import React, { Component, Fragment } from 'react';

class FieldManagementHeader extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Fragment>
				<thead>
					<tr>
						<th>Label</th>
						<th>Custom Display Label</th>
						<th className="center">Required</th>
						<th className="center">Visible</th>
					</tr>
				</thead>
			</Fragment>
		);
	}
}

export default FieldManagementHeader;
