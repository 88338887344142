import { invokeIfFunction, OutsideClick } from 'common/utilities';
import { filter, find, map, toLower } from 'lodash';
import { array, bool, func, object, string } from 'prop-types';
import React from 'react';
import AddNewSectionActions from './AddNewSectionActions';
import PaymentSiteAvailableFields from './PaymentSiteAvailableFields';

const AddNewSection = ({
	newSectionId,
	availableFields,
	isAdmin,
	newSectionActive,
	isKioskTheme,
	handleAddNewSection,
	addNewSection,
	toggledSections,
}) => {
	if (!newSectionActive) return null;
	const { entireBilling, entireShipping } = toggledSections;
	return (
		<OutsideClick action={handleAddNewSection} className="popover popover--down w--300">
			<ul className="popover__list">
				{map(availableFields, ({ key, label, actions, hide, internalSection }) => {
					if (
						(internalSection && !isAdmin) ||
						(isKioskTheme && toLower(label) === 'shipping information') ||
						toLower(label) === 'custom fields'
					) {
						return null;
					}
					return (
						!invokeIfFunction(hide) &&
						key !== 'other' && (
							<li className={`item${internalSection ? ' internal' : ''}`} key={key}>
								<AddNewSectionActions
									actions={filter(actions, ({ isAbove }) => isAbove)}
									toggledSections={toggledSections}
									label={invokeIfFunction(label)}
								/>
								<AddNewSectionActions
									actions={filter(actions, ({ isAbove }) => !isAbove)}
									toggledSections={toggledSections}
									label={invokeIfFunction(label)}
								/>
								{internalSection && (
									<PaymentSiteAvailableFields
										availableFields={find(availableFields, field => field.label === label)}
										entireBilling={entireBilling}
										entireShipping={entireShipping}
										isAdmin={isAdmin}
										isKioskTheme={isKioskTheme}
										isToggle={true}
									/>
								)}
							</li>
						)
					);
				})}
				{newSectionId && (
					<li>
						<button
							key="key"
							type="button"
							className="btn btn--link btn--link--tertiary spc--left--sml spc--bottom--sml spc--top--sml--alt"
							onClick={addNewSection}
						>
							<i className="icon icon--sml icon--add spc--right--sml"></i>
							New Section
						</button>
						<hr className="separator--primary" />
					</li>
				)}
			</ul>
		</OutsideClick>
	);
};
AddNewSection.propTypes = {
	newSectionId: string.isRequired,
	availableFields: array.isRequired,
	toggledSections: object.isRequired,
	isAdmin: bool.isRequired,
	newSectionActive: bool.isRequired,
	isKioskTheme: bool.isRequired,
	handleAddNewSection: func.isRequired,
	addNewSection: func.isRequired,
};

export default AddNewSection;
