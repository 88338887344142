import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toLower, each, uniqueId, get } from 'lodash';

import { kvaasResources } from 'common/utilities';
import { kvaasService } from 'common/services';
import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';

class ColumnFilterFooter extends Component {
	idPrefix = `saveColumnSettings${uniqueId()}`;
	constructor(props) {
		super(props);
		this.state = {
			oldData: {
				defaultColumns: {
					data: {},
				},
				reportOrder: {
					data: {},
				},
			},
			disabled: true,
			error: false,
		};
	}

	componentDidMount = async () => {
		try {
			const { ReportDefaultColumns, ReportOrder } = await this.getData();

			const newState = this.mapResponseToState(ReportDefaultColumns, ReportOrder);
			this.setState(newState);
		} catch (e) {
			this.handleError(e);
		}
	};
	getData = async () => {
		const getKvaas = this.requiredKvaasResources();
		const [ReportDefaultColumns, ReportOrder] = await this.props.makePendingRequest(getKvaas, 'kvaas');

		return {
			ReportDefaultColumns,
			ReportOrder,
		};
	};
	requiredKvaasResources() {
		return kvaasService.get(
			...[
				{ ...kvaasResources[`${this.props.kvaasResourceType}ReportDefaultColumns`], throwError: true },
				{ ...kvaasResources[`${this.props.kvaasResourceType}ReportOrder`], throwError: true },
			]
		);
	}

	handleError = e => {
		//eslint-disable-next-line
		console.error(e);
		this.setState({ error: true });
	};

	processResponse = (response, newState, key) => {
		const { data, result, error, refNum } = response;
		if (data) {
			if (toLower(result) === 's' || error === 'Item does not exist') {
				if (!error) {
					newState.oldData[key] = { ...response };
				}
				newState.disabled = false;
			} else {
				this.handleError({
					isApiError: true,
					isCanceled: false,
					success: false,
					message: error,
					ref: refNum,
				});
				newState.disabled = true;
			}
		}
	};

	mapResponseToState = (
		oldDefaultColumns = this.state.oldData.defaultColumns,
		oldDefaultReportOrder = this.state.oldData.reportOrder
	) => {
		const newState = {
			oldData: { ...this.state.oldData },
		};

		if (oldDefaultColumns) {
			this.processResponse(oldDefaultColumns, newState, 'defaultColumns');
		}

		if (oldDefaultReportOrder) {
			this.processResponse(oldDefaultReportOrder, newState, 'reportOrder');
		}

		return newState;
	};

	onReset = () => this.props.onReset();
	saveData = (data, oldData, resourceType) => {
		return kvaasService.save({
			newData: {
				newRevision: get(oldData, 'revision', 0) + 1,
				data,
			},
			oldData: { ...oldData },
			...kvaasResources[`${resourceType}`],
		});
	};
	onApply = async () => {
		this.setState({ isSaving: true });
		const { columns, onApply, kvaasResourceType } = this.props;
		const {
			oldData: { defaultColumns, reportOrder },
		} = this.state;
		const visibleData = {};
		const orderData = {};
		each(columns, ({ visible, hideable, customSettingsKey, order }) => {
			if (customSettingsKey && hideable) {
				visibleData[customSettingsKey] = visible;
				orderData[customSettingsKey] = order;
			}
		});

		try {
			const [[newDefaultColumns], [newReportOrder]] = await Promise.all([
				this.saveData(visibleData, defaultColumns, `${kvaasResourceType}ReportDefaultColumns`),
				this.saveData(orderData, reportOrder, `${kvaasResourceType}ReportOrder`),
			]);
			const newState = this.mapResponseToState(newDefaultColumns, newReportOrder);
			this.setState(newState, () => {
				if (!this.props.errorMessageVisible) {
					onApply(true);
				}
			});
		} catch (e) {
			this.props.handleError(e);
		}
		this.setState({ isSaving: false });
	};
	render() {
		return (
			<React.Fragment>
				<button
					type="button"
					disabled={this.state.isSaving}
					onClick={this.onReset}
					className="btn btn--link btn--link--underline"
				>
					Reset
				</button>
				<button
					type="button"
					disabled={this.state.isSaving}
					onClick={this.onApply}
					className="btn btn--sml btn--primary"
				>
					Done
				</button>
			</React.Fragment>
		);
	}
}

ColumnFilterFooter.propTypes = {
	onApply: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	errorMessageVisible: PropTypes.bool,
	hideSaveSelection: PropTypes.any,
	hasNoColumns: PropTypes.any,
	kvaasResourceType: PropTypes.string.isRequired,
};

export default withError(withCancelable(ColumnFilterFooter));
