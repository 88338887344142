import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'common/components/modal';
import { includes, join, replace, slice, toLower, cloneDeep } from 'lodash';

const PaymentEngineComputerModal = ({
	isOpen,
	onClose,
	onSave,
	data,
	softwareName,
	computer,
	isLoading,
	editMode = false,
}) => {
	const [isEditMode, setIsEditMode] = useState(editMode);
	const [clonedData, setClonedData] = useState(cloneDeep(data));
	const [sortConfig, setSortConfig] = useState({ key: 'key', direction: 'ascending' });

	useEffect(() => {
		setIsEditMode(editMode);
	}, [editMode]);

	useEffect(() => {
		if (isOpen) {
			setClonedData(cloneDeep(data));
		}
	}, [isOpen, data]);

	if (!data) return null;

	const formatXkey = xKey => {
		if (!xKey) {
			return '';
		}
		return `${join(slice(xKey, 0, 3), '')}*********${join(slice(xKey, xKey.length - 4), '')}`;
	};

	const renderPreviewCell = value => {
		const isCheckbox = includes(['true', 'false'], toLower(value));
		return isCheckbox ? (
			<td>
				<input
					type="checkbox"
					className="input--check input--check--no-label"
					checked={toLower(value) === 'true'}
					readOnly
				/>
				<label></label>
			</td>
		) : (
			<td>{value}</td>
		);
	};

	const onChange = (e, key) => {
		const { type, checked, value } = e.target;
		const newValue = type === 'checkbox' ? checked.toString() : value;
		setClonedData(prevData => ({
			...prevData,
			[key]: newValue,
		}));
	};

	const renderEditCell = (key, value) => {
		if (key === 'xKey') {
			return renderPreviewCell(formatXkey(value));
		}

		if (includes(['true', 'false'], toLower(value))) {
			return (
				<td>
					<div className="flex--primary">
						<input
							type="checkbox"
							className="input--check input--check--no-label"
							checked={toLower(value) === 'true'}
							onChange={e => onChange(e, key)}
							id={key}
							disabled={isLoading}
						/>
						<label htmlFor={key}></label>
					</div>
				</td>
			);
		}

		return (
			<td>
				<div className="flex--primary">
					<div className="flex--grow--1">
						<input className="input input--sml" value={value} onChange={e => onChange(e, key)} />
					</div>
				</div>
			</td>
		);
	};

	const handleSort = key => {
		let direction = 'ascending';
		if (sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfig({ key, direction });
	};

	const sortedData = Object.keys(isEditMode ? clonedData : data).sort((a, b) => {
		if (sortConfig.key === 'key') {
			const keyA = replace(a, /_/g, ' ');
			const keyB = replace(b, /_/g, ' ');
			if (keyA < keyB) return sortConfig.direction === 'ascending' ? -1 : 1;
			if (keyA > keyB) return sortConfig.direction === 'ascending' ? 1 : -1;
			return 0;
		} else {
			const valueA = (isEditMode ? clonedData : data)[a];
			const valueB = (isEditMode ? clonedData : data)[b];
			if (valueA < valueB) return sortConfig.direction === 'ascending' ? -1 : 1;
			if (valueA > valueB) return sortConfig.direction === 'ascending' ? 1 : -1;
			return 0;
		}
	});

	return (
		<Modal isOpen={isOpen} onClose={onClose} isLoading={isLoading}>
			<div className="modal__header">
				{isLoading && <div className="loader"></div>}
				<div>
					<h4 className="spc--bottom--tny">{softwareName}</h4>
					<p className="type--p1 type--color--text--light">{computer}</p>
				</div>
			</div>
			<div className="modal__body">
				<table className="table table--primary">
					<thead className="table--primary__thead">
						<tr>
							<th className="sticky-top">
								<div className="flex--primary flex--gap--sml">
									<span>Setting</span>
									<button className="btn btn--link" onClick={() => handleSort('key')}>
										<i className="icon icon--chevron--down--light sort-icon"></i>
									</button>
								</div>
							</th>
							<th className="sticky-top">
								<div className="flex--primary flex--gap--sml">
									<span>Value</span>
									<button className="btn btn--link" onClick={() => handleSort('valueFilter')}>
										<i className="icon icon--chevron--down--light sort-icon"></i>
									</button>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{sortedData.map(key => {
							const formattedKey = replace(key, /_/g, ' ');
							const value = (isEditMode ? clonedData : data)[key];
							const xKey = key === 'xKey';
							const displayValue = xKey ? formatXkey(value) : value;
							return (
								<tr key={key}>
									<td className="type--p2 type--p2--medium">{formattedKey}</td>
									{isEditMode ? renderEditCell(key, value) : renderPreviewCell(displayValue)}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="modal__footer">
				<button
					className={`btn btn--med btn--${isEditMode ? 'primary' : 'tertiary'}`}
					onClick={() => {
						if (isEditMode) {
							onSave(clonedData, softwareName, computer);
						} else {
							setIsEditMode(true);
						}
					}}
				>
					{isEditMode ? 'Save' : 'Edit'}
				</button>
			</div>
		</Modal>
	);
};

PaymentEngineComputerModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	data: PropTypes.object,
	softwareName: PropTypes.string,
	computer: PropTypes.string,
	isLoading: PropTypes.bool,
	editMode: PropTypes.bool,
};

export default PaymentEngineComputerModal;
