import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { get, each } from 'lodash';

import ActionsModal from 'common/components/transaction-actions/actions-modal-wrapper';
import { modalNames } from 'common/components/transaction-actions/modal-names';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import { Notification } from 'common/components/notifications';
import { withLoader } from 'common/components/loader';

const { c2payEnabled } = ApplicationSettings;

class PaymentMethodsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			sectionsExpanded: {
				applePay: false,
				clickToPay: false,
			},
		};

		this.notificationRef = createRef();
	}

	openCloseActionsModal = modal => this.setState({ modal });

	toggleExpandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};

	toggleExpandCollapseAll = expand => () => {
		const newState = { sectionsExpanded: this.state.sectionsExpanded };

		each(newState.sectionsExpanded, (_, section) => {
			newState.sectionsExpanded[section] = !expand;
		});

		this.setState(newState);
	};

	handleMessage = () => {
		const { expanded } = this.state;
		this.setState({ expanded: !expanded });
	};

	render = () => {
		const { modal } = this.state;

		return (
			<div className="settings--main">
				<ActionsModal modal={modal} onModalClose={this.openCloseActionsModal} isLoading={this.props.isLoading} />
				<Notification ref={this.notificationRef} />

				<div className="settings__header">
					<h3 className="settings__title">Account Settings</h3>
					<h5 className="spc--bottom--sml">Payment Methods</h5>
					<p className="type--p4 type--color--text--light">In this section, you can configure your Payment Methods</p>
				</div>

				<div className="spc--bottom--huge">
					<div className="flex--primary flex--gap--tny spc--bottom--lrg">
						<p className="type--p2 type--p2--medium">Configure Apple Pay</p>
						<i className="icon icon--tny icon--regular--info" data-tooltip="Configure Apple Pay for your website"></i>
					</div>
					<div className="flex--tertiary flex--gap--med">
						<p className="type--p2">Register for Apple Pay with Cardknox Certificate</p>
						<button
							className="btn btn--sml btn--primary"
							onClick={() =>
								this.openCloseActionsModal({
									name: modalNames.registerApplePay,
									data: {
										handleError: this.props.handleError,
										addNotification: get(this.notificationRef, 'current.addNotification'),
									},
								})
							}
						>
							Register
						</button>
					</div>

					<hr className="separator--primary spc--top--med spc--bottom--med" />

					<div className="flex--tertiary flex--gap--med">
						<p className="type--p2"> Register for Apple Pay using your own Certificate</p>

						<button
							className="btn btn--sml btn--primary"
							onClick={() =>
								this.openCloseActionsModal({
									name: modalNames.generateApplePay,
									data: {
										handleError: this.props.handleError,
										addNotification: get(this.notificationRef, 'current.addNotification'),
									},
								})
							}
						>
							Register
						</button>
					</div>
				</div>

				{c2payEnabled && (
					<div>
						<div className="flex--primary flex--gap--tny spc--bottom--lrg">
							<p className="type--p2 type--p2--medium">Configure Click To Pay</p>
							<i
								className="icon icon--tny icon--regular--info"
								data-tooltip="Configure Click To Pay for your website"
							></i>
						</div>

						<div className="flex--tertiary flex--gap--med flex--nowrap">
							<p className="type--p2">Click the button below to start your Click To Pay Domain registration</p>
							<button
								className="btn btn--sml btn--primary"
								onClick={() =>
									this.openCloseActionsModal({
										name: modalNames.configureClickToPay,
										data: {
											addNotification: get(this.notificationRef, 'current.addNotification'),
										},
									})
								}
							>
								Register your merchant
							</button>
						</div>
					</div>
				)}
			</div>
		);
	};
}

PaymentMethodsComponent.propTypes = {
	handleError: PropTypes.func.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
};

export default withError(withCancelable(withBlock(withLoader(PaymentMethodsComponent))));
