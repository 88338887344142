import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower, some, toUpper, includes } from 'lodash';

import { principalService } from 'common/services';
import { withLoader } from 'common/components/loader';
import { PasswordInput } from 'common/components/password-input';
import { logger } from 'common/utilities';

const specialCharactersRegex = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

class ChangePasswordComponent extends Component {
	constructor(props) {
		super(props);

		let username = '';
		if (props && props.location && props.location.state && props.location.state.username) {
			username = props.location.state.username;
		}

		this.state = {
			username: username,
			oldPassword: '',
			password: '',
			password2: '',
			errorMessage: null,
		};
	}

	componentWillMount() {
		if (principalService.get()) {
			this.redirect();
		}
	}

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	};

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	getInvalidPasswordMessage = err => {
		let msg;
		if (err.message === 'Provided password cannot be used for security reasons.') {
			msg = 'There may be a key word in your password that is not allowed. Please try another password';
		} else {
			msg =
				'Your password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one special character and one number. The keyword "password" is not allowed.';
		}
		return msg;
	};

	handleSubmit = async event => {
		const { history } = this.props;
		const { oldPassword, password } = this.state;
		let { username } = this.state;
		username = trim(toLower(username));

		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}
		this.setState({ errorMessage: '' });

		try {
			const user = await Auth.signIn(username, oldPassword);
			if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				// Force change password
				try {
					await Auth.completeNewPassword(user, password);
					history.push({ pathname: '/login' });
				} catch (err) {
					logger.logError({
						message: 'Change password error.',
						errorDetails: err,
						username,
					});
					let message;
					switch (err && err.code) {
						case 'NotAuthorizedException':
						case 'UserNotFoundException': {
							message = 'The username/password provided is incorrect.';
							break;
						}
						case 'InvalidParameterException': {
							message = err.message;
							break;
						}
						case 'UserNotConfirmedException': {
							history.push({
								pathname: '/confirm-registration',
								state: { username: username },
							});
							break;
						}
						case 'InvalidPasswordException': {
							message = this.getInvalidPasswordMessage(err);
							break;
						}
						default: {
							message = 'Something went wrong. Please try again.';
							break;
						}
					}
					this.setState({ errorMessage: message });
				}
			} else {
				try {
					const success = await Auth.changePassword(user, oldPassword, password);
					if (success === 'SUCCESS') {
						history.push({ pathname: '/login' });
					} else {
						this.setState({ errorMessage: 'Something went wrong. Please reload and try again.' });
						logger.logError({
							message: 'Change password failed',
							errorDetails: '',
							username,
						});
					}
				} catch (err) {
					logger.logError({
						message: 'Change password error.',
						errorDetails: err,
						username,
					});
					let message;
					switch (err && err.code) {
						case 'NotAuthorizedException':
						case 'UserNotFoundException': {
							message = 'The username/password provided is incorrect.';
							break;
						}
						case 'InvalidParameterException': {
							message = err.message;
							break;
						}
						case 'UserNotConfirmedException': {
							history.push({
								pathname: '/confirm-registration',
								state: { username: username },
							});
							break;
						}
						case 'InvalidPasswordException': {
							if (includes(toLower(err.message), 'security')) {
								message = 'There may be a key word in your password that is not allowed. Please try another password';
							} else {
								message =
									'Your password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one special character and one number. The keyword "password" is not allowed.';
							}
							break;
						}
						default: {
							message = 'Something went wrong. Please try again.';
							break;
						}
					}
					this.setState({ errorMessage: message });
				}
			}
		} catch (err) {
			logger.logError({
				message: 'Change password login error.',
				errorDetails: err,
				username,
			});
			let message;
			switch (err && err.code) {
				case 'InvalidParameterException': {
					message = err.message;
					break;
				}
				case 'UserNotConfirmedException': {
					history.push({
						pathname: '/confirm-registration',
						state: { username: username },
					});
					break;
				}
				case 'LimitExceededException': {
					message = 'Login attempts exceeded the limit. Please wait and try again later.';
					break;
				}
				case 'NotAuthorizedException':
				case 'UserNotFoundException': {
					message = 'The username/password provided is incorrect.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.setState({ errorMessage: message });
		}
	};

	formValidation = () => {
		const { username, password, password2 } = this.state;

		if (username.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your email',
			});
			return false;
		}

		if (password.length <= 0 || password2.length <= 0) {
			this.setState({
				errorMessage: 'Please enter new password',
			});
			return false;
		}

		if (password.length < 8) {
			this.setState({
				errorMessage: 'Your password needs to contain at least 8 characters',
			});
			return false;
		}

		if (!some(password, char => char === toUpper(char))) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one uppercase character',
			});
			return false;
		}

		if (!some(password, char => char === toLower(char))) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one lowercase character',
			});
			return false;
		}

		if (!some(password, char => !isNaN(parseInt(char)))) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one number',
			});
			return false;
		}

		if (!specialCharactersRegex.test(password)) {
			this.setState({
				errorMessage: 'Your password needs to contain at least one special character',
			});
			return false;
		}

		if (password !== password2) {
			this.setState({
				errorMessage: 'New passwords do not match',
			});
			return false;
		}

		return true;
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = '/';

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	render() {
		const { username, oldPassword, password, password2, errorMessage } = this.state;

		return (
			<div>
				<form className="membership__form form" onSubmit={this.handleSubmit}>
					<div className="membership__section">
						<h2 className="membership__title">Change password</h2>
						<div className="membership__spacer">
							<label className="membership__label">Email</label>
							<input
								name="username"
								type="email"
								className="input input--med"
								placeholder="user@gmail.com"
								value={username}
								onChange={this.handleChange}
								tabIndex="1"
								inputMode="email"
							/>
						</div>
						<div className="membership__spacer">
							<label className="membership__label">Current password</label>
							<PasswordInput
								name="oldPassword"
								placeholder="Enter your current password"
								value={oldPassword}
								onChange={this.handleChange}
								tabIndex="2"
								setErrorMessage={this.setErrorMessage}
							/>
						</div>
						<div className="membership__spacer">
							<label className="membership__label">New password</label>
							<PasswordInput
								placeholder="Enter new password"
								value={password}
								onChange={this.handleChange}
								tabIndex="3"
								setErrorMessage={this.setErrorMessage}
							/>
						</div>
						<div className="spc--bottom--med">
							<label className="membership__label">New password again</label>
							<PasswordInput
								name="password2"
								placeholder="Enter new password again"
								value={password2}
								onChange={this.handleChange}
								tabIndex="4"
								setErrorMessage={this.setErrorMessage}
							/>
							{errorMessage ? (
								<div className="spc--top--sml spc--bottom--med type--color--error">{errorMessage}</div>
							) : null}
						</div>
						<button type="submit" className="btn btn--primary btn--med membership__btn" tabIndex="5">
							Change password
						</button>
						<div className="membership--main__action">
							<span className="membership--main__label">Do you have an account?</span>{' '}
							<button
								type="button"
								onClick={this.redirectToLogin}
								className="btn membership--main__btn type--wgt--medium"
							>
								Log in
							</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

ChangePasswordComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default withLoader(ChangePasswordComponent);
