import React, { Component } from 'react';
import { func, arrayOf, object, shape, string } from 'prop-types';
import { find } from 'lodash';

class AddEditCustomReport extends Component {
	constructor(props) {
		super(props);
		let { report } = props;
		if (!report) {
			report = {};
		}
		const { name = '', key = '' } = report;
		this.state = {
			name,
			key,
			dirty: false,
		};
	}

	onChange = ({ target: { value, checked, type, name } }) => {
		const newValue = type === 'checkbox' ? checked : value;
		this.setState({
			[name]: newValue,
			dirty: true,
		});
	};

	onSave = saveAsNew => {
		this.setState({
			dirty: true,
		});
		const { onSave, filters, sortBy } = this.props;
		const { name, key } = this.state;
		if (!name) {
			return;
		}
		onSave(name, filters, sortBy, saveAsNew ? '' : key);
	};

	render = () => {
		const { name, key, dirty } = this.state;
		const { filters } = this.props;
		const { onSave, onChange } = this;
		const dateFilter = find(filters, { key: 'date' });
		return (
			<React.Fragment>
				<header className="modal__header">
					<h4>Custom Saved Report</h4>
				</header>
				<div className="modal__body">
					{dateFilter.values.key === 'custom' ? (
						<div className="type--validation">You cannot save a report with a custom date range.</div>
					) : (
						<div className="form__group">
							<div className="form__group__header">
								<label htmlFor="customReportName" className="form__group__label">
									Report Name
								</label>
								<div className="form__group__required" data-tooltip="Required">
									*
								</div>
							</div>
							<input id="customReportName" className="input input--med" name="name" value={name} onChange={onChange} />
							{!name && dirty && <div className="type--validation spc--top--tny">Report name is required.</div>}
						</div>
					)}
				</div>
				<footer className="modal__footer">
					{key && (
						<button type="button" className="btn btn--med btn--primary" onClick={() => onSave(true)}>
							Save As New
						</button>
					)}
					<button type="button" className="btn btn--med btn--primary" onClick={() => onSave(false)}>
						Save
					</button>
				</footer>
			</React.Fragment>
		);
	};
}

AddEditCustomReport.propTypes = {
	onSave: func.isRequired,
	onCancel: func.isRequired,
	filters: arrayOf(object).isRequired,
	sortBy: string.isRequired,
	report: shape({
		name: string.isRequired,
		key: string.isRequired,
	}),
};

export default AddEditCustomReport;
