import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	map,
	cloneDeep,
	filter,
	each,
	clone,
	orderBy,
	forOwn,
	find,
	isArray,
	toLower,
	includes,
	split,
	findIndex,
	camelCase,
	sortBy,
	slice,
	some,
	get,
	isEmpty,
	join,
	keys,
	toPairs,
	concat,
	fromPairs,
	indexOf,
} from 'lodash';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

import { ReactToPrint } from '../../Common/components/react-to-print';
import { Modal } from '../../Common/components/modal';
import { kvaasResources, uniqueId, invokeIfFunction } from '../../Common/utilities';
import { MainFilter, Filters, compileFilter } from './filter';
import {
	customersFilter as filtersOfCustomers,
	compileFilter as compileCustomersFilter,
} from 'common/components/customers/filter/customersFilter';
import {
	recurringSchedulesFilter as filtersOfRecurringSchedules,
	compileFilter as compileRecurringScheduleFilter,
} from 'common/components/recurring-schedules/filter/recurringSchedulesFilter';
import { TransactionsSortBy } from '../../Common/sorters/transactions-sort-options';
import { transactionService, kvaasService, customerService, principalService } from '../../Common/services';
import { TransactionColumns as Columns } from './../../Common/components/transactions/column-filter/transactionColumns';
import { exportOptions } from './../../Common/components/export/export-options';
import { PrintGridData } from '../../Common/components/print-grid-data';
import { withError } from '../../Common/components/error';
import { withCancelable } from '../../Common/components/cancelable';
import { withLoader } from '../../Common/components/loader';
import { Notification } from '../../Common/components/notifications';
import AddEditCustomReport from './add-edit';
import { predefinedDates } from 'common/components/date-picker';
import { exportService } from 'common/components/export/exportService';
import { CustomerColumns } from 'common/components/customers/column-filter/customerColumns';
import { RecurringScheduleColumns } from 'common/components/recurring-schedules/column-filter/recurringScheduleColumns';

const requestKeys = {
	FETCH: 'fetch',
	KVAAS: 'kvaas',
	FILTER: key => `save${key}`,
	REPORTS: 'reports',
	STATE: 'state',
};

class ReportsComponent extends Component {
	constructor(props) {
		super(props);

		const filters = this.getFilters();

		const activeFilters = this.props.activeFilters || cloneDeep(filters);
		const principal = principalService.get();
		this.state = {
			selectedDate: '',
			isLoading: false,
			filters,
			activeFilters,
			initialRecordsLimit: 1001,
			transactionsSortBy: TransactionsSortBy[0],
			isModalOpen: false,
			isPrintOpen: false,
			isLoadingDownload: false,
			showDownloadDisclaimer: false,
			data: [],
			columns: cloneDeep(Columns).filter(c => (principal.isAdmin ? true : !c.isAdminColumn)),
			isSaveModalOpen: false,
			isDeleteModalOpen: false,
			reportToDelete: null,
			reportToEdit: null,
			cachedFilters: null,
			oldData: {
				customReports: null,
				reportFilters: {},
			},
			customReports: [],
			shownCustomReports: [],
			reportFilters: {},
			pageSize: 4,
			currentPage: 1,
			error: '',
		};

		this.printTrigger = React.createRef();
		this.notification = React.createRef();
		this.mainFilter = React.createRef();
		this.top = React.createRef();
	}

	componentDidMount = async () => {
		this.setState({ isLoading: true });
		try {
			const [labels, hidden, customReports, customDefaultColumns, defaultValues] = await this.props.makePendingRequest(
				kvaasService.get(
					kvaasResources.transactionDisplayLabels,
					kvaasResources.transactionReportHiddenFields,
					kvaasResources.customReports,
					kvaasResources.transactionReportDefaultColumns,
					kvaasResources.transactionReportDefaultValues
				),
				requestKeys.KVAAS
			);
			let columns = this.addCustomData(this.state.columns, [labels, hidden, customDefaultColumns]);
			const includeVoidStatusInCsv = get(defaultValues, 'data.includeVoidStatus');
			if (!includeVoidStatusInCsv) {
				columns = columns.filter(col => col.key !== 'xVoid');
				const xAmountColumn = columns.find(col => col.key === 'amountToUse');
				if (xAmountColumn) {
					xAmountColumn.dependentExportKey =
						xAmountColumn.dependentExportKey && xAmountColumn.dependentExportKey.filter(key => key != 'xVoid');
				}
			}
			const newState = {
				...this.mapResponseToState(customReports),
				columns,
				isLoading: false,
			};
			this.setState(newState);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({ isLoading: false });
			}
		}
	};

	getFilters = () => {
		return cloneDeep(Filters);
	};

	addCustomDataType = (newData, customData, mapper) => {
		each(customData && customData.data, (value, customSettingsKey) => {
			const item = find(newData, item =>
				isArray(item.customSettingsKey)
					? includes(item.customSettingsKey, customSettingsKey)
					: toLower(item.customSettingsKey) === toLower(customSettingsKey)
			);
			if (item) {
				mapper(item, value);
			}
		});
	};

	addCustomData = (data, [customDisplayLabels, hiddenColumns, customDefaultColumns]) => {
		const newData = cloneDeep(data);
		let anyChanged = false;
		this.addCustomDataType(newData, customDefaultColumns, item => {
			item.visible = true;
			anyChanged = true;
		});
		this.addCustomDataType(newData, customDisplayLabels, (item, value) => {
			item.name = value;
			anyChanged = true;
		});
		this.addCustomDataType(newData, hiddenColumns, item => {
			item.hideable = false;
			item.visible = false;
			anyChanged = true;
		});
		return anyChanged ? newData : data;
	};

	resetFilters = (cacheOldFilters = false) => {
		const reportFilters = this.getFilters();
		const newFilters = {
			filters: reportFilters,
			activeFilters: this.props.activeFilters || reportFilters,
		};
		if (!this.state.cachedFilters && cacheOldFilters) {
			newFilters.cachedFilters = {
				filters: cloneDeep(this.state.filters),
				activeFilters: cloneDeep(this.state.activeFilters),
				transactionsSortBy: this.state.transactionsSortBy,
			};
		}
		this.setState(newFilters);
	};

	onFilterUpdate = (filters, activeFilters) => {
		const state = {};

		if (filters) {
			state.filters = filters;
		}

		if (activeFilters) {
			state.activeFilters = activeFilters;
		}

		this.setState(state);
	};

	onSortUpdate = sort => {
		const state = {};
		if (sort) {
			state.transactionsSortBy = sort;
		} else {
			state.transactionsSortBy = TransactionsSortBy[0];
		}
		this.setState(state);
	};

	toggleModal = () => {
		const { isModalOpen } = this.state;
		this.setState({
			isModalOpen: !isModalOpen,
		});
	};

	/*-------------------------------*/

	mapData = data => {
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, function(item) {
				const clonedItem = clone(item);
				clonedItem.xEnteredDateMoment = moment(clonedItem.xEnteredDate);
				item.xEnteredDate = clonedItem.xEnteredDateMoment.format(ApplicationSettings.displayDateTimeFormat);
				item.xEnteredDateMoment = clonedItem.xEnteredDateMoment;
			});
		}
	};

	sortTransactions = (data, sortBy) => {
		let sortKey = sortBy.xColumn;
		if (sortKey === 'xEnteredDate') {
			sortKey = 'xEnteredDateMoment';
		}

		let sorted = [];
		if (data && data.xReportData && data.xReportData.length > 0) {
			sorted = orderBy(data.xReportData, [sortKey], ['ASC']);
		}
		return sorted;
	};

	getColumns = (columns = this.state.columns) => {
		const extractedColumns = this.extractColumns(columns, this.getVisibleColumns(columns));
		return filter(extractedColumns, extractedColumn => extractedColumn.key != 'xOrderID');
	};

	columnNameTransformer = columns => {
		let returnObj = [];
		for (let col of columns) {
			returnObj[col.key] = col.name.replace(/\u00AD/g, '');
		}
		return returnObj;
	};

	getPrintColumns = () => {
		const extractedColumns = this.extractColumns(this.state.columns, this.getVisibleColumns(this.state.columns), true);
		return map(extractedColumns.filter(c => c.key !== 'xOrderID'), ({ key, name }) => ({
			key,
			name: name.replace(/\u00AD/g, ''),
		}));
	};

	filterVisibleColumns = data => {
		let columns = this.getColumns();
		columns = this.columnNameTransformer(columns);
		let exportData = [];
		if (data) {
			for (let row of data) {
				let exportRow = {};
				const columnKeys = Object.keys(columns);
				for (let colKey of columnKeys) {
					const newPropName = columns[colKey];
					exportRow[newPropName] = row[colKey];
				}
				exportData.push(exportRow);
			}
		}

		return exportData;
	};

	fetchData = async (filters, filterDateFormat, sortBy, filterColumns = true) => {
		try {
			const filter = await compileFilter(filters, filterDateFormat);
			const data = await this.props.makePendingRequest(
				transactionService.filterTransactionsAll(filter),
				requestKeys.FETCH
			);
			this.mapData(data);

			let sortedData = this.sortTransactions(data, sortBy);
			return filterColumns ? this.filterVisibleColumns(sortedData) : sortedData;
		} catch (e) {
			this.props.handleError(e);
		}
	};

	fetchReport = async report => {
		try {
			this.props.showLoader(true);
			const { key } = report;
			const [response] = await this.props.makePendingRequest(kvaasService.get(kvaasResources.reportFilters(key)), key);
			const newState = this.mapResponseToReport(response, key);
			await this.setStateAsync(newState);
			this.props.showLoader(false);
			return {
				...report,
				filters: {
					...newState.reportFilters[key],
				},
				revision: response.revision,
			};
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
			}
		}
	};

	download = async (reportFetcher, sortBy, columnMapper, showDownloadDisclaimer = false) => {
		this.setState({
			isModalOpen: true,
			isLoadingDownload: true,
			showDownloadDisclaimer,
		});
		try {
			let data = await reportFetcher;
			if (!data) {
				data = [];
			}
			if (isEmpty(data)) {
				this.setState({
					isModalOpen: false,
				});
				this.notification.current.addNotification({
					message: 'No data to export for the selected filters',
					infoMessage: true,
				});
				return;
			}

			if (!isEmpty(sortBy)) {
				if (sortBy.xColumn === 'xEnteredDate') {
					this.sortBytransactionDate(data);
				} else {
					data = orderBy(data, [sortBy.xColumn], ['ASC']);
				}
			}

			let options = exportOptions;
			options.filename = 'report_export';
			const exporter = new ExportToCsv(options);
			const mappedData = columnMapper(data);
			exporter.generateCsv(mappedData);

			this.setState({
				isModalOpen: false,
				isLoadingDownload: false,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					isModalOpen: false,
					isLoadingDownload: false,
				});
			}
		}
	};

	sortBytransactionDate = data => {
		data.sort((a, b) => {
			if (!isEmpty(a.xEnteredDate) && !isEmpty(b.xEnteredDate)) {
				if (
					moment(a.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat).isSameOrAfter(
						moment(b.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat)
					)
				)
					return 1;
				else return -1;
			} else return 1;
		});
	};

	mapCustomerColumns = (data, showToken) => {
		let customerColumns = cloneDeep(CustomerColumns(showToken));

		const recurringScheduleColumns = cloneDeep(RecurringScheduleColumns);
		const columns = [...customerColumns, ...recurringScheduleColumns];
		const extractedColumns = this.extractColumns(columns, columns);
		const cols = { ...this.columnNameTransformer(extractedColumns) };

		return this.replacer(data, cols);
	};

	extractColumns = (allcolumns, columns, isPrint = false) => {
		const filteredColumns = filter(
			columns,
			c => !c.hideOnExport && !c.hasNoField && (!isPrint || !c.hideOnPrint) && (c.visible || c.hideable)
		);
		each(filteredColumns, (column, index) => {
			if (column.dependentExportKey) {
				let dependentColumnKeys = [];
				if (!Array.isArray(column.dependentExportKey)) {
					dependentColumnKeys.push(column.dependentExportKey);
				} else {
					dependentColumnKeys = column.dependentExportKey;
				}
				each(dependentColumnKeys, dependentColumnKey => {
					if (!find(filteredColumns, { key: dependentColumnKey })) {
						let dependentColumn = find(allcolumns, {
							key: dependentColumnKey,
						});
						filteredColumns.splice(index + 1, 0, dependentColumn);
					}
				});
			}
		});
		const mappedColumns = map(
			filteredColumns,
			({ key, exportKey, fieldKey, name, alignHeaderRight = false, ignoreOnfetch = false }) => ({
				key: exportKey || key,
				name,
				fieldKey,
				alignHeaderRight,
				ignoreOnfetch,
			})
		);
		return mappedColumns;
	};

	getVisibleColumns = columns => filter(columns, col => col.visible);

	replaceColumns = (data, sortBy) => {
		let clonedData = data;
		const reportColumns = this.getColumns();
		const updatedColumns = this.withSortByColumn(reportColumns, sortBy);
		let columns = { ...this.columnNameTransformer(updatedColumns) };

		if (columns.xEnteredDate) {
			clonedData = map(data, row => {
				if (!row.xEnteredDate) return row;
				const dateTime = split(row.xEnteredDate, ',');
				return { ...row, date: dateTime[0], time: dateTime[1], xEnteredDate: undefined };
			});
			const xEnteredDateIndex = indexOf(keys(columns), 'xEnteredDate');
			const orderedColumns = toPairs(columns);
			const filteredColumns = filter(orderedColumns, ([key]) => key !== 'xEnteredDate');
			const dateAndTime = [['date', 'Date'], ['time', 'Time']];
			const updatedColumns = concat(
				slice(filteredColumns, 0, xEnteredDateIndex),
				dateAndTime,
				slice(filteredColumns, xEnteredDateIndex)
			);
			columns = fromPairs(updatedColumns);
		}

		return this.replacer(clonedData, columns);
	};

	getReportColumnAsArray = (sortBy = null) => {
		let reportColumns = this.getColumns();
		const columns = this.withSortByColumn(reportColumns, sortBy);
		let fetchcolumns = filter(columns, col => !col.ignoreOnfetch);
		return split(join(map(fetchcolumns, ({ key, fieldKey }) => fieldKey || key), ','), ',');
	};

	withSortByColumn = (reportColumns, sortColumn) => {
		if (!isEmpty(sortColumn)) {
			const transactionColumns = cloneDeep(Columns);
			const extractedColumns = this.extractColumns(transactionColumns, transactionColumns);
			const [sortByColumn] = filter(extractedColumns, column => column.key === sortColumn.xColumn);
			if (!isEmpty(sortByColumn)) return [...reportColumns, sortByColumn];
		}
		return reportColumns;
	};

	replacer = (data, columns) =>
		map(data, obj => {
			const newObj = {};
			each(columns, (column, colKey) => {
				newObj[column] = obj[colKey] !== undefined ? obj[colKey] : '';
			});
			return newObj;
		});

	handleDownloadExport = () => {
		this.download(
			exportService.getTransactionData(
				this.state.filters,
				null,
				null,
				{},
				this.getReportColumnAsArray(this.state.transactionsSortBy),
				'transactions'
			),
			this.state.transactionsSortBy,
			this.replaceColumns
		);
	};

	handleEdit = reportToEdit => {
		this.setState({
			reportToEdit,
		});
	};

	handleCancel = () => {
		this.setState({
			reportToEdit: null,
			...this.state.cachedFilters,
			cachedFilters: null,
		});
	};

	showMoreCustomReports = () => {
		const { currentPage, pageSize, customReports } = this.state;
		this.setState({
			shownCustomReports: slice(customReports, 0, pageSize * (currentPage + 1)),
			currentPage: currentPage + 1,
		});
	};

	mapQuickReport = report => {
		const sortBy = find(TransactionsSortBy, sort => sort.key === report.sortBy);
		const updates = { ...report.filters };

		if (report.date !== 'custom') {
			const { key, startValue, endValue } = report.dateFilter;
			const startDate = moment()
				.startOf('day')
				.add(invokeIfFunction(startValue), 'days');
			const endDate = moment()
				.endOf('day')
				.add(invokeIfFunction(endValue), 'days');
			updates.date = { key, startDate, endDate };
		}

		const updatedFilters = this.getFilters();
		each(updates, (filterItem, key) => {
			const filters = find(updatedFilters, { key });
			forOwn(filters.values, function(_, prop) {
				const valueItem = filterItem[camelCase(prop)];
				if (valueItem) {
					filters.values[prop] = valueItem;
					filters.hasSelection = true;
				}
			});
		});

		return {
			updatedFilters,
			sortBy,
		};
	};

	setFilters = async report => {
		const { updatedFilters: filters } = this.mapQuickReport(report);
		await this.setStateAsync({ filters });
		if (
			this.mainFilter.current &&
			!this.mainFilter.current.state.isExpanded &&
			some(filters, ({ hasSelection }) => hasSelection)
		) {
			this.mainFilter.current.toggleExpanded();
		}
	};

	handleQuickReportDownloadExport = async report => {
		let reportFetcher = () => Promise.resolve();
		let sortColumnBy = {};
		let columnMapper = () => {};
		if (report.reportType && report.reportType === 'customersAndRecurringRecords') {
			reportFetcher = this.fetchAllCustomersAndRecurringSchedules();

			columnMapper = data => this.mapCustomerColumns(data, true);
		} else if (report.reportType && report.reportType === 'expiredPayments') {
			reportFetcher = this.fetchExpiredPaymentMethods().then(result => this.unifyCustomers(result));
			columnMapper = this.mapCustomerColumns;
		} else if (report.reportType && report.reportType === 'expiredPaymentsActiveRecurring') {
			reportFetcher = this.fetchExpiredPaymentMethods().then(result => {
				return this.unifyCustomers(this.downloadExpiredPaymentMethodsForActiveRecurring(result));
			});
			columnMapper = this.mapCustomerColumns;
		} else {
			const { updatedFilters, sortBy } = this.mapQuickReport(report);
			sortColumnBy = sortBy;
			columnMapper = this.replaceColumns;
			reportFetcher = exportService.getTransactionData(
				updatedFilters,
				null,
				null,
				{},
				this.getReportColumnAsArray(sortBy),
				'transactions'
			);
		}
		this.download(reportFetcher, sortColumnBy, columnMapper, report.showDownloadDisclaimer);
	};

	unifyCustomers = data => {
		const customerColumns = cloneDeep(CustomerColumns);
		const filteredCustomerColumns = this.extractColumns(customerColumns, customerColumns);

		let lastCustomerId = '';
		return map(data, row => {
			if (lastCustomerId === row.customerId) {
				let emptyCustomer = {};
				each(filteredCustomerColumns, ({ key }) => {
					emptyCustomer[key] = '';
				});
				return (row = { ...row, ...emptyCustomer });
			}
			lastCustomerId = row.customerId;
			return row;
		});
	};

	fetchAllCustomersAndRecurringSchedules = async () => {
		const defFilter = cloneDeep(filtersOfCustomers);
		const defaultRecurringSchedulesFilters = cloneDeep(filtersOfRecurringSchedules);
		const customersFilter = await this.props.makePendingRequest(compileCustomersFilter(defFilter), requestKeys.FETCH);
		const recurrScheduleFilter = await this.props.makePendingRequest(
			compileRecurringScheduleFilter(defaultRecurringSchedulesFilters),
			requestKeys.FETCH
		);

		const [customers, paymentMethods, recurringSchedules] = await this.props.makePendingRequest(
			Promise.all([
				customerService.filterCustomersAll(null, customersFilter),
				customerService.getPaymentMethods(),
				customerService.filterRecurringSchedulesAll(null, recurrScheduleFilter),
			]),
			requestKeys.FETCH
		);
		const hasExportData =
			customers &&
			customers.xReportData &&
			paymentMethods &&
			paymentMethods.xReportData &&
			recurringSchedules &&
			recurringSchedules.xReportData;

		if (hasExportData) {
			const newRows = customerService.prepareExportData(customers, paymentMethods, true);
			const rows = this.mapRecurringAndCustomersUnified(newRows, recurringSchedules.xReportData, true);
			return rows;
		} else {
			return [];
		}
	};

	fetchExpiredPaymentMethods = async () => {
		const defaultCustomersFilter = cloneDeep(filtersOfCustomers);
		const defaultRecurringSchedulesFilters = cloneDeep(filtersOfRecurringSchedules);
		const customersFilter = await this.props.makePendingRequest(
			compileCustomersFilter(defaultCustomersFilter),
			requestKeys.FETCH
		);
		const recurringSchedulesFilter = await this.props.makePendingRequest(
			compileRecurringScheduleFilter(defaultRecurringSchedulesFilters),
			requestKeys.FETCH
		);

		const [customers, paymentMethods, recurringSchedules] = await this.props.makePendingRequest(
			Promise.all([
				customerService.filterCustomersAll(null, customersFilter),
				customerService.getPaymentMethods(),
				customerService.filterRecurringSchedulesAll(null, recurringSchedulesFilter),
			]),
			requestKeys.FETCH
		);

		if (
			customers &&
			customers.xReportData &&
			paymentMethods &&
			paymentMethods.xReportData &&
			recurringSchedules &&
			recurringSchedules.xReportData
		) {
			customerService.mapCustomerPaymentMethods(customers, paymentMethods, false, true);
			const rows = this.mapRecurringSchedulesAndCustomers(customers.xReportData, recurringSchedules.xReportData);
			const expiredPayments = filter(
				rows,
				row => row.isPaymentMethodExpired === 'Expired' || row.isPaymentMethodExpired === 'About to expire'
			);
			map(expiredPayments, expiredPayment => {
				const temp = expiredPayment.paymentMethodExpiry;
				expiredPayment.paymentMethodExpiry = expiredPayment.paymentMethod;
				expiredPayment.paymentMethod = temp;
			});

			return sortBy(expiredPayments, expiredPayment => expiredPayment.paymentMethodExpiryMoment);
		} else {
			return [];
		}
	};

	downloadExpiredPaymentMethodsForActiveRecurring = data => {
		return filter(data, record => record.isActive);
	};
	mapRecurringSchedulesAndCustomers = (customers, recurringSchedules, exportAll) => {
		const mappedRows = [];
		let recurringScheduleKeys = recurringSchedules.length > 0 ? recurringSchedules[0] : {};
		if (!exportAll) Object.keys(recurringScheduleKeys).forEach(key => (recurringScheduleKeys[key] = ''));
		map(customers, customer => {
			const rawExpiry = this.rawExpiry(customer.paymentMethodExpiry);
			customer.isPaymentMethodExpired = customerService.isExpired(rawExpiry)
				? 'Expired'
				: customerService.isAboutToExpire(rawExpiry)
				? 'About to expire'
				: '';
			const customerSchedules = filter(
				recurringSchedules,
				recurringSchedule => customer.customerId === recurringSchedule.customerId
			);
			if (customerSchedules.length > 0)
				map(customerSchedules, customerSchedule => mappedRows.push({ ...customer, ...customerSchedule }));
			else mappedRows.push({ ...recurringScheduleKeys, ...customer });
		});
		return mappedRows;
	};
	// Function to set common keys with a customer to empty strings
	setCommonKeysToEmpty = (schedule, customer) => {
		let newSchedule = { ...schedule };
		for (let key in newSchedule) {
			if (key in customer && key !== 'customerId') {
				newSchedule[key] = '';
			}
		}
		return newSchedule;
	};

	checkPaymentMethodExpiry = paymentMethod => {
		const rawExpiry = this.rawExpiry(paymentMethod.paymentMethodExpiry);
		if (customerService.isExpired(rawExpiry)) {
			paymentMethod.isPaymentMethodExpired = 'Expired';
		} else if (customerService.isAboutToExpire(rawExpiry)) {
			paymentMethod.isPaymentMethodExpired = 'About to expire';
		} else {
			paymentMethod.isPaymentMethodExpired = '';
		}
		return paymentMethod.isPaymentMethodExpired;
	};

	mapRecurringAndCustomersUnified = (customers, recurringSchedules, exportAll) => {
		const mappedRows = [];

		let recurringScheduleKeys = recurringSchedules.length > 0 ? recurringSchedules[0] : {};
		if (!exportAll) Object.keys(recurringScheduleKeys).forEach(key => (recurringScheduleKeys[key] = ''));

		map(customers, customer => {
			customer.isPaymentMethodExpired = this.checkPaymentMethodExpiry(customer);

			const customerSchedules = filter(
				recurringSchedules,
				recurringSchedule => customer.customerId === recurringSchedule.customerId
			);

			let customerRows = [];
			if (isEmpty(customerSchedules)) {
				customerRows.push(customer);
			} else {
				map(customerSchedules, (customerSchedule, index) => {
					if (index === 0) {
						customerRows.push({ ...customer, ...customerSchedule });
					} else {
						customerRows.push(this.setCommonKeysToEmpty(customerSchedule, customer));
					}
				});
			}

			each(customer.nonDefaultPaymentMethods, pm => {
				let customerAndPaymentMethod = { ...customer, ...pm, isDefaultPaymentMethod: false };
				customerAndPaymentMethod.isPaymentMethodExpired = this.checkPaymentMethodExpiry(customerAndPaymentMethod);
				customerRows.push(customerAndPaymentMethod);
			});

			mappedRows.push(...customerRows);
		});
		return mappedRows;
	};

	rawExpiry = formattedExpriy => {
		if (formattedExpriy) {
			let result = formattedExpriy.split('');
			result.splice(2, 1);
			formattedExpriy = result.join('');
		}
		return formattedExpriy;
	};

	handlePrintExport = async () => {
		this.setState({
			isModalOpen: true,
		});
		try {
			const data = await this.fetchData(
				this.state.filters,
				ApplicationSettings.apiDateTimeFormat,
				this.state.transactionsSortBy,
				false
			);
			if (isEmpty(data)) {
				this.setState({
					isModalOpen: false,
				});
				this.notification.current.addNotification({
					message: 'No data to print for the selected filters',
					infoMessage: true,
				});
				return;
			}
			this.setState(
				{
					isPrintOpen: true,
					data: data,
					isModalOpen: false,
				},
				() => {
					this.printTrigger.current.handlePrint();
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					isPrintOpen: false,
					isModalOpen: false,
				});
			}
		}
	};

	openDeleteModal = reportToDelete => {
		this.setState({
			isDeleteModalOpen: true,
			reportToDelete,
		});
	};

	closeDeleteModal = () => {
		this.setState({
			isDeleteModalOpen: false,
			reportToDelete: null,
		});
	};

	openCloseSaveModal = () => {
		this.setState({
			isSaveModalOpen: !this.state.isSaveModalOpen,
		});
	};

	handleViewReport = () => {
		const { filters, initialRecordsLimit, transactionsSortBy: initialSortBy } = this.state;

		this.props.history.push({
			pathname: '/transactions',
			filters,
			initialRecordsLimit,
			initialSortBy,
			ignoreDefaultFilterValues: true,
		});
	};

	onSaveReport = async (name, filters, sortBy, key) => {
		const {
			isLoading,
			customReports,
			reportFilters,
			pageSize,
			reportToDelete,
			reportToEdit,
			isDeleteModalOpen,
		} = cloneDeep(this.state);
		if (isLoading) {
			return;
		}
		this.setState({ isLoading: true, isSaveModalOpen: false });
		if (isDeleteModalOpen) {
			this.closeDeleteModal();
		}
		let index;
		if (reportToDelete) {
			if (!key) {
				key = reportToDelete.key;
			}
			index = findIndex(customReports, item => item.key === key);
			customReports.splice(index);
			this.setState({ customReports });
		} else {
			if (!key) {
				key = `${moment().format('YYYYMMDDHHmmss')}${uniqueId()}`;
			}
			index = findIndex(customReports, { key });
			const dateFilter = find(filters, { key: 'date' });
			const report = {
				name,
				date: dateFilter.values.key,
				sortBy,
				key,
				actions: {},
			};
			report.dateFilter = find(predefinedDates, { key: report.date });
			report.fetchReport = () => this.fetchReport(report);
			if (index > -1) {
				customReports[index] = report;
			} else {
				customReports.push(report);
			}
			reportFilters[key] = this.mapFilters(filters);
			await this.setStateAsync({
				customReports,
				reportFilters,
				shownCustomReports: slice(customReports, 0, pageSize),
				currentPage: 1,
			});
		}

		let refreshData = false;
		let refNum;
		let error;
		try {
			const [reportsRequest, filtersRequest] = this.mapStateToRequest(key);
			if (!reportToDelete) {
				const [filtersResponse] = await this.props.makePendingRequest(
					kvaasService.save(filtersRequest),
					requestKeys.FILTER(key)
				);
				const newReportState = this.mapResponseToReport(filtersResponse, key);
				await this.props.makePendingRequest(this.setStateAsync(newReportState), requestKeys.STATE);
			}
			const [customReports] = await this.props.makePendingRequest(
				kvaasService.save(reportsRequest),
				requestKeys.REPORTS
			);
			if (reportToDelete) {
				const { revision } = await this.fetchReport(reportToDelete);
				await this.props.makePendingRequest(
					kvaasService.delete({ ...filtersRequest, revision }),
					requestKeys.FILTER(key)
				);
			}
			const newState = this.mapResponseToState(customReports);
			refNum = customReports.refNum;
			await this.props.makePendingRequest(this.setStateAsync(newState), requestKeys.STATE);
			this.handleCancel();
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			try {
				const [customReports] = await this.props.makePendingRequest(
					kvaasService.get(kvaasResources.customReports),
					requestKeys.REPORTS
				);
				const newState = this.mapResponseToState(customReports);
				if (this.state.reportFilters[key]) {
					newState.reportFilters = {
						...this.state.reportFilters,
					};
					delete newState.reportFilters[key];
				}
				refNum = customReports.refNum;
				this.setState(newState);
			} catch (e) {
				error = this.props.handleError(e, { delayMessage: true });
				if (!error) {
					return;
				}
			}
		}
		this.setState({ isLoading: false });
		if (!error) {
			let message = 'Custom report saved';
			if (reportToDelete) {
				message = `Report ${reportToDelete.name} was deleted`;
			}
			if (reportToEdit) {
				message = `Report ${reportToEdit.name} was updated`;
			}
			this.notification.current.addNotification({
				message,
				ref: refNum,
				success: true,
			});
		} else {
			error.show();
		}
	};

	setStateAsync = newState =>
		new Promise(resolve => {
			this.setState(newState, resolve);
		});

	mapFilters = filters => {
		const mappedFilters = {};
		each(filters, ({ hasSelection, values, key }) => {
			if (key !== 'date' && hasSelection) {
				if (!mappedFilters[key]) {
					mappedFilters[key] = {};
				}
				each(values, (value, prop) => {
					if (value) {
						mappedFilters[key][prop] = value;
					}
				});
			}
		});
		return mappedFilters;
	};

	mapResponseToReport = (response, key) => {
		const { oldData, reportFilters } = cloneDeep(this.state);
		const newState = {
			oldData,
			reportFilters,
		};
		const report = {};
		this.checkIfError(newState, response, `reportFilters.${key}`, (value, key) => {
			const [filter, valueKey] = split(key, '.');
			if (!report[filter]) {
				report[filter] = {};
			}
			report[filter][valueKey] = value;
		});
		newState.reportFilters[key] = report;

		return newState;
	};

	mapResponseToState = (oldCustomReports = this.state.oldData.customReports) => {
		const { oldData, customReports, pageSize } = cloneDeep(this.state);
		const newState = {
			oldData,
			customReports,
		};
		try {
			this.checkIfError(newState, oldCustomReports, 'customReports', (report, key) => {
				const [date, sortBy, name] = split(report, '.', 3);
				const mappedReport = {
					name,
					key,
					date,
					sortBy,
					actions: {
						view: true,
						download: true,
						edit: true,
						delete: true,
					},
				};
				mappedReport.dateFilter = find(predefinedDates, { key: date });
				mappedReport.fetchReport = () => this.fetchReport(mappedReport);
				const index = findIndex(customReports, { key });
				if (index > -1) {
					newState.customReports[index] = mappedReport;
				} else {
					newState.customReports.push(mappedReport);
				}
			});
		} catch (e) {
			//eslint-disable-next-line
			console.error(e);
			return {
				customReports: [],
				error: get(e, 'message'),
			};
		}
		newState.customReports = sortBy(newState.customReports, ({ key }) => key);
		newState.shownCustomReports = slice(newState.customReports, 0, pageSize);
		newState.currentPage = 1;

		return newState;
	};

	checkIfError = (newState, oldData, type, callback) => {
		const { data, result, error, refNum } = oldData;
		if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
			if (!error) {
				const [fieldKey, propKey] = split(type, '.');
				const clonedData = { ...oldData };
				if (propKey) {
					newState.oldData[fieldKey][propKey] = clonedData;
				} else {
					newState.oldData[fieldKey] = clonedData;
				}
			}
			each(data, callback);
		} else {
			throw {
				isApiError: true,
				ref: refNum,
				message: error,
				success: false,
			};
		}
	};

	mapStateToRequest = key => {
		const {
			customReports,
			reportFilters,
			oldData: { customReports: oldCustomReports, reportFilters: oldReportFilters },
		} = this.state;
		return [
			this.mapStateToCustomReports(customReports, oldCustomReports),
			this.mapStateToReportFilters(key, reportFilters, oldReportFilters),
		];
	};

	mapStateToReportFilters = (key, reportFilters, oldReportFilters) => {
		const { primaryKey, userSetting, tableName } = kvaasResources.reportFilters(key);
		const data = {};
		const oldData = oldReportFilters[key];
		if (oldData) {
			each(oldData.data, (_, id) => {
				data[id] = null;
			});
		}
		each(reportFilters[key], (filter, id) => {
			each(filter, (value, prop) => {
				if (value) {
					data[`${id}.${prop}`] = value;
				}
			});
		});
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			primaryKey,
			userSetting,
			tableName,
		};
	};

	mapStateToCustomReports = (customReports, oldCustomReports) => {
		const { reportToDelete } = this.state;
		const { primaryKey, userSetting } = kvaasResources.customReports;
		const data = {};
		each(customReports, ({ name, date, sortBy, key }) => {
			if (reportToDelete && key === reportToDelete.key) {
				data[key] = '';
			} else {
				data[key] = `${date}.${sortBy}.${name}`;
			}
		});
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData: oldCustomReports,
			primaryKey,
			userSetting,
		};
	};

	renderDeleteModal = () => {
		const { reportToDelete } = this.state;
		if (!reportToDelete) {
			return null;
		}
		return (
			<React.Fragment>
				<div className="modal__header">
					<h4>Confirm Report Deletion</h4>
				</div>
				<div className="modal__body">
					<p className="p2">
						Are you sure you want to delete <span className="type--wgt--bold">{reportToDelete.name}</span>?
					</p>
				</div>
				<div className="modal__footer">
					<button onClick={this.onSaveReport} className="btn btn--med btn--primary">
						Delete Report
					</button>
				</div>
			</React.Fragment>
		);
	};

	renderToolbar = () => {
		const { isLoading, isPrintOpen, data, error } = this.state;
		return (
			<React.Fragment>
				<div className="flex--primary flex--gap--sml--alt">
					<button
						disabled={isLoading}
						onClick={this.handlePrintExport}
						className="btn btn--med btn--link btn--link--tertiary datatooltip--auto datatooltip--down"
					>
						<i className="icon icon--sml icon--print--light"></i>
						Print
					</button>
					<button
						disabled={isLoading}
						onClick={this.handleDownloadExport}
						className="btn btn--med btn--link btn--link--tertiary datatooltip--auto datatooltip--down"
					>
						<i className="icon icon--sml icon--download"></i>
						Export
					</button>
				</div>
				<div className="flex--primary flex--gap--sml--alt">
					<button
						disabled={isLoading || error}
						data-tooltip={error ? 'Failed to fetch from KVAAS' : null}
						onClick={this.openCloseSaveModal}
						className="btn btn--med btn--secondary"
					>
						Save Report
					</button>
					<button disabled={isLoading} onClick={this.handleViewReport} className="btn btn--med btn--primary">
						View Report
					</button>
				</div>
				{isPrintOpen && !this.state.isModalOpen ? (
					<div style={{ display: 'none' }}>
						<ReactToPrint
							splitColumns={true}
							trigger={() => <div></div>}
							content={() => this.print}
							ref={this.printTrigger}
						/>
						<PrintGridData
							data={data}
							columns={this.getPrintColumns()}
							title="Transaction report"
							type="transactions"
							ref={el => (this.print = el)}
						/>
					</div>
				) : null}
			</React.Fragment>
		);
	};

	render() {
		const {
			filters,
			activeFilters,
			initialRecordsLimit,
			transactionsSortBy,
			isLoadingDownload,
			showDownloadDisclaimer,
			isModalOpen,
			isSaveModalOpen,
			isDeleteModalOpen,
			isLoading,
			shownCustomReports,
			customReports,
			reportToEdit,
			error,
		} = this.state;

		const canShowMoreCustomReports = customReports.length > shownCustomReports.length;

		return (
			<div ref={this.top}>
				<Notification ref={this.notification} />
				<Modal isOpen={isSaveModalOpen} onClose={this.openCloseSaveModal} className="modal__content modal--sml">
					<AddEditCustomReport
						report={reportToEdit}
						filters={filters}
						sortBy={transactionsSortBy.key}
						onSave={this.onSaveReport}
						onCancel={this.openCloseSaveModal}
					/>
				</Modal>
				<Modal isOpen={isDeleteModalOpen} onClose={this.closeDeleteModal}>
					{isDeleteModalOpen ? this.renderDeleteModal() : <div></div>}
				</Modal>
				<MainFilter
					hideSourceKey={true}
					ref={this.mainFilter}
					topRef={this.top}
					filters={filters}
					activeFilters={activeFilters}
					initialRecordsLimit={initialRecordsLimit}
					transactionsSortBy={transactionsSortBy}
					onFiltersUpdate={this.onFilterUpdate}
					resetFilters={this.resetFilters}
					onSortUpdate={this.onSortUpdate}
					onQuickReportDownload={this.handleQuickReportDownloadExport}
					setFilters={this.setFilters}
					customReports={shownCustomReports}
					canShowMoreCustomReports={canShowMoreCustomReports}
					showMoreCustomReports={this.showMoreCustomReports}
					isLoading={isLoading}
					openDeleteModal={this.openDeleteModal}
					handleEdit={this.handleEdit}
					handleCancel={this.handleCancel}
					reportToEdit={reportToEdit}
					renderToolbar={this.renderToolbar}
					error={error}
				/>

				<Modal isOpen={isModalOpen} onClose={this.toggleModal}>
					<div className="modal__body">
						<div className="loader--popup__holder">
							<div className="loader__spinner"></div>
						</div>
						<div className="type--center spc--top--sml">
							<h4>Preparing your report now</h4>
							{isLoadingDownload ? (
								<Fragment>
									<p className="type--p2 type--color--text--light spc--top--sml">
										Please remain in the Reports module until your download is complete.
									</p>
									{showDownloadDisclaimer && (
										<p className="type--p2 type--color--text--light spc--top--sml">
											Your report is preparing for download. Please note that this may take longer than usual.
										</p>
									)}
								</Fragment>
							) : null}
						</div>
					</div>
					<div className="modal__footer"></div>
				</Modal>
			</div>
		);
	}
}

ReportsComponent.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	activeFilters: PropTypes.any,
	history: PropTypes.object,
	reportType: PropTypes.string,
};

export default withLoader(withCancelable(withError(ReportsComponent)));
