import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { padStart } from 'lodash';

class LastDigitsFilterComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cnError: null,
		};
	}

	componentDidUpdate() {
		const { noFocus } = this.props;
		if (this.inputRef && !noFocus) {
			this.inputRef.focus();
		}
	}

	setInputRef = el => {
		this.inputRef = el;
	};

	handleChange = ({ value }) => {
		const {
			numberOfDigits,
			filter: { key },
			onFilterChanged,
		} = this.props;

		let regex = new RegExp(`\\d{${numberOfDigits}}`);

		if (key === 'giftCardNumber') {
			regex = new RegExp(`\\d{7,${numberOfDigits}}`);
		}

		if (value != null && (value.match(regex) != null || value === '')) {
			if (value === '') {
				value = null;
			}
			onFilterChanged({
				id: key,
				values: [{ key, value }],
				emptyValue: null,
			});
		}
	};

	render() {
		const {
			numberOfDigits,
			noFocus,
			placeholder,
			filter: { key, values },
			goButtonHandler,
		} = this.props;
		const { cnError } = this.state;
		return (
			<Fragment>
				<NumberFormat
					inputMode="numeric"
					className="input input--med"
					placeholder={placeholder || `Last ${numberOfDigits} Digits`}
					onValueChange={this.handleChange}
					value={values[key] == null ? '' : values[key]}
					format={padStart('', numberOfDigits, '#')}
					mask={key === 'giftCardNumber' ? '' : '_'}
					getInputRef={this.setInputRef}
					autoFocus={!noFocus}
				/>
				{cnError && <span>{cnError}</span>}
				{goButtonHandler && (
					<button className="btn btn--med btn--primary" onClick={goButtonHandler}>
						Submit
					</button>
				)}
			</Fragment>
		);
	}
}

LastDigitsFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	numberOfDigits: PropTypes.number,
	noFocus: PropTypes.bool,
	placeholder: PropTypes.string,
	goButtonHandler: PropTypes.func,
	goButtonText: PropTypes.string,
};

export default LastDigitsFilterComponent;
