import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { map, some, trim, toLower } from 'lodash';
import PaymentSiteFieldOption from './PaymentSiteFieldOption';

class PaymentSiteFieldOptions extends Component {
	isValidOption = (invalid, isDuplicate) => {
		const duplicate = isDuplicate;
		if (invalid) return invalid;

		return duplicate;
	};

	isDuplicateLabel = (options, key, index) => {
		return some(options, (item, i) => i !== index && trim(item.key) === trim(key));
	};
	isDuplicateKey = (options, value, index) => {
		return some(options, (item, i) => i !== index && trim(item.value) === trim(value));
	};

	disableUp = (moveUp, index, sectionIndex, fieldIndex, options, data) => {
		return (
			options.length === 1 ||
			(moveUp && index === 0) ||
			(!moveUp &&
				(sectionIndex > -1 && fieldIndex > -1) &&
				index === data.sections[sectionIndex].fields[fieldIndex].values.length - 1)
		);
	};
	disableSortOptions = (emptyValues, hasDuplicateValues, allValuesEmpty, hasInvalidOtherValue) => {
		return (
			emptyValues.reportingValue ||
			emptyValues.displayValue ||
			hasDuplicateValues ||
			allValuesEmpty ||
			hasInvalidOtherValue
		);
	};

	renderInvalidOther = invalidOther => {
		return invalidOther.isInvalid ? (
			<p className="type--validation spc--bottom--sml">
				Please use the 'Add Other' option button to input 'Other' values. Manual input of 'Other' is not allowed.
			</p>
		) : null;
	};

	renderInvalidAmount = invalidAmount => {
		return invalidAmount ? <p className="type--validation spc--bottom--sml">Amount is invalid.</p> : null;
	};
	render() {
		const {
			options,
			sectionId,
			hasDuplicateValues,
			invalidOther,
			allValuesEmpty,
			invalidAmount,
			emptyValues,
			isOther,
			sortOptionsAlphabetically,
			addFieldOption,
			addOtherOption,
			data,
			optionsKey,
			handleAmountChange,
			handleOtherChange,
			handleExistingOptionChange,
			removeFieldOption,
			moveOption,
			merchantCurrencyCode,
			getInvalidClassName,
			validateFields,
			handleSortOptionsAlphabetically,
		} = this.props;
		return (
			<div className="paymentsite__card__expand">
				{this.renderInvalidAmount(invalidAmount.isInvalid)}
				{this.renderInvalidOther(invalidOther)}
				{(hasDuplicateValues || emptyValues.reportingValue || emptyValues.displayValue) && (
					<div className="type--validation__wrapper spc--bottom--med">
						{hasDuplicateValues && (
							<p className="type--validation">All Display Label/Reporting Value fields must be unique.</p>
						)}
						{emptyValues.reportingValue && !emptyValues.isAmount && (
							<p className="type--validation">Reporting value is missing.</p>
						)}
						{emptyValues.displayValue && <p className="type--validation">Display value is missing.</p>}
						{emptyValues.isAmount && emptyValues.reportingValue && (
							<p className="type--validation">Amount is missing.</p>
						)}
					</div>
				)}

				<table className="paymentsite__table">
					<colgroup>
						<col width="70" />
						<col />
						<col />
						<col width="0" />
					</colgroup>
					<thead>
						<tr>
							<th></th>
							<th>Display value</th>
							<th>{toLower(optionsKey) === 'xamount' ? 'Amount' : 'Reporting value'}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{map(options, (option, index) => {
							return (
								<PaymentSiteFieldOption
									key={sectionId + index}
									optionsKey={optionsKey}
									sectionId={sectionId}
									option={option}
									index={index}
									merchantCurrencyCode={merchantCurrencyCode}
									data={data}
									handleAmountChange={handleAmountChange}
									handleOtherChange={handleOtherChange}
									handleExistingOptionChange={handleExistingOptionChange}
									removeFieldOption={removeFieldOption}
									options={options}
									isDuplicateLabel={this.isDuplicateLabel}
									isDuplicateKey={this.isDuplicateKey}
									isValidOption={this.isValidOption}
									isExpandedOptionsModalView={true}
									getInvalidClassName={getInvalidClassName}
									disableUp={this.disableUp}
									moveOption={moveOption}
									validateFields={validateFields}
									sortOptionsAlphabetically={sortOptionsAlphabetically}
								/>
							);
						})}
					</tbody>
				</table>
				<div className="paymentsite__card__expand__footer">
					<button onClick={() => addFieldOption(sectionId)} className="btn btn--sml btn--link">
						Add Additional Option
					</button>

					<div className="flex--primary flex--gap--lrg">
						<div>
							<input
								checked={sortOptionsAlphabetically}
								value="sortOptionsAlphabetically"
								type="checkbox"
								id="sorting"
								name="sorting"
								className="input--check"
								onChange={() => handleSortOptionsAlphabetically(sectionId, optionsKey, options)}
								disabled={this.disableSortOptions(
									emptyValues,
									hasDuplicateValues,
									allValuesEmpty,
									invalidOther.isInvalid
								)}
							/>
							<label htmlFor="sorting">Sort Alphabetically</label>
						</div>
						<div className="flex--primary flex--gap--sml flex--nowrap">
							<div>
								<input
									tabIndex="-1"
									type="checkbox"
									name="addOther"
									id={`addOther`}
									value={isOther}
									checked={isOther}
									className="input--toggle"
									onChange={() => addOtherOption(sectionId)}
								/>
								<label htmlFor={`addOther`}></label>
							</div>
							<span
								className="type--p3 datatooltip--w--200"
								data-tooltip='Select this checkbox to include the "Other" option with a text box for user input'
							>
								Add Other Option
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
PaymentSiteFieldOptions.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			displayLabel: PropTypes.string,
			reportingValue: PropTypes.string,
		})
	).isRequired,
	isAmount: PropTypes.bool.isRequired,
	hasDuplicateValues: PropTypes.bool.isRequired,
	invalidOther: PropTypes.object.isRequired,
	invalidAmount: PropTypes.object.isRequired,
	emptyValues: PropTypes.object.isRequired,
	allValuesEmpty: PropTypes.bool.isRequired,
	isOther: PropTypes.bool.isRequired,
	sortOptionsAlphabetically: PropTypes.bool,
	sectionId: PropTypes.string.isRequired,
	optionsKey: PropTypes.string.isRequired,
	merchantCurrencyCode: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
	addFieldOption: PropTypes.func.isRequired,
	addOtherOption: PropTypes.func.isRequired,
	handleAmountChange: PropTypes.func.isRequired,
	handleOtherChange: PropTypes.func.isRequired,
	handleExistingOptionChange: PropTypes.func.isRequired,
	removeFieldOption: PropTypes.func.isRequired,
	moveOption: PropTypes.func.isRequired,
	handleSortOptionsAlphabetically: PropTypes.func.isRequired,
	getInvalidClassName: PropTypes.func.isRequired,
	validateFields: PropTypes.func.isRequired,
};

export default PaymentSiteFieldOptions;
