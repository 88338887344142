import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { map, toLower, isEmpty } from 'lodash';

import { principalService } from '../../../../services';
import { CardTypeImagePath } from 'common/utilities';
import { modalNames } from 'common/components/transaction-actions/modal-names';
import { Tour } from 'common/components/tour';
const { ifieldsSource } = ApplicationSettings;
const commonStyle = {
	width: 'calc(100% - 26px)',
	height: '42px',
	outline: 'none',
	'border-radius': '8px',
};

const inputStyle = {
	...commonStyle,
	padding: '0 12px',
	border: '1px solid #CCCDD3',
	'box-shadow': '',
};

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'newFields',
	steps: [
		{
			selector: '#newFields',
			content: 'The AVS Street and AVS Zip values can now be entered on the customer screen.',
		},
	],
};

/* global setAccount, setIfieldStyle, enableAutoFormatting, ifieldEventCallbacks, addIfieldKeyPressCallback, getTokens */

class AddEditCustomerPaymentsAddEdit extends Component {
	constructor(props) {
		super(props);

		this.paymentNameInput = createRef();
		this.cardNumRef = createRef();
		this.hidCardNumRef = createRef();
		this.accountNumRef = createRef();
		this.hidAccountNumRef = createRef();
	}

	get zipLabel() {
		return this.props.isCanadian ? 'AVS Postal Code' : 'AVS Zip';
	}

	componentDidMount = async () => {
		const { requiredFields, isExpanded, advancedView, advancedViewByDefault } = this.props;
		if (
			this.cardNumRef.current &&
			this.hidCardNumRef.current &&
			this.accountNumRef.current &&
			this.hidAccountNumRef.current &&
			(isExpanded ||
				!advancedView ||
				advancedViewByDefault ||
				(requiredFields && (requiredFields.address || requiredFields[this.zipLabel])))
		) {
			window.ifieldDataCache = {
				cardNumberIsValid: false,
				cardNumberLength: 0,
				cardNumberFormattedLength: 0,
				cardNumberIsEmpty: true,
				issuer: 'unknown',
				cvvIsValid: false,
				cvvLength: 0,
				cvvIsEmpty: true,
				achLength: 0,
				achIsEmpty: true,
				achIsValid: false,
				lastIfieldChanged: '',
			};
			const ifields = principalService.get().ifields;
			setAccount(ifields, SoftwareSettings.name, SoftwareSettings.version);
			setIfieldStyle('card-number', inputStyle);
			setIfieldStyle('ach', inputStyle);
			enableAutoFormatting(' ');
			if (ifieldEventCallbacks) {
				//eslint-disable-next-line
				window.ifieldEventCallbacks = {};
			}
			addIfieldKeyPressCallback(
				function(value) {
					const { onChange, id } = this.props;
					const { cardNumberIsValid, cardNumberIsEmpty, achIsValid, achIsEmpty } = value;
					onChange(id, '_meta.fetchToken', this.getIfieldTokens);
					onChange(id, '_meta.cc', {
						cardNumberIsValid,
						cardNumberIsEmpty,
					});
					onChange(id, '_meta.check', {
						achIsEmpty,
						achIsValid,
					});
				}.bind(this)
			);
		}
	};

	componentDidUpdate = prevProps => {
		const { isExpanded, focusOnMount } = this.props;
		if (prevProps.isExpanded !== isExpanded) {
			if (focusOnMount && this.paymentNameInput.current && isExpanded) {
				this.paymentNameInput.current.focus();
			}
		}
	};

	toggleExpandCollapsePayment = () => {
		const {
			payment: {
				_meta: { id, paymentExpanded },
			},
		} = this.props;
		this.props.onChange(id, '_meta.paymentExpanded', !paymentExpanded);
	};
	onAccountTypeChange = ({ target: { value } }) => {
		const { id } = this.props;
		this.props.onPaymentAccountTypeChange(id, value);
	};
	onChange = ({ target: { name, value } }) => {
		const { id } = this.props;
		this.props.onChange(id, name, value);
	};

	getIfieldTokens = () => {
		const {
			payment: { tokenType },
		} = this.props;
		return new Promise(resolve => {
			if (tokenType === 'cc' && this.hidCardNumRef.current) {
				getTokens(
					() => {
						resolve({
							cc: this.hidCardNumRef.current.value,
						});
					},
					resolve,
					30000
				);
			} else if (tokenType === 'check' && this.hidAccountNumRef.current) {
				getTokens(
					() => {
						resolve({
							check: this.hidAccountNumRef.current.value,
						});
					},
					resolve,
					30000
				);
			} else {
				resolve();
			}
		});
	};

	onNumberChange = ({ value }, { target: { name } }) => {
		const { id } = this.props;
		this.props.onChange(id, name, value);
	};

	isAllowedRoutingNumber = values => {
		const { value } = values;

		return value.length < 10;
	};

	handleDelete = () => {
		const { id, payment, defaultPaymentMethodExists, hasMultiplePayments } = this.props;
		const isOnlyDefaultPaymentMethod = !hasMultiplePayments && payment.isDefaultPaymentMethod;

		if (payment.isDefaultPaymentMethod && !payment._meta.deleted && defaultPaymentMethodExists && hasMultiplePayments) {
			this.props.onChange(id, '_meta.tentativeDelete', true);
		} else {
			if (isOnlyDefaultPaymentMethod && payment.paymentMethodId) {
				this.openDeleteOnlyAvailablePaymentMethod();
			} else {
				this.props.handleDelete(id);
			}
		}
	};

	openDeleteOnlyAvailablePaymentMethod = () => {
		const { handleOpenCloseModal, deactivateActiveSchedulesAndRemovePaymentMethod } = this.props;

		handleOpenCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Deactivating schedules and removing payment method',
				question: (
					<div>
						<p>
							By removing the only available payment method, all recurring schedules will be automatically deactivated.
						</p>
						<br />
						<p>Are you sure you want to remove the only available payment method?</p>
					</div>
				),
				onConfirm: deactivateActiveSchedulesAndRemovePaymentMethod,
			},
		});
	};

	onSetAsDefaultChange = () => {
		const { id } = this.props;
		this.props.onChange(id, 'isDefaultPaymentMethod', true);
	};
	getPaymentTitle = payment => {
		return payment.token
			? payment.tokenAlias || (
					<div className="flex--primary flex--gap--sml">
						<img src={CardTypeImagePath.getPath(payment.cardType)} className="grid__creditcard" />
						<span>{`****${payment.maskedNumber.slice(-4)}`}</span>
					</div>
			  )
			: 'New payment';
	};

	renderAdvancedViewHeader = (payment, paymentTitle, isViewOnly) => {
		return (
			<Fragment>
				<div className="info-panel__heading__wrapper is-expandable" onClick={this.toggleExpandCollapsePayment}>
					<label className="info-panel__heading">{paymentTitle}</label>
					<i
						className={`icon icon--sml icon--chevron--${
							payment._meta.paymentExpanded ? 'down' : 'top'
						}--primary flex--no-shrink`}
					></i>
				</div>
				<div className="info-panel__section">
					<div className="flex--tertiary flex--gap--sml--alt spc--top--sml">
						<div>
							<input
								type="checkbox"
								id={`${payment._meta.id}-isDefaultPaymentMethod`}
								name="isDefaultPaymentMethod"
								className="input--check"
								value={payment.isDefaultPaymentMethod || false}
								checked={payment.isDefaultPaymentMethod || false}
								onChange={this.onSetAsDefaultChange}
							/>
							<label htmlFor={`${payment._meta.id}-isDefaultPaymentMethod`}>Set to Default</label>
						</div>

						{payment.paymentMethodId ? (
							<div>
								<input
									type="checkbox"
									id={`${payment._meta.id}-_meta.deleted`}
									name="_meta.deleted"
									className="input--check"
									value={payment._meta.deleted || false}
									checked={payment._meta.deleted || false}
									onChange={this.handleDelete}
									disabled={isViewOnly}
								/>
								<label htmlFor={`${payment._meta.id}-_meta.deleted`}>
									Remove {toLower(payment.tokenType) === 'cc' ? 'Card' : 'Check'}
								</label>
							</div>
						) : (
							<button className="btn btn--med btn--tertiary" onClick={this.handleDelete} disabled={isViewOnly}>
								<i className="icon icon--sml icon--delete"></i>
								{`Remove ${toLower(payment.tokenType) === 'cc' ? 'Card' : 'Check'}`}
							</button>
						)}
					</div>
				</div>
			</Fragment>
		);
	};
	renderAdvancedViewRow = (payment, isViewOnly, required) => {
		return (
			<Fragment>
				{payment._meta.tentativeDelete && payment.isDefaultPaymentMethod && (
					<div className="f-col f-col-sml-12 form__group">
						<p className="type--validation">Please set default payment method before removing</p>
					</div>
				)}

				{payment._meta.paymentExpanded && (
					<Fragment>
						<div className="f-col f-col-sml-12 f-col-med-6 form__group">
							<div className="form__group__header">
								<label htmlFor={`${payment._meta.id}-tokenAlias`} className="form__group__label">
									Payment name
								</label>
							</div>
							<input
								ref={this.paymentNameInput}
								type="text"
								id={`${payment._meta.id}-tokenAlias`}
								name="tokenAlias"
								className="input input--med"
								placeholder="Payment method name"
								value={payment.tokenAlias || ''}
								onChange={this.onChange}
								disabled={isViewOnly}
							/>
						</div>
						<div className="f-col f-col-sml-12 f-col-med-6 form__group">
							<div className="form__group__header">
								<label htmlFor={`${payment._meta.id}-name`} className="form__group__label">
									{toLower(payment.tokenType) === 'cc' ? 'Cardholder name' : <span>Account name {required}</span>}
								</label>
							</div>
							{payment.paymentMethodId && toLower(payment.tokenType) === 'check' ? (
								<input type="text" className="input input--med" value={payment.name || ''} disabled />
							) : (
								<input
									type="text"
									id={`${payment._meta.id}-name`}
									name="name"
									className="input input--med"
									value={payment.name || ''}
									onChange={this.onChange}
									disabled={isViewOnly}
									placeholder={toLower(payment.tokenType) === 'cc' ? 'Cardholder name' : 'Account name'}
								/>
							)}
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	};
	renderRow = (paymentTitle, payment, isViewOnly) => {
		return (
			<Fragment>
				<div className="f-col f-col-sml-12">
					<div className="info-panel--modal">
						<div
							className="info-panel__heading__wrapper is-expandable spc--bottom--med"
							onClick={this.toggleExpandCollapsePayment}
						>
							<div className="flex--primary">
								<i
									className={`icon icon--sml icon--chevron--${
										payment._meta.paymentExpanded ? 'top' : 'down'
									}--primary spc--right--tny`}
								></i>
								<h6 className="info-panel__heading">{paymentTitle}</h6>
							</div>
							{payment.paymentMethodId ? (
								<div className="display--ib type--left spc--right--med">
									<input
										type="checkbox"
										id={`${payment._meta.id}-_meta.deleted`}
										name="_meta.deleted"
										className="input input--check"
										value={payment._meta.deleted || ''}
										checked={payment._meta.deleted || false}
										disabled={isViewOnly}
										onChange={this.handleDelete}
									/>
									<label htmlFor={`${payment._meta.id}-_meta.deleted`}>
										Remove {toLower(payment.tokenType) === 'cc' ? 'Card' : 'Check'}
									</label>
								</div>
							) : null}
						</div>
					</div>
					<div className="input--check--enable-form spc--bottom--med">
						<input
							type="checkbox"
							id={`${payment._meta.id}-isDefaultPaymentMethod`}
							name="isDefaultPaymentMethod"
							className="input input--check"
							value={payment.isDefaultPaymentMethod || ''}
							checked={payment.isDefaultPaymentMethod || false}
							onChange={this.onSetAsDefaultChange}
							disabled={isViewOnly}
						/>
						<label htmlFor={`${payment._meta.id}-isDefaultPaymentMethod`}>Set to Default</label>
					</div>
				</div>
			</Fragment>
		);
	};

	renderCreditFields = (payment, required, dontDisplay, advancedView, maskedCardNumber, isViewOnly) => {
		return (
			<div className="f-row" style={toLower(payment.tokenType) !== 'cc' ? dontDisplay : null}>
				<div className="f-col f-col-sml-12 f-col-med-6 form__group">
					<div className="form__group__header">
						<label htmlFor={`${payment._meta.id}-_meta.cardNumber`} className="form__group__label">
							Card number
						</label>
						<div className="form__group__required">{required}</div>
					</div>
					{payment.maskedNumber ? (
						<div className="input--cardnumber">
							<div className="input input--med input--disabled datatooltip--w--100" disabled>
								<img src={CardTypeImagePath.getPath(payment.cardType)} className="grid__creditcard" />
								<a
									href={`/transactions?cardNumber=${payment.maskedNumber.slice(-4)}&disabled=true`}
									target="_blank"
									data-tooltip="Filter by last 4 digits"
									className="anchor anchor--primary type--wgt--bold padd--left--med--alt"
								>
									{maskedCardNumber || ''}
								</a>
							</div>
						</div>
					) : (
						<Fragment>
							<iframe
								ref={this.cardNumRef}
								data-ifields-id="card-number"
								className="input--iframe"
								data-ifields-placeholder="XXXX XXXX XXXX XXXX"
								src={ifieldsSource}
							/>
							<input
								type="hidden"
								data-ifields-id="card-number-token"
								name="xCardNum"
								ref={this.hidCardNumRef}
								required
							/>
						</Fragment>
					)}
				</div>

				<div className="f-col f-col-sml-12 f-col-med-6 form__group">
					<div className="form__group__header">
						<label htmlFor={`${payment._meta.id}-exp`} className="form__group__label">
							Exp Date {required}
						</label>
					</div>
					<NumberFormat
						format="##/##"
						id={`${payment._meta.id}-exp`}
						name="exp"
						className="input input--med"
						value={payment.exp || ''}
						placeholder="MM/YY"
						mask={['M', 'M', 'Y', 'Y']}
						onValueChange={this.onNumberChange}
						disabled={isViewOnly}
						inputMode="decimal"
					/>
				</div>
			</div>
		);
	};
	renderCheckFields = (payment, required, dontDisplay, advancedView, isViewOnly) => {
		return (
			<div className="f-row" style={toLower(payment.tokenType) !== 'check' ? dontDisplay : null}>
				<div className="f-col f-col-sml-12 f-col-med-6 form__group">
					<div className="form__group__header">
						<span data-ifields-id="card-data-error" className="form__group__label">
							Account Type
						</span>
						<div className="form__group__required">{required}</div>
					</div>
					<select
						name="accountType"
						className="input input--med input--select"
						value={payment.accountType}
						onChange={this.onAccountTypeChange}
					>
						<option value="" hidden={payment.accountType}>
							Select an account type
						</option>
						<option value={this.props.accountTypeConstants.CHECKING}>Checking</option>
						<option value={this.props.accountTypeConstants.SAVING}>Saving</option>
					</select>
				</div>

				<div className={`f-col f-col-sml-12 f-col-med-6 ${advancedView ? 'f-col-xxlrg-6' : ''} form__group`}>
					<div className="form__group__header">
						<label htmlFor={`${payment._meta.id}-_meta.routingNumber`} className="form__group__label">
							Routing number {required}
						</label>
					</div>
					{payment.maskedNumber ? (
						<input type="text" inputMode="numeric" className="input input--med" value="*********" disabled />
					) : (
						<NumberFormat
							value={payment._meta.routingNumber || ''}
							format="##########"
							placeholder="Routing number"
							className="input input--med"
							inputMode="numeric"
							name="_meta.routingNumber"
							id={`${payment._meta.id}-_meta.routingNumber`}
							onValueChange={this.onNumberChange}
							isAllowed={this.isAllowedRoutingNumber}
							disabled={isViewOnly}
						/>
					)}
				</div>

				{!advancedView && toLower(payment.tokenType) === 'check' && (
					<div className="f-col f-col-sml-12 f-col-med-6 form__group">
						<div className="form__group__header">
							<label htmlFor={`${payment._meta.id}-name`} className="form__group__label">
								Account name {required}
							</label>
						</div>
						{payment.paymentMethodId ? (
							<input type="text" className="input input--med" value={payment.name || ''} disabled />
						) : (
							<input
								type="text"
								id={`${payment._meta.id}-name`}
								name="name"
								className="input input--med"
								value={payment.name || ''}
								onChange={this.onChange}
								disabled={isViewOnly}
								placeholder="Account name"
							/>
						)}
					</div>
				)}

				<div className={`f-col f-col-sml-12 f-col-med-6 ${advancedView ? 'f-col-xxlrg-6' : ''} form__group`}>
					<div className="form__group__header">
						<label htmlFor={`${payment._meta.id}-_meta.accountNumber`} className="form__group__label">
							Account number
						</label>
						<div className="form__group__required">{required}</div>
					</div>
					{payment.maskedNumber ? (
						<input
							type="text"
							inputMode="numeric"
							className="input input--med"
							value={payment.maskedNumber || ''}
							disabled
						/>
					) : (
						<Fragment>
							<iframe
								className="input--iframe"
								data-ifields-id="ach"
								data-ifields-placeholder="Account number"
								src={ifieldsSource}
								ref={this.accountNumRef}
							/>
							<input type="hidden" data-ifields-id="ach-token" name="xAccount" ref={this.hidAccountNumRef} required />
						</Fragment>
					)}
				</div>
			</div>
		);
	};

	renderFields = ({ payment, dontDisplay, advancedView, required, maskedCardNumber, isViewOnly }) => {
		return (
			<div className={payment._meta.paymentExpanded ? 'f-col f-col-sml-12' : 'display--n'}>
				{this.renderCreditFields(payment, required, dontDisplay, advancedView, maskedCardNumber, isViewOnly)}
				{this.renderCheckFields(payment, required, dontDisplay, advancedView, isViewOnly)}
			</div>
		);
	};
	render = () => {
		const { payment, requiredFields, advancedView, isExpanded, isViewOnly } = this.props;
		const maskedCardNumber = payment && !isEmpty(payment.maskedNumber) && `****${payment.maskedNumber.slice(-4)}`;
		const required = (
			<span data-tooltip="Required" className="form__group__required">
				*
			</span>
		);
		const dontDisplay = { display: 'none' };
		const paymentTitle = this.getPaymentTitle(payment);
		if (payment._meta.isLoading) {
			return (
				<div className="loader__holder">
					<div className="loader__spinner"></div>
				</div>
			);
		} else {
			return (
				<Fragment>
					{map(payment._meta.errorMessages, (errorMessage, index) => (
						<p key={index} className="type--validation spc--bottom--med">
							{errorMessage}
						</p>
					))}
					{advancedView && this.renderAdvancedViewHeader(payment, paymentTitle, isViewOnly)}
					<div className="info-panel__section info-panel__section--no-bottom-padding">
						<div className="f-row">
							<Fragment>
								{advancedView && this.renderAdvancedViewRow(payment, isViewOnly, required)}
								{!advancedView && this.renderRow(paymentTitle, payment, isViewOnly)}
								{this.renderFields({
									payment,
									dontDisplay,
									advancedView,
									required,
									maskedCardNumber,
									isViewOnly,
								})}
							</Fragment>
						</div>
						{advancedView && payment._meta.paymentExpanded && (
							<Fragment>
								<div id="newFields" className="f-row">
									<div className="f-col f-col-sml-12 f-col-med-6 form__group">
										<div className="form__group__header">
											<label htmlFor={`${payment._meta.id}-street`} className="form__group__label">
												AVS Street {requiredFields.address && required}
											</label>
										</div>
										<input
											type="text"
											id={`${payment._meta.id}-street`}
											name="street"
											className="input input--med"
											placeholder="AVS Street"
											value={payment.street || ''}
											disabled={isViewOnly}
											onChange={this.onChange}
										/>
									</div>
									<div className="f-col f-col-sml-12 f-col-med-6 form__group">
										<div className="form__group__header">
											<label htmlFor={`${payment._meta.id}-zip`} className="form__group__label">
												{this.zipLabel} {requiredFields.zip && required}
											</label>
										</div>
										<input
											type="text"
											inputMode="numeric"
											id={`${payment._meta.id}-zip`}
											name="zip"
											className="input input--med"
											placeholder={this.zipLabel}
											value={payment.zip || ''}
											disabled={isViewOnly}
											onChange={this.onChange}
										/>
									</div>
								</div>
								{isExpanded && <Tour tourConfig={tourConfig} />}
							</Fragment>
						)}
					</div>
				</Fragment>
			);
		}
	};
}

AddEditCustomerPaymentsAddEdit.propTypes = {
	type: PropTypes.string,
	id: PropTypes.string.isRequired,
	payment: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	handleDelete: PropTypes.func.isRequired,
	savePayment: PropTypes.func.isRequired,
	focusOnMount: PropTypes.bool.isRequired,
	requiredFields: PropTypes.object,
	defaultPaymentMethodExists: PropTypes.bool,
	advancedView: PropTypes.bool.isRequired,
	isCanadian: PropTypes.bool,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	hasMultiplePayments: PropTypes.bool,
	handleOpenCloseModal: PropTypes.func.isRequired,
	deactivateActiveSchedulesAndRemovePaymentMethod: PropTypes.func.isRequired,
	accountTypeConstants: PropTypes.object.isRequired,
	onPaymentAccountTypeChange: PropTypes.func.isRequired,
};

export default AddEditCustomerPaymentsAddEdit;
