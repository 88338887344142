import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
class FooterComponent extends Component {
	renderSaveButton = () => {
		const { hideReset } = this.props;
		return (
			<Fragment>
				{!hideReset && this.renderSetToDefaultButton()}
				<div className="flex--primary flex--gap--sml--alt">
					<p className="type--p3">Settings are saved only after clicking on Save button</p>
					<button
						className="btn btn--med btn--primary"
						disabled={this.props.disabled}
						onClick={() => this.props.save(false)}
					>
						Save Changes
					</button>
				</div>
			</Fragment>
		);
	};

	renderSetToDefaultButton = () => (
		<button
			className="btn btn--med btn--tertiary align--h--left"
			disabled={this.props.isLoading}
			onClick={() => this.props.save(true)}
		>
			Reset to Default
		</button>
	);
	render() {
		return <div className="settings__footer">{this.renderSaveButton()}</div>;
	}
}
FooterComponent.defaultProps = {
	disabled: false,
	hideReset: false,
	isLoading: false,
	save: () => {},
};
FooterComponent.propTypes = {
	disabled: PropTypes.bool,
	hideReset: PropTypes.bool,
	isLoading: PropTypes.bool,
	save: PropTypes.func,
};
export default FooterComponent;
