import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { startsWith, find, get } from 'lodash';

import sectionKeys from '../../routing/sections';
import { principalService, authenticationService, kvaasService, logoManagementService } from '../../Common/services';
import { kvaasResources } from '../../Common/utilities';
import { withCancelable } from '../../Common/components/cancelable';
import { withError } from '../../Common/components/error';
import { hasFeaturePackage, featurePackages } from 'common/utilities/has-feature-package';
import { withForwardRef } from 'common/components/with-forward-ref';
import { Tour } from 'common/components/tour';
import { hasPaymentSites } from 'common/services/helper-service';
import ImageWithFallback from 'common/components/ImageFallback';
import moment from 'moment';

const requestKeys = {
	FETCH: 'fetch',
	KVAAS: 'kvaas',
	LOGO: 'logo',
};

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'sidebarVideoTutorial',
	steps: [
		{
			selector: '#sidebarVideoTutorial',
			content: 'Make the most of your Portal experience. Check out our video demos to learn how to use key features!',
		},
	],
};

class SidebarComponent extends Component {
	constructor(props) {
		super(props);

		const principal = principalService.get();

		this.state = {
			principal,
			dba: this.getDba(principal),
			email: '',
			isHelpOpen: false,
			showDisputesTab: false,
			coBrandPortal: false,
			hasIfields: false,
			logoUrl: false,
		};
	}

	get hasTerminalOnly() {
		return hasFeaturePackage(featurePackages.terminalOnly);
	}

	componentDidMount = async () => {
		const { principal } = this.state;
		try {
			this.subscription = principalService.subscribe(principal => {
				this.setState({ principal, dba: this.getDba(principal) });
			});
			const [user] = await this.props.makePendingRequest(
				Promise.all([authenticationService.getUser(), this.fetchKvaas()]),
				requestKeys.FETCH
			);

			principal.hasAccess[sectionKeys.sendPaymentRequest] = await hasPaymentSites(principal);
			this.setState({
				email: get(user, 'attributes.email', ''),
				principal,
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};

	componentWillUnmount() {
		this.subscription.unsubscribe();
	}
	hasPageAccess(sectionKey) {
		if (this.state.principal && this.state.principal.hasAccess) {
			return this.state.principal.hasAccess[sectionKeys[sectionKey]];
		}
	}
	fetchLogo = async (fetchNew = false) => {
		if (this.state.logoUrl && !fetchNew) {
			return;
		}
		try {
			const { LogoUrl } = await this.props.makePendingRequest(logoManagementService.getLogoUrl(), requestKeys.LOGO);
			let logoUrl = LogoUrl && `${LogoUrl}?${Date.now()}`;
			this.setState({ logoUrl });
		} catch (e) {
			this.props.handleError(e);
		}
	};

	hasAccessGoPlus = () => {
		const { principal } = this.state;
		if (principal && principal.hasAccess) {
			return principal.hasAccess[sectionKeys.goPlus];
		}
		return false;
	};
	fetchKvaas = async () => {
		const { history, makePendingRequest, handleError } = this.props;
		try {
			const [portalFlags, walkthroughSettings, logoManagement] = await makePendingRequest(
				kvaasService.get(kvaasResources.portalFlags, kvaasResources.walkthroughSettings, kvaasResources.logoManagement),
				requestKeys.KVAAS
			);
			const showDisputesTab = get(portalFlags, 'data.showDisputesTab', false) || this.hasAccessGoPlus();
			const sidebarVideoTutorial = get(walkthroughSettings, 'data.sidebarVideoTutorial');
			const isHelpOpen = !sidebarVideoTutorial && !startsWith(history.location.pathname, '/transactions');
			const coBrandPortal = get(logoManagement, 'data.coBrandPortal', false);

			const newState = {
				showDisputesTab,
				isHelpOpen,
				coBrandPortal,
			};

			if (coBrandPortal) {
				await this.fetchLogo();
			}

			if (isHelpOpen) {
				this.props.openSidebar();
				setTimeout(
					() => {
						this.setState(newState);
					},
					isHelpOpen ? 250 : 0
				);
			} else {
				this.setState(newState);
			}
		} catch (e) {
			handleError(e);
		}
	};

	getDba = principal => {
		if (!principal) {
			return;
		}
		const activeKey = find(principal.list, ({ mid }) => mid === principal.id);
		return activeKey && activeKey.dba;
	};

	expandedClass = className =>
		!this.props.isExpanded && window.innerWidth > 1199 ? `${className} is-collapsed` : className;

	redirectToUpgradePlan = (hasCustomerBilling = false) => {
		this.props.history.push({ pathname: '/terminal-only', state: { hasCustomerBilling } });
	};

	onNewTransactionClick = () => {
		const { history } = this.props;
		history.push({
			pathname: '/transactions',
			openNewTransaction: true,
		});
	};

	onNewCustomerClick = async () => {
		try {
			const { history } = this.props;
			const hasIfields =
				!!document.querySelector('.newtransaction__iframe') && history.location.pathname !== '/new-transaction';
			const [userSettings] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.userSettings),
				requestKeys.KVAAS
			);
			const expandNewCustomerPopup = get(userSettings, 'data.expandNewCustomerPopup', false);
			history.push({
				pathname: '/customers/report',
				openNewCustomer: !hasIfields,
				advancedView: !!expandNewCustomerPopup,
			});
		} catch (e) {
			this.props.handleError(e);
		}
	};

	handleOpenCloseHelp = () => {
		this.setState({ isHelpOpen: !this.state.isHelpOpen }, this.props.scrollHelpIntoView);
	};

	onRecurringSchedulePaths = (_, { pathname }) => {
		return startsWith(pathname, '/recurring-schedules');
	};

	onCustomerPaths = (_, { pathname }) => {
		return startsWith(pathname, '/customers');
	};
	render = () => {
		const { email, isHelpOpen, dba, showDisputesTab, hasIfields, hasSelectedRows, coBrandPortal, logoUrl } = this.state;
		const { history } = this.props;
		const principal = get(this.state, 'principal', { hasAccess: {} });
		const isViewOnly = principal.isViewOnly;
		const version =
			SoftwareSettings.version +
			'-' +
			(AppBuildVersion ? AppBuildVersion : '') +
			'-' +
			(AppBuildEnvironment ? AppBuildEnvironment : '');

		return (
			<Fragment>
				<div className="sidebar">
					<div className={this.expandedClass('sidebar__header')}>
						<ImageWithFallback>
							{props => <a href="/" className="sidebar__header__logo" {...props}></a>}
						</ImageWithFallback>
					</div>

					<ul className="nav">
						{this.hasPageAccess(sectionKeys.fraud) ? (
							<li className="nav__item">
								<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/fraudwatch">
									<i className="icon icon--sml nav__icon nav__icon--fraudwatch" />
									<span className="nav__link__text">FraudWatch Reports</span>
								</NavLink>
							</li>
						) : null}
						{this.hasPageAccess(sectionKeys.dashboard) ? (
							<Fragment>
								<li className="nav__item">
									<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" exact to="/">
										<i className="icon icon--sml nav__icon nav__icon nav__icon--dashboard" />
										<span className="nav__link__text">Dashboard</span>
									</NavLink>
								</li>
								{this.hasPageAccess(sectionKeys.transactions) ? (
									<Fragment>
										<li className="nav__item">
											<div
												className={`${this.expandedClass('nav__link shortcut')} ${
													startsWith(history.location.pathname, '/transactions') ? 'is-active' : ''
												}`}
											>
												<NavLink to="/transactions">
													<i className="icon icon--sml nav__icon nav__icon--transactions" />
													<span className="nav__link__text">Transactions</span>
												</NavLink>
												{this.hasPageAccess(sectionKeys.newTransaction) ? (
													<button
														className="btn nav__link__button"
														onClick={() => {
															if (this.hasTerminalOnly) {
																this.redirectToUpgradePlan();
															} else {
																this.onNewTransactionClick();
															}
														}}
													>
														<span className="text">New</span>
														<i className="icon"></i>
													</button>
												) : null}
											</div>
										</li>
										{this.hasPageAccess(sectionKeys.transfers) ? (
											<li className="nav__item">
												<NavLink
													className={this.expandedClass('nav__link')}
													activeClassName="is-active"
													to="/transfers"
												>
													<i className="icon icon--sml nav__icon nav__icon--reports" />
													<span className="nav__link__text">Transfers</span>
												</NavLink>
											</li>
										) : null}
										<li className="nav__item">
											<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/reports">
												<i className="icon icon--sml nav__icon nav__icon--reports" />
												<span className="nav__link__text">Reports</span>
											</NavLink>
										</li>
									</Fragment>
								) : null}
								{this.hasPageAccess(sectionKeys.gift) ? (
									<li className="nav__item">
										<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/giftreport">
											<i className="icon icon--sml nav__icon nav__icon--gift-report" />
											<span className="nav__link__text">Gift Report</span>
										</NavLink>
									</li>
								) : null}
							</Fragment>
						) : this.hasPageAccess(sectionKeys.virtualTerminal) ? (
							<Fragment>
								<li className="nav__item">
									<div
										className={`${this.expandedClass('nav__link shortcut')} ${
											startsWith(history.location.pathname, '/new-transaction') ? 'is-active' : ''
										}`}
									>
										<NavLink to="/new-transaction">
											<i className="icon icon--sml nav__icon nav__icon--transactions" />
											<span className="nav__link__text">New Transaction</span>
										</NavLink>
									</div>
								</li>
							</Fragment>
						) : null}
						{this.hasPageAccess(sectionKeys.batches) ? (
							<li className="nav__item">
								<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/batches">
									<i className="icon icon--sml nav__icon nav__icon--batches" />
									<span className="nav__link__text">Batches</span>
								</NavLink>
							</li>
						) : null}
						{showDisputesTab && this.hasPageAccess(sectionKeys.dashboard) && this.hasPageAccess(sectionKeys.disputes) && (
							<li className="nav__item">
								<NavLink
									className={`${this.expandedClass('nav__link')} ${
										startsWith(history.location.pathname, '/disputes') ? 'is-active' : ''
									}`}
									to="/disputes"
								>
									<i className="icon icon--sml nav__icon nav__icon--exclamation-mark" />
									<span className="nav__link__text">Disputes</span>
								</NavLink>
							</li>
						)}
						{this.hasPageAccess(sectionKeys.customers) && (
							<li className="nav__item">
								<div
									className={`${this.expandedClass('nav__link shortcut')} ${
										startsWith(history.location.pathname, '/customers') ? 'is-active' : ''
									}`}
								>
									<NavLink
										to={`/customers${
											startsWith(history.location.pathname, '/customers/report') ? '/report?refresh=true' : ''
										}`}
										isActive={this.onCustomerPaths}
									>
										<i className="icon icon--sml nav__icon nav__icon--customers" />
										<span className="nav__link__text">Customers</span>
									</NavLink>
									{!isViewOnly && (
										<button
											disabled={hasIfields || hasSelectedRows}
											className="btn nav__link__button"
											onClick={() => {
												if (!hasFeaturePackage(featurePackages.customerBilling)) {
													this.redirectToUpgradePlan(true);
												} else {
													this.onNewCustomerClick();
												}
											}}
										>
											<span className="text">New</span>
											<i className="icon"></i>
										</button>
									)}
								</div>
							</li>
						)}
						{this.hasPageAccess(sectionKeys.dashboard) ? (
							<li className="nav__item">
								<NavLink
									className={this.expandedClass('nav__link')}
									isActive={this.onRecurringSchedulePaths}
									activeClassName="is-active"
									to={`/recurring-schedules${
										startsWith(history.location.pathname, '/recurring-schedules/report') ? '/report?refresh=true' : ''
									}`}
								>
									<i className="icon icon--sml nav__icon nav__icon--recurring-payments" />
									<span className="nav__link__text">Recurring Schedules</span>
								</NavLink>
							</li>
						) : null}
						{this.hasPageAccess(sectionKeys.portalManagement) && (
							<li className="nav__item">
								<NavLink
									className={this.expandedClass('nav__link')}
									activeClassName="is-active"
									to="/portal-management"
								>
									<i className="icon icon--sml nav__icon nav__icon--portal-management" />
									<span className="nav__link__text">Portal Management</span>
								</NavLink>
							</li>
						)}
						{this.hasPageAccess(sectionKeys.newTransaction) && this.hasPageAccess(sectionKeys.sendPaymentRequest) && (
							<li className="nav__item">
								<NavLink
									className={this.expandedClass('nav__link')}
									activeClassName="is-active"
									to="/send-payment-request"
								>
									<i className="icon icon--sml nav__icon nav__icon--payment" />
									<span className="nav__link__text">Send Payment Request</span>
								</NavLink>
							</li>
						)}
						{this.hasPageAccess(sectionKeys.statements) ? (
							<li className="nav__item">
								<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to={'/statements'}>
									<i className="icon icon--sml nav__icon nav__icon--statements" />
									<span className="nav__link__text">Statements</span>
								</NavLink>
							</li>
						) : null}

						{this.hasPageAccess(sectionKeys.featureAddons) && (
							<li className="nav__item">
								<NavLink className={this.expandedClass('nav__link')} activeClassName="is-active" to="/features">
									<i className="icon icon--sml nav__icon nav__icon--addons" />
									<span className="nav__link__text">Feature Add-Ons</span>
								</NavLink>
							</li>
						)}
					</ul>
				</div>

				<ul className="nav">
					<li className="nav__item">
						<NavLink
							className={this.expandedClass('nav__link')}
							activeClassName="is-active"
							to={this.hasTerminalOnly ? '/settings/user-settings/new-transaction' : '/settings/user-settings'}
						>
							<i className="icon icon--sml nav__icon nav__icon--settings" />
							<span className="nav__link__text">Settings</span>
						</NavLink>
					</li>
					<li className="nav__item">
						<Fragment>
							<div
								className={this.expandedClass(isHelpOpen ? 'nav__link is-active' : 'nav__link')}
								onClick={this.handleOpenCloseHelp}
							>
								<i className="icon icon--sml nav__icon nav__icon--support" />
								<span className="nav__link__text">Help Center</span>
								<i className={`icon icon--sml icon--chevron--${isHelpOpen ? 'top--white' : 'down'}`} />
							</div>
							{isHelpOpen && (
								<div className={`${!this.props.isExpanded ? 'nav--help-center__wrapper is-collapsed' : ''}`}>
									{!this.props.isExpanded && (
										<div className="nav--help-center__header">
											<i className="icon icon--sml icon--info--white"></i>
											<p>Help Center</p>
										</div>
									)}
									<ul className={`nav--help-center ${!this.props.isExpanded ? ' is-collapsed' : ''}`}>
										<li className="nav__item">
											<a
												className={this.expandedClass('nav__link')}
												href={`https://cardknox.wufoo.com/forms/z30vu2k0i7r23e/?Field118=${encodeURIComponent(
													dba
												)}&Field119=${encodeURIComponent(email)}`}
												target="_blank"
											>
												<i className="icon icon--sml icon--contact-us--light"></i>
												<span className="nav__link__text">Contact Us</span>
											</a>
										</li>
										<li className="nav__item">
											<a
												className={this.expandedClass('nav__link')}
												href="https://kb.cardknox.com/portal-demo"
												rel="noopener noreferrer"
												target="_blank"
												id="sidebarVideoTutorial"
											>
												<i className="icon icon--sml icon--play-control--light"></i>
												<span className="nav__link__text">Video Tutorial</span>
											</a>
										</li>
										<li className="nav__item">
											<a
												className={this.expandedClass('nav__link')}
												href="https://kb.cardknox.com/portal-faq"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i className="icon icon--sml icon--print--light"></i>
												<span className="nav__link__text">FAQ</span>
											</a>
										</li>
										<li className="nav__item">
											<a
												className={this.expandedClass('nav__link')}
												href="https://kb.cardknox.com/portal"
												rel="noopener noreferrer"
												target="_blank"
											>
												<i className="icon icon--sml icon--microphone--light"></i>
												<span className="nav__link__text">User Guide</span>
											</a>
										</li>
										<li className="nav__item">
											<a
												className={this.expandedClass('nav__link')}
												href="https://www.cardknox.com/remote"
												rel="noopener noreferrer"
												target="_blank"
												id="lastElementInHelp"
											>
												<i className="icon icon--sml icon--reports--light"></i>
												<span className="nav__link__text">Remote Support</span>
											</a>
										</li>
									</ul>
								</div>
							)}
						</Fragment>
					</li>
				</ul>
				{coBrandPortal && logoUrl && (
					<div className="sidebar__cobrand-logo">
						<img src={logoUrl} alt="Logo Image" />
					</div>
				)}
				<div className="sidebar__footer">
					&#169; Copyright {moment().format('YYYY')}
					<br />({version})
				</div>
				{isHelpOpen && !startsWith(history.location.pathname, '/transactions') && <Tour tourConfig={tourConfig} />}
			</Fragment>
		);
	};
}

SidebarComponent.propTypes = {
	isExpanded: PropTypes.bool,
	location: PropTypes.object,
	history: PropTypes.object,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	scrollHelpIntoView: PropTypes.func.isRequired,
	openSidebar: PropTypes.func,
};

export default withError(withCancelable(withForwardRef(SidebarComponent, withRouter)));
