import React from 'react';
import PropTypes from 'prop-types';
import { hasFeaturePackage } from 'common/utilities';
import { featurePackageTooltips, featurePackages } from 'common/utilities/has-feature-package';
import { get, includes, split, toLower } from 'lodash';

const hasTerminalOnly = () => {
	return hasFeaturePackage(featurePackages.terminalOnly);
};
const isVoid = (xVoid, xResponseResult) => {
	return isApproved(xResponseResult) && xVoid === '1';
};
const isViewOnly = principal => {
	return get(principal, 'isViewOnly', false);
};

const isApproved = xResponseResult => {
	return toLower(xResponseResult) === 'approved';
};
const isRefund = (xCommand = '') => {
	return xCommand.toLowerCase().split(':')[1] === 'refund';
};
const shouldDisableAddCustomer = (
	xResponseResult,
	hasCustomerBilling,
	isNotAllowedPaymentMethod,
	xMerchantId,
	xVoid,
	principal
) =>
	toLower(xResponseResult) === 'declined' ||
	hasTerminalOnly() ||
	!hasCustomerBilling ||
	isNotAllowedPaymentMethod ||
	isVoid(xVoid, xResponseResult) ||
	isViewOnly(principal) ||
	!!xMerchantId;

const RenderLinkPaymentMethodOrCustomer = props => {
	const {
		row: { xResponseResult, xCommand, xMerchantId, xToken, xVoid },
		isLinkPaymentMethod,
		customClassNames,
		openLinkTransactionPopup,
		principal,
	} = props;

	const isErroredToken = xResponseResult === 'Error' && xToken;
	const hasCustomerBilling = hasFeaturePackage(featurePackages.customerBilling);
	const paymentMethod = split(toLower(xCommand), ':')[0];
	const isNotAllowedPaymentMethod =
		!includes(['cc', 'check'], paymentMethod) &&
		toLower(xCommand) !== 'gift:redeem' &&
		toLower(xCommand) !== 'gift:issue';

	const disabled = shouldDisableAddCustomer(
		xResponseResult,
		hasCustomerBilling,
		isNotAllowedPaymentMethod,
		xMerchantId,
		xVoid,
		principal
	);

	let tooltip = null;

	if (toLower(xResponseResult) === 'declined' || isNotAllowedPaymentMethod || isVoid(xVoid, xResponseResult)) {
		tooltip = isLinkPaymentMethod
			? "The Payment method can't be linked to a customer"
			: "The transaction can't be linked to a customer";
	} else if (hasTerminalOnly()) {
		tooltip = featurePackageTooltips.hasTerminalOnly;
	} else if (!hasCustomerBilling) {
		tooltip = featurePackageTooltips.noCustomerBilling;
	}
	if (isViewOnly(principal)) {
		tooltip = 'Permissions required';
	}

	if (isApproved(xResponseResult) || disabled || isErroredToken) {
		return (
			<li data-tooltip={tooltip} className="datatooltip--w--200">
				<button
					className={customClassNames || 'btn btn--link btn--link--tertiary'}
					disabled={disabled || isRefund(xCommand)}
					onClick={() => openLinkTransactionPopup(isLinkPaymentMethod)}
				>
					<i className={`icon icon--lrg ${isLinkPaymentMethod ? 'icon--link--light' : 'icon--add-user--light'}`}></i>
					{isLinkPaymentMethod ? 'Link payment method' : 'Add new customer'}
				</button>
			</li>
		);
	}
};
RenderLinkPaymentMethodOrCustomer.propTypes = {
	row: PropTypes.shape({
		xResponseResult: PropTypes.string.isRequired,
		xCommand: PropTypes.string.isRequired,
		xMerchantId: PropTypes.string.isRequired,
		xToken: PropTypes.string,
	}).isRequired,
	isLinkPaymentMethod: PropTypes.bool,
	customClassNames: PropTypes.string,
	openLinkTransactionPopup: PropTypes.func.isRequired,
	principal: PropTypes.object.isRequired,
};

export default RenderLinkPaymentMethodOrCustomer;
