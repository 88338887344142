export const emailTemplates = {
	customSendPaymentRequest: 'sendCustomPaymentRequest',
	sendPaymentRequest: `sendDefaultPaymentRequest`,
	welcomeToCardknox: 'welcometocardknox',
	upgradePlan: 'upgradeplan',
	paymentSite: 'paymentsite',
	errorReport: 'errorreport',
	mobileApp: 'mobileapp'
};
export default emailTemplates;
