import React from 'react';
import PropTypes from 'prop-types';
const PaymentEngineCard = ({ software, computers, isLoading, addNewComputer, setEditModal, setPreviewModal }) => {
	return (
		<div className="card spc--bottom--sml">
			<div className="card__header card__header--border">
				<h5>{software}</h5>
				<button className="btn btn--med btn--primary" onClick={addNewComputer(software)} disabled={isLoading}>
					Add New Computer
				</button>
			</div>
			<div className="card__body">
				<span className="display--b type--title type--uppercase spc--top--lrg spc--bottom--tny">Computers</span>
				<table className="table table--primary table--payment-engine">
					<tbody>
						{computers.map((computer, index) => (
							<tr key={index}>
								<td className="type--p1">{computer}</td>
								<td>
									<div className="flex--primary flex--gap--tny flex--right">
										<button
											className="btn btn--action btn--action--secondary"
											onClick={setEditModal(software, computer)}
											disabled={false}
										>
											<i className="icon icon--sml icon--edit"></i>
										</button>
										<button className="btn btn--med btn--tertiary" onClick={setPreviewModal(software, computer)}>
											Preview
										</button>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};
PaymentEngineCard.propTypes = {
	software: PropTypes.string.isRequired,
	computers: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	addNewComputer: PropTypes.func.isRequired,
	setEditModal: PropTypes.func.isRequired,
	setPreviewModal: PropTypes.func.isRequired,
};

export default PaymentEngineCard;
