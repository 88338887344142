import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { invokeIfFunction } from 'common/utilities';

class AddOn extends Component {
	constructor(props) {
		super(props);
		this.state = { activating: false };
	}
	navigateToPath = path => {
		const newPath = `features/${path}`;
		const currentPath = this.props.history.location.pathname;
		if (currentPath !== `/${newPath}`) {
			this.props.history.push(`/${newPath}`);
		}
	};
	get getClassName() {
		return this.props.isActive ? 'add-ons__card add-ons__card--active' : 'add-ons__card';
	}
	render() {
		const { onBeforeActivate } = this.props;
		const {
			title,
			details,
			templateId,
			path,
			successMessage,
			question,
			activateLabel,
			additionalContent,
		} = this.props.data;
		return (
			<div className={this.getClassName}>
				<div className="add-ons__card__logo"></div>
				<label className="add-ons__card__title">{title}</label>
				<p className="add-ons__card__description">{invokeIfFunction(details)}</p>
				<div className="add-ons__card__footer">
					<button onClick={() => this.navigateToPath(path)} className="btn btn--sml btn--tertiary">
						Show Details
					</button>
					<button
						onClick={() => onBeforeActivate(templateId, successMessage, path, question, additionalContent)}
						className="btn btn--sml btn--primary"
					>
						{activateLabel || 'Activate'}
					</button>
				</div>
			</div>
		);
	}
}

AddOn.propTypes = {
	data: PropTypes.object.isRequired,
	makePendingRequest: PropTypes.func,
	history: PropTypes.object,
	onBeforeActivate: PropTypes.func,
	isActive: PropTypes.bool.isRequired,
};

export default AddOn;
