import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, some } from 'lodash';

import AddEditCustomerGeneralAddEdit from './add-edit';
import CustomerPreview from './components/customer-preview';

class AddEditCustomerGeneral extends Component {
	onChange = change => {
		this.props.onChange(change);
	};

	onCustomBlur = fieldName => {
		this.props.onCustomBlur(fieldName);
	};

	render() {
		const {
			data,
			// refNum,
			errorMessages,
			advancedView,
			isCanadian,
			isExpanded,
			isLoading,
			requiredFields,
			blurredField,
			savedGeneral,
			renderFailedToFetch,
			customerRequiredFields,
			customDisplayLabels,
			customerHiddenFields,
			isViewOnly,
			isPreview,
			displayPreview,
			togglePreviewEdit,
		} = this.props;

		return (
			<Fragment>
				{!advancedView && (
					<Fragment>
						{renderFailedToFetch('spc--bottom--sml', 'message message--warning fullwidth')}
						{!some(['billFirstName', 'billLastName', 'billCompany'], field => data[field]) && (
							<div className="notes notes--primary spc--bottom--med flex--primary">
								<i className="icon"></i>
								<p className="type--p3">
									{`${customDisplayLabels['firstName'] || 'First Name'}, ${customDisplayLabels['lastName'] ||
										'Last Name'} or ${customDisplayLabels['company'] || 'Company'} is required.`}
								</p>
							</div>
						)}
					</Fragment>
				)}
				{map(errorMessages, (errorMessage, index) => (
					<p key={index} className="type--validation spc--bottom--med">
						{errorMessage}
					</p>
				))}
				{isPreview && displayPreview && (
					<button onClick={togglePreviewEdit} className="grid-sidebar__edit-button">
						<i className="icon icon--lrg icon--chevron--left--primary"></i>
						Edit
					</button>
				)}
				{isPreview ? (
					<CustomerPreview
						customDisplayLabels={customDisplayLabels}
						data={data}
						customerHiddenFields={customerHiddenFields}
						isCanadian={isCanadian}
					/>
				) : (
					<AddEditCustomerGeneralAddEdit
						data={data}
						onChange={this.onChange}
						onCustomBlur={this.onCustomBlur}
						blurredField={blurredField}
						savedGeneral={savedGeneral}
						advancedView={advancedView}
						isCanadian={isCanadian}
						isExpanded={isExpanded}
						isLoading={isLoading}
						requiredFields={requiredFields}
						customDisplayLabels={customDisplayLabels}
						customerRequiredFields={customerRequiredFields}
						customerHiddenFields={customerHiddenFields}
						isViewOnly={isViewOnly}
						isPreview={isPreview}
					/>
				)}
			</Fragment>
		);
	}
}

AddEditCustomerGeneral.propTypes = {
	data: PropTypes.object,
	errorMessages: PropTypes.arrayOf(PropTypes.string),
	refNum: PropTypes.string,
	onChange: PropTypes.func,
	onCustomBlur: PropTypes.func,
	advancedView: PropTypes.bool.isRequired,
	isCanadian: PropTypes.bool,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	blurredField: PropTypes.any,
	savedGeneral: PropTypes.any,
	renderFailedToFetch: PropTypes.func.isRequired,
	customerRequiredFields: PropTypes.object,
	customerDisplayLabels: PropTypes.object,
	customerHiddenFields: PropTypes.object,
	isPreview: PropTypes.bool,
	displayPreview: PropTypes.bool,
	togglePreviewEdit: PropTypes.func,
};

export default AddEditCustomerGeneral;
