import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageWithFallback from 'common/components/ImageFallback';

class PublicLayoutComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<div className="auth">
					<aside className="auth__sidebar">
						<ImageWithFallback imgAttributes={{ alt: 'logo' }}>
							{props => <img src={'static/media/sola-logo.svg'} className="auth__sidebar__logo" {...props} />}
						</ImageWithFallback>
					</aside>

					<div className="auth__main">{this.props.children}</div>
				</div>
			</React.Fragment>
		);
	}
}

PublicLayoutComponent.propTypes = {
	children: PropTypes.any,
};

export default PublicLayoutComponent;
