import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Notification } from 'common/components/notifications';
import { withLoader } from 'common/components/loader';
import { find, get, head, isArray, join, map, mapValues, tail, toLower, toUpper } from 'lodash';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import settingService from 'common/services/settingsService';
import AvsSettings from './fraud-settings/AvsSettings';
import TransactionScreening from './fraud-settings/TransactionScreening';
import AdditionalFraudSettings from './fraud-settings/AdditionalFraudSettings';
import FraudSettingsBlockList from './fraud-settings/FraudSettingsBlockList';
import { countryList } from 'components/new-transaction/constants';
import { transactionService } from 'common/services';
import FooterComponent from '../components/FooterComponent';

class FraudSettings extends Component {
	constructor(props) {
		super(props);
		this.transactionService = transactionService;
		this.state = {
			isExpandedAll: false,
			isAvsExpanded: false,
			isFraudExpanded: false,
			isTransactionExpanded: false,
			isAdditionalExpanded: false,
			cardknoxSettings: {
				AVS: '',
				CVV: '',
				AVS_USOnly: false,
			},
			affSettings: {
				selectedCountryToDeny: [],
				selectedCountryToAllow: [],
				AFF_WhiteList_IPs: [],
				AFF_Allow_Country: [],
				AFF_Allow_Country_BlockOnFailure: false,
				AFF_Block_CardBin: [],
				AFF_Block_Email: [],
				AffBlockName: [],
				AFF_Block_IP: [],
				AFF_Block_IP_3: [],
				AFF_Block_IP_4: [],
				AFF_Block_Keyed: false,
				AFF_Velocity_NumberOfCards: '',
				BlockAddressPoBox: false,
				BlockPrepaid: false,
				BlockNonUSCanada: false,
				AFF_BlockCardByPaymentId: [],
			},
		};
		this.notificationRef = createRef();
	}

	get isLoading() {
		return this.props.isLoading;
	}
	convertStringToBoolean = value => {
		if (typeof value === 'string') {
			const lowerCaseValue = value.toLowerCase();
			if (lowerCaseValue === 'true') {
				return true;
			}
			if (lowerCaseValue === 'false') {
				return false;
			}
		}
		return value;
	};

	convertAffSettingsToArray = affSettings => {
		return mapValues(affSettings, (value, key) => {
			value = this.convertStringToBoolean(value);
			if (typeof value === 'string') {
				if (value === '') {
					return key === 'AFF_Allow_Country' ? [''] : [];
				}
				let valuesArray = value.split(',');
				if (key === 'AFF_Allow_Country') {
					// Map country codes to country names
					valuesArray = valuesArray.map(code => {
						const country = find(countryList, { code: toUpper(code) });
						return country ? country.name : code;
					});
				}
				return valuesArray;
			}
			return value;
		});
	};
	convertAffSettingsForApi = affSettings => {
		return mapValues(affSettings, (value, key) => {
			value = this.convertStringToBoolean(value);
			if (isArray(value)) {
				if (head(value) === '') {
					value = tail(value);
				}
				if (key === 'AFF_Allow_Country') {
					// Map country names to country codes
					value = map(value, name => {
						const country = find(countryList, country => toLower(country.name) === toLower(name));
						return country ? country.code : name;
					});
				}
				return join(value, ',');
			}
			return value;
		});
	};
	async componentDidMount() {
		this.props.showLoader(true);
		try {
			const [fraudSettings, advancedFraudSettings] = await this.props.makePendingRequest(
				Promise.all([settingService.loadSettings('FRAUD'), settingService.loadSettings('AdvancedFraudFeatures')]),
				'fetch'
			);

			const { cardknoxSettings } = fraudSettings;
			let { cardknoxSettings: affSettings } = advancedFraudSettings;
			if (cardknoxSettings.AVS_USOnly) {
				cardknoxSettings.AVS_USOnly = toLower(cardknoxSettings.AVS_USOnly) === 'true';
			}
			let isBasicAvs =
				cardknoxSettings.AVS === '' ||
				cardknoxSettings.AVS === 'nnn,nyz,nyw' ||
				cardknoxSettings.AVS === 'nnn,yna' ||
				cardknoxSettings.AVS === 'nnn,nyz,nyw,yna,nyw';
			this.setState({
				cardknoxSettings,
				affSettings: this.convertAffSettingsToArray(affSettings),
				isBasicAvs,
			});
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader(false);
	}
	toggleExpand = componentKey => {
		this.setState(prevState => ({ [componentKey]: !prevState[componentKey] }));
	};
	toggleExpandCollapseAll = expand => () => {
		this.setState({
			...this.state,
			isAvsExpanded: expand,
			isFraudExpanded: expand,
			isTransactionExpanded: expand,
			isAdditionalExpanded: expand,
			isExpandedAll: expand,
		});
	};

	handleSettingsUpdate = (settingKey, setting) => {
		this.props.handleBlockChange(true);
		this.setState(prevState => ({
			...prevState,
			[settingKey]: {
				...prevState[settingKey],
				...setting,
			},
			[settingKey + 'Changed']: true,
		}));
	};

	getCardByRefNum = async refNum => {
		this.props.showLoader(true);
		try {
			const result = await this.transactionService.filterTransactionsRequest(
				{
					xRefNum: refNum,
					xCommand: 'Report:Transactions',
				},
				1,
				['xPaymentId', 'xMaskedCardNum', 'xCommand', 'xCardType']
			);
			this.props.showLoader(false);
			const data = await this.transactionService.parseResult(result);
			const xReportData = data.xReportData[0];
			if (toLower(get(xReportData, 'xCardType', '')) === 'check') {
				this.notificationRef.current.addNotification({
					message: 'Check transactions cannot be blocked',
					success: false,
				});
				return null;
			}

			return data;
		} catch (error) {
			this.props.showLoader(false);
			return null;
		}
	};
	handleUpdateData = async () => {
		this.props.showLoader(true);
		try {
			const { cardknoxSettingsChanged, affSettingsChanged, cardknoxSettings, affSettings } = this.state;
			let ref = '';
			let isSettingsChanged = false;

			if (cardknoxSettingsChanged) {
				const updatedCardknoxSettings = { ...cardknoxSettings };
				updatedCardknoxSettings.AVS_USOnly = toLower(updatedCardknoxSettings.AVS_USOnly) === 'true';
				const result = await this.props.makePendingRequest(
					settingService.updateSettings('Fraud', updatedCardknoxSettings)
				);
				ref += result.refNum;
				isSettingsChanged = true;
			}
			affSettings.AFF_Velocity_NumberOfCards = parseInt(affSettings.AFF_Velocity_NumberOfCards, 10);

			if (affSettingsChanged) {
				const result = await this.props.makePendingRequest(
					settingService.updateSettings('AdvancedFraudFeatures', this.convertAffSettingsForApi(affSettings))
				);
				ref += `\n${result.refNum}`;
				isSettingsChanged = true;
			}

			if (isSettingsChanged) {
				this.notificationRef.current.addNotification({
					message: 'Settings saved successfully',
					success: true,
					ref,
				});
			}

			this.props.handleBlockChange(false);
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader(false);
	};

	render() {
		const {
			cardknoxSettings,
			affSettings,
			isAvsExpanded,
			isFraudExpanded,
			isTransactionExpanded,
			isAdditionalExpanded,
		} = this.state;

		return (
			<div className="settings--main">
				<Notification ref={this.notificationRef} />

				<div className="settings__header">
					<h3 className="settings__title">Fraud Settings</h3>
				</div>

				<AvsSettings
					cardknoxSettings={cardknoxSettings}
					handleSettingsUpdate={this.handleSettingsUpdate}
					isLoading={this.props.isLoading}
					state={this.state}
					handleBlockChange={this.props.handleBlockChange}
					isExpanded={isAvsExpanded}
					toggleExpand={() => this.toggleExpand('isAvsExpanded')}
				/>
				<FraudSettingsBlockList
					affSettings={affSettings}
					handleSettingsUpdate={this.handleSettingsUpdate}
					getCardByRefNum={this.getCardByRefNum}
					isLoading={this.props.isLoading}
					handleBlockChange={this.props.handleBlockChange}
					isExpanded={isFraudExpanded}
					toggleExpand={() => this.toggleExpand('isFraudExpanded')}
				/>
				<TransactionScreening
					affSettings={affSettings}
					handleSettingsUpdate={this.handleSettingsUpdate}
					isLoading={this.props.isLoading}
					handleBlockChange={this.props.handleBlockChange}
					isExpanded={isTransactionExpanded}
					toggleExpand={() => this.toggleExpand('isTransactionExpanded')}
				/>
				<AdditionalFraudSettings
					affSettings={affSettings}
					handleSettingsUpdate={this.handleSettingsUpdate}
					isLoading={this.props.isLoading}
					handleBlockChange={this.props.handleBlockChange}
					isExpanded={isAdditionalExpanded}
					toggleExpand={() => this.toggleExpand('isAdditionalExpanded')}
				/>

				<FooterComponent
					save={this.handleUpdateData}
					isLoading={this.props.isLoading}
					disabled={this.isLoading}
					hideReset={true}
				/>
			</div>
		);
	}
}

FraudSettings.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	showLoader: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
};
export default withLoader(withError(withCancelable(withBlock(FraudSettings))));
