import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

const PaymentSiteActionsComponent = ({ history, match: { path }, dependentValues }) => {
	const onEdit = () => {
		const { id } = dependentValues;
		history.push(`${path}/${id}`);
	};
	return (
		<Fragment>
			<button type="button" className="btn btn--reset" onClick={onEdit}>
				<i className="icon icon--sml icon--edit spc--right--med" />
			</button>
		</Fragment>
	);
};

PaymentSiteActionsComponent.propTypes = {
	dependentValues: object,
	history: object,
	match: object,
};

export default withRouter(PaymentSiteActionsComponent);
