import React, { Component, createRef, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { toLower, isEmpty } from 'lodash';
import { parse } from 'query-string';

import { withError } from '../../Common/components/error';
import { withCancelable } from '../../Common/components/cancelable';
import { withLoader } from '../../Common/components/loader';
import { principalService, cardholderUpdaterService } from '../../Common/services';
import { Notification } from '../../Common/components/notifications';
import { FormErrors, CurrencyMap } from '../../Common/utilities';
import validators from '../../Common/fields/validators';
import { defaultIfields } from 'common/fields';
import { Modal } from '../../Common/components/modal';
import PrivacyPolicy from '../../assets/privacy_policy.html';

const { ifieldsSource } = ApplicationSettings;

const commonStyle = {
	width: 'calc(100% - 26px)',
	height: '42px',
	outline: 'none',
	'border-radius': '8px',
};

const inputStyle = {
	...commonStyle,
	padding: '0 12px',
	border: '1px solid #CCCDD3',
	'box-shadow': '',
};

const invalidStyle = {
	...commonStyle,
	padding: '0 12px',
	border: '1px solid #C83E4D',
};

/* global setAccount, setIfieldStyle, enableAutoFormatting, ifieldEventCallbacks, addIfieldKeyPressCallback, getTokens */

class CardholderUpdater extends Component {
	constructor() {
		super();

		this.cardNumRef = createRef();
		this.hidCardNumRef = createRef();
		this.notificationRef = createRef();

		this.state = {
			amount: '',
			merchantName: '',
			lastFour: '',
			originalMerchantOnly: true,
			useForThisAccount: true,
			formSubmitted: false,
			errors: {},
			refNum: '',
			ccToken: '',
			token: '',
			expDate: '',
			useForAllAccounts: false,
			cardNumberIsEmpty: true,
			cardNumberIsValid: true,
			privacyPolicyVisible: false,
		};
	}

	componentDidMount = () => {
		const {
			location: { search },
		} = this.props;
		if (this.cardNumRef.current) {
			window.ifieldDataCache = {
				cardNumberIsValid: false,
				cardNumberLength: 0,
				cardNumberFormattedLength: 0,
				cardNumberIsEmpty: true,
				issuer: 'unknown',
				cvvIsValid: false,
				cvvLength: 0,
				cvvIsEmpty: true,
				achLength: 0,
				achIsEmpty: true,
				achIsValid: false,
				lastIfieldChanged: '',
			};
			const ifields = principalService.get().ifields || defaultIfields;
			setAccount(ifields, SoftwareSettings.name, SoftwareSettings.version);
			setIfieldStyle('card-number', inputStyle);
			enableAutoFormatting(' ');
			if (ifieldEventCallbacks) {
				//eslint-disable-next-line
				window.ifieldEventCallbacks = {};
			}
			addIfieldKeyPressCallback(
				function(value) {
					const { cardNumberIsValid, cardNumberIsEmpty } = value;
					this.setState({ cardNumberIsValid, cardNumberIsEmpty }, this.validateInputs);
				}.bind(this)
			);
		}
		this.parseUrltoState(search);
	};

	redirect = () => {
		this.props.history.push('/');
	};

	parseUrltoState = search => {
		try {
			const { Amount, MerchantName, LastFour, Token, Refnum } = parse(atob(search.substr(1)));
			if (!Token) {
				this.redirect();
			}
			const newState = {
				amount: Amount,
				merchantName: MerchantName,
				lastFour: LastFour,
				token: Token,
				refNum: Refnum,
			};
			this.setState(newState);
		} catch (e) {
			this.redirect();
		}
	};

	getIfieldTokens = () => {
		return new Promise(resolve => {
			if (this.hidCardNumRef.current) {
				getTokens(
					() => {
						resolve({
							ccToken: this.hidCardNumRef.current.value,
						});
					},
					resolve,
					30000
				);
			} else {
				resolve();
			}
		});
	};

	validateInputs = () => {
		const { cardNumberIsEmpty, cardNumberIsValid, expDate, formSubmitted } = this.state;
		let hasErrors = false;
		const newState = { errors: {} };
		if (cardNumberIsEmpty || !cardNumberIsValid) {
			newState.errors.cardNumber = cardNumberIsEmpty ? 'Card Number is required' : 'Card Number is not valid';
			hasErrors = true;
		}
		if (!expDate || !validators.expDate(expDate)) {
			newState.errors.expDate = !expDate ? 'Expiration Date is required' : 'Expiration Date is not valid';
			hasErrors = true;
		}
		if (formSubmitted) {
			this.setState(newState);
		}
		return hasErrors;
	};

	updateCard = async () => {
		this.setState({ formSubmitted: true }, async () => {
			try {
				if (!this.validateInputs()) {
					this.props.showLoader(true);
					const { refNum: Refnum, expDate: Exp, originalMerchantOnly: OriginalMerchantOnly, token: Token } = this.state;
					const { ccToken: SUTData } = await this.props.makePendingRequest(this.getIfieldTokens());
					const data = {
						SUTData,
						Refnum,
						Exp,
						OriginalMerchantOnly,
						Token,
					};
					const { xRefnum, xResultData, xResult } = await this.props.makePendingRequest(
						cardholderUpdaterService.updateCard(data)
					);
					if (toLower(xResult) === 's') {
						this.notificationRef.current.addNotification({
							message: xResultData,
							ref: xRefnum,
							success: true,
						});
					}
					this.props.showLoader(false);
				}
			} catch (e) {
				this.props.showLoader(false);
				this.props.handleError(e);
			}
		});
	};

	handleExpDateChange = ({ value }) => {
		this.setState({ expDate: value }, this.validateInputs);
	};

	handleRadioChange = ({ target: { name, checked } }) => {
		const newState = {
			[name]: checked,
			originalMerchantOnly: name === 'useForThisAccount',
		};

		if (name === 'useForThisAccount') {
			newState.useForAllAccounts = false;
		} else {
			newState.useForThisAccount = false;
		}

		this.setState(newState);
	};

	togglePrivacyPolicy = () => {
		const { privacyPolicyVisible } = this.state;
		this.setState({
			privacyPolicyVisible: !privacyPolicyVisible,
		});
	};

	render = () => {
		const {
			amount,
			lastFour,
			merchantName,
			expDate,
			useForAllAccounts,
			useForThisAccount,
			cardNumberIsValid,
			errors,
		} = this.state;
		const required = (
			<span className="form__group__required" data-tooltip="Required">
				*
			</span>
		);

		setIfieldStyle('card-number', cardNumberIsValid ? inputStyle : invalidStyle);

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				<div className="auth__form">
					<h2 className="spc--bottom--lrg">Card information</h2>
					<p className="type--p2 spc--bottom--xxxlrg">
						Your card ending in <span className="type--p2--medium">{lastFour}</span> used at merchant {merchantName} for
						the amount of {CurrencyMap.resolveCurrency()}
						{amount} has been declined.
					</p>
					{!isEmpty(errors) && <FormErrors className="spc--bottom--lrg" errors={errors} validationList />}
					<label data-ifields-id="card-data-error" />

					<div className="form__group">
						<div className="form__group__header">
							<label className="form__group__label" htmlFor="cardNumber">
								Card Number
							</label>
							<div className="form__group__required">{required}</div>
						</div>
						<iframe
							ref={this.cardNumRef}
							data-ifields-id="card-number"
							className="input--iframe"
							data-ifields-placeholder="XXXX XXXX XXXX XXXX"
							src={ifieldsSource}
						/>
						<input
							type="hidden"
							data-ifields-id="card-number-token"
							name="xCardNum"
							ref={this.hidCardNumRef}
							required
						/>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<label className="form__group__label" htmlFor="expDate">
								Expiration Date
							</label>
							<div className="form__group__required">{required}</div>
						</div>
						<NumberFormat
							name="expDate"
							id="expDate"
							type="text"
							className="input input--med input--date"
							format="##/##"
							placeholder="MM/YY"
							value={expDate}
							onValueChange={this.handleExpDateChange}
						/>
					</div>
					<div className="spc--bottom--med">
						<input
							type="radio"
							name="useForThisAccount"
							id="useForThisAccount"
							checked={useForThisAccount}
							className="input--radio"
							onChange={this.handleRadioChange}
						/>
						<label htmlFor="useForThisAccount">Use updated card for {merchantName} only</label>
					</div>
					<div className="spc--bottom--lrg">
						<input
							type="radio"
							name="useForAllAccounts"
							id="useForAllAccounts"
							checked={useForAllAccounts}
							className="input--radio"
							onChange={this.handleRadioChange}
						/>
						<label htmlFor="useForAllAccounts">Use for all Cardknox accounts</label>
					</div>
					<div className="notes notes--error spc--bottom--huge">
						<div className="icon"></div>
						<div className="type--p4">
							Please note that in some cases your original card may be retried before your new card is attempted.
						</div>
					</div>
					<button className="btn btn--lrg btn--primary fullwidth" onClick={this.updateCard}>
						Update Card Information
					</button>
				</div>

				<Modal
					className="modal__content modal__content--privacy modal--med"
					isOpen={this.state.privacyPolicyVisible}
					onClose={this.togglePrivacyPolicy}
				>
					<PrivacyPolicy />
					<div className="modal__footer"></div>
				</Modal>
				<div className="auth__main__privacy">
					© Copyright 2024 Cardknox -{' '}
					<button
						onClick={e => {
							e.preventDefault();
							this.togglePrivacyPolicy();
						}}
						className="btn btn--link btn--link--underline"
					>
						Privacy Policy
					</button>
				</div>
			</Fragment>
		);
	};
}

CardholderUpdater.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	location: PropTypes.object,
	history: PropTypes.object,
};

export default withError(withLoader(withCancelable(CardholderUpdater)));
