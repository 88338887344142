import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectAllContext } from 'common/contexts';

class SelectAllHeaderComponent extends Component {
	static contextType = SelectAllContext;
	render() {
		const {
			column: { alignHeaderRight },
		} = this.props;

		return (
			<div className={`react-grid-HeaderCell-content${alignHeaderRight ? ' type--right' : ''}`}>
				<input
					className="input--check input--check--no-label"
					id="selectAllRows"
					type="checkbox"
					checked={this.context.checked}
					onChange={this.context.toggle}
				/>
				<label htmlFor="selectAllRows"></label>
			</div>
		);
	}
}

SelectAllHeaderComponent.propTypes = {
	column: PropTypes.object,
};

export default SelectAllHeaderComponent;
