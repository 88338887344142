import React from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

function TapToPhone(props) {
	return (
		<div className="">
			<p className="spc--bottom--sml">
				No terminal? No problem. Accept in-person payments with just a supported Android device.
			</p>
			<div className="spc--bottom--sml--alt">
				<button className="btn btn--sml btn--primary" onClick={() => props.history.push('features/TapToPhone')}>
					Get started now!
				</button>
				<button
					className="btn btn--sml btn--secondary spc--left--tny"
					onClick={() =>
						window.open(
							'https://www.cardknox.com/tap-to-phone/?utm_source=portal&utm_medium=banner&utm_campaign=cs',
							'_blank',
							'noopener'
						)
					}
				>
					Learn More
				</button>
			</div>
		</div>
	);
}
TapToPhone.propTypes = {
	history: object.isRequired,
};
export default withRouter(TapToPhone);
