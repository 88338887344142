import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class DatePickerSelectionComponent extends Component {
	filterRemoved = () => {
		this.props.onFilterRemoved({
			id: 'date',
		});
	};
	showMessage = (isExporting, maxApiDaysRange, showRangeMessage) => {
		if (isExporting) {
			return 'Please remain in the Transactions module until your download is complete.';
		}

		if (showRangeMessage) {
			return `Expect slower response time when selecting a date range which is greater than ${maxApiDaysRange} days.`;
		}
	};

	render() {
		const {
			filter: {
				values: { startDate, endDate, key },
				defaultValues: { key: defaultKey },
				props: { maxApiDaysRange },
			},
			isExporting,
			showRangeMessage,
		} = this.props;
		const start = startDate.format(ApplicationSettings.displayShortDateTimeFormat);
		const end = endDate.format(ApplicationSettings.displayShortDateTimeFormat);
		const isDefault = key === defaultKey;
		return (
			<Fragment>
				{(isExporting || showRangeMessage) && (
					<div className="notes notes--primary">{this.showMessage(isExporting, maxApiDaysRange, showRangeMessage)}</div>
				)}
				<button className="filter__toolbar__selected-filter">
					Date Range:
					<span className="type--wgt--medium">
						{start} - {end}
					</span>
					{!isDefault && (
						<button data-tooltip="Reset" onClick={this.filterRemoved} className="btn btn--link datatooltip--auto">
							<i className="icon icon--tny icon--reset"></i>
						</button>
					)}
				</button>
			</Fragment>
		);
	}
}

DatePickerSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
	isExporting: PropTypes.oneOf([true, false, null]),
	showRangeMessage: PropTypes.any,
};

export default DatePickerSelectionComponent;
