import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { CardTypeImagePath } from 'common/utilities';

const PaymentsPreview = ({ payments, setDefaultPaymentMethod }) => {
	const [sections, setSections] = useState({
		creditCards: true,
		achCards: true,
	});

	const toggleSection = section => {
		setSections(prevSections => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	const renderCardInfo = payment => (
		<div key={payment.paymentMethodId} className="grid-sidebar__field grid-sidebar__field--big">
			<div className="flex--primary flex--gap--sml--alt flex--nowrap">
				<img src={CardTypeImagePath.getPath(payment.cardType, 'big/')} alt={`${payment.cardType} logo`} />
				<div>
					<label className="display--b type--p1 type--p1--medium type--color--text spc--bottom--tny">{`****${payment.maskedNumber.slice(
						-4
					)}`}</label>
					<label className="display--b type--p2 type--color--text--light">Expiring {payment.exp}</label>
				</div>
			</div>
			<div className={payment.isDefaultPaymentMethod ? '' : 'spc--right--sml'}>
				<input
					type="radio"
					data-payment-method-id={payment.paymentMethodId}
					id={`payment-method-${payment.paymentMethodId}-isDefaultPaymentMethod`}
					onChange={setDefaultPaymentMethod}
					checked={payment.isDefaultPaymentMethod}
					className={`input--radio${payment.isDefaultPaymentMethod ? ' input--radio--boxed' : ''}`}
				/>
				<label htmlFor={`payment-method-${payment.paymentMethodId}-isDefaultPaymentMethod`}>
					{payment.isDefaultPaymentMethod && 'Default'}
				</label>
			</div>
		</div>
	);

	const creditCards = payments.filter(payment => payment.tokenType.toLowerCase() === 'cc');
	const achCards = payments.filter(payment => payment.tokenType.toLowerCase() === 'check');

	return (
		<Fragment>
			<div className="info-panel__heading__wrapper is-expandable" onClick={() => toggleSection('creditCards')}>
				<h6 className="info-panel__heading">Credit Cards</h6>
				<i className={`icon icon--sml icon--chevron--${sections.creditCards ? 'top' : 'down'}--primary`}></i>
			</div>
			{sections.creditCards && (
				<div className="info-panel__section">
					{creditCards.length > 0 ? (
						creditCards.map(renderCardInfo)
					) : (
						<p className="type--p2 type--color--text--light type--center spc--top--sml">No credit cards available.</p>
					)}
				</div>
			)}
			<div className="info-panel__heading__wrapper is-expandable" onClick={() => toggleSection('achCards')}>
				<h6 className="info-panel__heading">ACH/Check Cards</h6>
				<i className={`icon icon--sml icon--chevron--${sections.achCards ? 'top' : 'down'}--primary`}></i>
			</div>
			{sections.achCards && (
				<div className="info-panel__section">
					{achCards.length > 0 ? (
						achCards.map(renderCardInfo)
					) : (
						<p className="type--p2 type--color--text--light type--center spc--top--sml">
							No ACH/check cards available.
						</p>
					)}
				</div>
			)}
		</Fragment>
	);
};

PaymentsPreview.propTypes = {
	payments: PropTypes.arrayOf(
		PropTypes.shape({
			cardType: PropTypes.string,
			maskedNumber: PropTypes.string,
			exp: PropTypes.string,
			isDefaultPaymentMethod: PropTypes.bool,
			tokenType: PropTypes.string,
		})
	).isRequired,
	setDefaultPaymentMethod: PropTypes.func.isRequired,
};

export default PaymentsPreview;
