import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { noop } from 'lodash';

class NumberFilterComponent extends Component {
	get value() {
		const {
			filter: { key, values },
		} = this.props;
		return values[key] === null ? '' : values[key];
	}

	componentDidUpdate() {
		const { noFocus } = this.props;
		if (this.inputRef && !noFocus) {
			this.inputRef.focus();
		}
	}

	handleChange = values => {
		const { formattedValue } = values;
		const {
			onFilterChanged,
			filter: { key },
		} = this.props;

		onFilterChanged({
			id: key,
			values: [{ key, value: formattedValue === '' ? null : formattedValue }],
			emptyValue: null,
		});
	};

	render() {
		const {
			filter: { key, name, values },
			noFocus,
			onBlur,
			goButtonHandler,
			inputMode,
			thousandSeparator,
			decimalSeparator,
			className,
		} = this.props;
		const userInputMode = inputMode || 'numeric';
		return (
			<Fragment>
				<NumberFormat
					decimalSeparator={decimalSeparator}
					thousandSeparator={thousandSeparator}
					value={values[key] === null ? '' : values[key]}
					placeholder={name}
					id={key}
					onValueChange={this.handleChange}
					onBlur={onBlur ? onBlur : noop}
					autoFocus={!noFocus}
					getInputRef={el => (this.inputRef = el)}
					inputMode={userInputMode}
					className={`${className ? className : 'input input--med'}`}
				/>
				{goButtonHandler && (
					<button className="btn btn--med btn--primary" onClick={goButtonHandler}>
						Submit
					</button>
				)}
			</Fragment>
		);
	}
}

NumberFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	noFocus: PropTypes.bool,
	onBlur: PropTypes.func,
	goButtonHandler: PropTypes.func,
	inputMode: PropTypes.any,
	thousandSeparator: PropTypes.any,
	decimalSeparator: PropTypes.any,
	className: PropTypes.string,
};

export default NumberFilterComponent;
