import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, find, trim, toLower, get, split } from 'lodash';

import { Modal } from 'common/components/modal/index';
import { withError } from 'common/components/error';
import { FormErrors } from 'common/utilities';
import IpInput from 'common/components/ip-input/ip-input';
import { ReactMultiEmail, isEmail } from 'common/components/react-multi-email';

const permissionkeys = {
	admin: 'admin',
	advanced: 'advanced',
	standard: 'standard',
	viewOnly: 'viewOnly',
	saveOnly: 'saveOnly',
	authonly: 'authonly',
	saleOnly: 'saleonly',
};
const keyTypes = {
	paymentsite: 'paymentsite',
	api: 'standard',
	ifields: 'ifields',
};

const descriptionKeys = {
	software: 'software',
	store: 'store',
	website: 'website',
	other: 'other',
};

const unhide = {
	display: 'flex',
};
const hide = {
	display: 'none',
};

class AddEditKey extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...this.defaults,
			emails: [],
			showEmailInput: false,
		};
		this.errorsRef = null;
	}

	get permissions() {
		return [
			{
				label: 'Admin Role',
				key: permissionkeys.admin,
				description: 'Has permission to run all commands and pull reports.',
			},
			{
				label: 'Standard Role',
				key: permissionkeys.standard,
				description: (
					<Fragment>
						<p className="spc--bottom--tny">
							<span className="type--italic">Has permission to: </span>
							create and save new transactions and gift transactions; run reports for transactions, batches, and gifts;
							create new customers and recurring schedules.
						</p>
						<p>
							<span className="type--italic">Doesn’t have permission to: </span>issue refunds/credit, void transactions,
							or manage users.
						</p>
					</Fragment>
				),
			},
			{
				label: 'View Only Role',
				key: permissionkeys.viewOnly,
				description: (
					<Fragment>
						<p className="spc--bottom--tny">Only has permission to pull reports.</p>

						<p>
							For more details on the API calls associated with these permissions, see the{' '}
							<a href="https://kb.cardknox.com/api/" className="anchor anchor--primary">
								Cardknox API documentation
							</a>
							.
						</p>
					</Fragment>
				),
			},
			{
				label: 'Save Only Role',
				key: permissionkeys.saveOnly,
				description: 'Can only use the save command.',
			},
			{
				label: 'Auth only Role',
				key: permissionkeys.authonly,
				description: 'Can only run auth commands.',
			},
			{
				label: 'Sale only Role',
				key: permissionkeys.saleOnly,
				description: 'Can only run sales.',
			},
		];
	}
	get keyTypes() {
		const types = [
			{ key: keyTypes.api, label: 'API (Transaction Key)' },
			{ key: keyTypes.ifields, label: 'iFields (Token Key)' },
		];
		if (this.props.action === 'edit') {
			types.push({ key: keyTypes.paymentsite, label: 'Payment Site' });
		}
		return types;
	}
	get descriptions() {
		return [
			{ key: '', label: 'Please select' },
			{ key: descriptionKeys.software, label: 'Software' },
			{ key: descriptionKeys.store, label: 'Store' },
			{ key: descriptionKeys.website, label: 'Website' },
			{ key: descriptionKeys.other, label: 'Other' },
		];
	}

	get defaultSelectedDescription() {
		return '';
	}

	get defaultSelectedKeyType() {
		return this.keyTypes[0].key;
	}

	get defaultPermission() {
		return this.permissions[0].key;
	}
	get isPaymentSiteKey() {
		return get(this.props, 'data.keyType', '') === 'paymentsite';
	}
	get defaults() {
		return {
			selectedKeyType: this.defaultSelectedKeyType,
			selectedDescription: this.defaultSelectedDescription,
			description: this.defaultSelectedDescription,
			selectedPermission: this.defaultPermission,
			errors: {},
			keyInfo: {},
			whiteListedIPs: {
				allIPs: '',
				areAllIPsValid: true,
			},
			isModalOpen: false,
			isAdvanceSectionOpen: false,
		};
	}
	get isInvalidEmails() {
		const { emails, showEmailInput } = this.state;
		return showEmailInput && isEmpty(emails);
	}
	handleCloseModal = () => {
		const newState = this.defaults;
		newState.isModalOpen = false;
		this.setState(newState);
	};

	handleOpenModal = async () => {
		const { data, action } = this.props;
		const { whiteListedIPs } = this.state;
		const newState = {};
		if ((action === 'edit' || action === 'duplicate') && data && data.key) {
			newState.keyInfo = await this.props.fetchKey(data.key);
			if (newState.keyInfo) {
				const keyType = find(this.keyTypes, keyType => keyType.key === newState.keyInfo.keyType);
				const description = find(this.descriptions, description => description.key === newState.keyInfo.keyDescription);
				const role = find(this.permissions, permission => toLower(permission.key) === toLower(newState.keyInfo.role));
				const emailReceipts = get(newState.keyInfo.data, 'Email_Receipts', 'false');
				const emails = get(newState.keyInfo.data, 'Email_ReceiptsOverrideTo', '');
				if (emails) {
					newState.emails = split(emails, ',');
				}
				newState.showEmailInput = toLower(emailReceipts) === 'true';
				newState.selectedKeyType = keyType ? keyType.key : this.defaultSelectedKeyType;
				newState.whiteListedIPs = {
					...whiteListedIPs,
					allIPs: newState.keyInfo.whiteListServerIPs.replace(/,/g, '\n'),
				};

				if (newState.selectedKeyType === keyTypes.ifields) {
					newState.selectedDescription = this.defaultSelectedDescription;
					newState.description = newState.keyInfo.keyDescription;
					newState.selectedPermission = role ? role.key : permissionkeys.saveOnly;
				} else {
					newState.selectedDescription = description ? description.key : descriptionKeys.other;
					newState.description = description ? description.key : newState.keyInfo.keyDescription;
					newState.selectedPermission = role ? role.key : this.defaultPermission;
				}
				newState.isModalOpen = true;
			}
		} else {
			newState.isModalOpen = true;
		}

		this.setState(newState);
	};

	handleChange = ({ target: { name, value } }) => {
		const newState = { [name]: value };
		this.setState(newState);
	};

	onIPChange = event => {
		const { whiteListedIPs } = this.state;
		const {
			eventArgs: {
				target: { value },
			},
		} = event;
		whiteListedIPs.allIPs = value.replace(/ +?/g, '');
		this.setState({ whiteListedIPs });
	};

	onIPBlur = event => {
		const { areAllIPsValid } = event;
		const { whiteListedIPs } = this.state;
		whiteListedIPs.areAllIPsValid = areAllIPsValid;
		this.setState({ whiteListedIPs });
	};

	handleDescriptionChange = ({ target: { name, value } }) => {
		const newState = { [name]: value, description: value };
		if (value === 'other') newState.description = '';
		this.setState(newState);
	};
	getLabel = (email, index, removeEmail) => {
		return (
			<div data-tag key={index}>
				<span>{email}</span>
				<span data-tag-handle onClick={() => removeEmail(index)} onKeyDown={() => null}></span>
			</div>
		);
	};

	setEmail = ({ emails }) => {
		if (emails) {
			this.setState({ emails });
		}
	};
	handleShowEmailChange = () => {
		this.setState(prevState => ({
			showEmailInput: !prevState.showEmailInput,
		}));
	};

	handleKeyTypeChange = ({ target: { name, value } }) => {
		const newState = { [name]: value };
		if (value === keyTypes.ifields) {
			newState.description = '';
			newState.selectedPermission = permissionkeys.saveOnly;
		} else {
			newState.description = this.defaultSelectedDescription;
			newState.selectedDescription = this.defaultSelectedDescription;
			newState.selectedPermission = this.defaultPermission;
		}
		this.setState(newState);
	};

	createNewKey = () => {
		if (!this.checkFormValidity()) {
			return;
		}
		const {
			selectedKeyType,
			description,
			selectedPermission,
			whiteListedIPs: { allIPs },
		} = this.state;
		const data = {
			role: selectedPermission,
			keytype: selectedKeyType,
			keyDescription: trim(description),
			whiteListedIPs: allIPs.replace(/\n/g, ','),
		};

		this.props.createNewKey(data, this.handleCloseModal, this.errorHandler);
	};

	saveChanges = () => {
		if (!this.checkFormValidity()) {
			return;
		}
		const {
			keyInfo: { ddbRevision, index1 },
			whiteListedIPs: { allIPs },
		} = this.state;

		const { selectedKeyType, description, selectedPermission } = this.state;
		const data = {
			key2: this.props.row.key,
			role: selectedPermission,
			ddbrevision: ddbRevision,
			index1,
			keytype: selectedKeyType,
			keyDescription: trim(description),
			whiteListedIPs: allIPs.replace(/\n/g, ','),
		};
		if (this.isPaymentSiteKey) {
			data['Email_ReceiptsOverrideTo'] = this.state.emails.join(',');
			data['Email_Receipts'] = this.state.showEmailInput;
		}

		this.props.saveChanges(data, this.handleCloseModal, this.errorHandler);
	};

	checkFormValidity = () => {
		const errors = {};
		const {
			description,
			selectedDescription,
			whiteListedIPs: { areAllIPsValid },
			selectedKeyType,
		} = this.state;
		if (isEmpty(trim(description))) {
			errors.description =
				selectedKeyType === keyTypes.api && selectedDescription === descriptionKeys.other
					? 'Custom Description is required'
					: 'Description is required.';
		}
		if (!areAllIPsValid) {
			errors.whiteListedIPs = 'All IPs must be a valid IPv4 or IPv6 address.';
		}
		this.setState({ errors });
		const anyErrors = !isEmpty(errors);
		if (anyErrors) this.focusErrors();
		return !anyErrors;
	};

	errorHandler = error => {
		const errors = {};
		errors[error.key] = error.message;
		this.setState({ errors });
		this.focusErrors();
	};

	focusErrors = () => {
		if (this.errorsRef) this.errorsRef.scrollIntoView({ block: 'end', behavior: 'smooth' });
	};

	setErrorsRef = element => {
		this.errorsRef = element;
	};

	toggleAdvancedRoles = () => {
		this.setState({
			isAdvanceSectionOpened: !this.state.isAdvanceSectionOpened,
		});
	};
	renderSettings = () => {
		const { emails, showEmailInput } = this.state;

		return (
			<Fragment>
				<div>
					<div className="spc--bottom--sml--alt">
						<input
							className="input--check"
							type="checkbox"
							checked={showEmailInput}
							onChange={this.handleShowEmailChange}
							name="emailReceipt"
							id="emailReceipt"
						/>

						<label htmlFor="emailReceipt" className="type--color--text--medium type--wgt--medium">
							Enable Email Receipts
						</label>
					</div>
					{showEmailInput && (
						<div>
							<p className="type--xsml type--color--text--light spc--bottom--xsml">
								Multiple emails should be separated by commas
							</p>
							<ReactMultiEmail
								placeholder="Email"
								emails={emails}
								onChange={this.setEmail}
								validateEmail={isEmail}
								getLabel={this.getLabel}
							/>
						</div>
					)}
				</div>
				<div className="separator separator--grey1 spc--bottom--med"></div>
			</Fragment>
		);
	};

	renderPermissions = () => {
		const { selectedPermission, selectedKeyType, isAdvanceSectionOpened } = this.state;
		const advanceSectionFrom = 3;

		const permissions = this.permissions.splice(0, advanceSectionFrom - 1);
		const advancedSection = this.permissions.splice(advanceSectionFrom - 1, this.permissions.length);

		return (
			<div className="flex--primary flex--column flex--gap--xlrg flex--top spc--bottom--xlrg">
				{map(permissions, permission => {
					return (
						<div key={permission.key}>
							<div className="spc--bottom--sml">
								<input
									type="radio"
									className="input--radio"
									id={permission.key}
									name="selectedPermission"
									value={permission.key}
									checked={permission.key === selectedPermission}
									onChange={this.handleChange}
									disabled={selectedKeyType === keyTypes.ifields && permission.key !== permissionkeys.saveOnly}
								/>
								<label htmlFor={permission.key}>{permission.label}</label>
							</div>
							<p className="type--p4 type--color--text--light spc--left--lrg" htmlFor={permission.key}>
								{permission.description}
							</p>
						</div>
					);
				})}

				<a className="btn btn--link" onClick={this.toggleAdvancedRoles}>
					<i
						className={`icon icon--sml icon--${isAdvanceSectionOpened ? 'minus' : 'add'}--primary spc--right--sml`}
					></i>
					Additional roles
				</a>

				<div
					className="flex--primary flex--column flex--gap--xlrg flex--top"
					style={isAdvanceSectionOpened ? unhide : hide}
				>
					{map(advancedSection, permission => {
						return (
							<div key={permission.key}>
								<div className="spc--bottom--sml">
									<input
										type="radio"
										className="input--radio"
										id={permission.key}
										name="selectedPermission"
										value={permission.key}
										checked={permission.key === selectedPermission}
										onChange={this.handleChange}
										disabled={selectedKeyType === keyTypes.ifields && permission.key !== permissionkeys.saveOnly}
									/>
									<label htmlFor={permission.key}>{permission.label}</label>
								</div>
								<p className="type--p4 type--color--text--light spc--left--lrg" htmlFor={permission.key}>
									{permission.description}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	renderAllowedIPs = () => {
		return (
			<div className="spc--bottom--sml">
				<div className="flex--primary spc--bottom--med">
					<h6>Whitelist IPs</h6>
					<i
						className="icon icon--tny icon--regular--info spc--left--tny"
						data-tooltip="Add the IP addresses allowed to use this API key. If you leave this field blank, all IP addresses can use the key. For multiple IP addresses, type each IP address on a separate line."
					></i>
				</div>
				<IpInput
					id="whiteListedIPs"
					name="whiteListedIPs"
					value={this.state.whiteListedIPs.allIPs}
					onChange={this.onIPChange}
					onBlur={this.onIPBlur}
				></IpInput>
			</div>
		);
	};

	renderKeyType = () => {
		const { selectedKeyType } = this.state;
		const keyType = find(this.keyTypes, keyType => keyType.key === selectedKeyType);
		return (
			<div className="input input--med input--disabled">
				<span>{keyType.label}</span>
			</div>
		);
	};

	render() {
		const { children, className, shouldHideModal, isLoading, action } = this.props;
		const { isModalOpen, selectedKeyType, selectedDescription, description, errors } = this.state;
		const title = action === 'edit' ? 'Edit a Key' : 'Create a Key';

		const descriptionInput = (
			<input
				className="input input--med"
				type="text"
				name="description"
				value={description}
				onChange={this.handleChange}
				placeholder="Write Your Description"
			/>
		);
		return (
			<React.Fragment>
				<button onClick={this.handleOpenModal} type="button" className={className}>
					{children}
				</button>
				<Modal
					isOpen={isModalOpen}
					onClose={this.handleCloseModal}
					overlayClassName={shouldHideModal() ? 'modal__hide' : 'modal__overlay'}
				>
					<div className="modal__header">
						<div className="modal__header__title">{title}</div>
					</div>

					<form className="modal__body">
						<div className="spc--bottom--med" ref={this.setErrorsRef}>
							{!isEmpty(errors) && <FormErrors errors={errors} />}
						</div>

						<div className="form__group">
							<div className="form__group__header">
								<span className="form__group__label">Type</span>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
								{selectedKeyType === keyTypes.ifields && (
									<i
										className="icon icon--tny icon--regular--info"
										data-tooltip="iField key allows save only permission."
									></i>
								)}
							</div>
							{action === 'edit' ? (
								this.renderKeyType()
							) : (
								<select
									className="input input--med input--select"
									name="selectedKeyType"
									onChange={this.handleKeyTypeChange}
									value={selectedKeyType}
								>
									{map(this.keyTypes, keyType => (
										<option key={keyType.key} value={keyType.key}>
											{keyType.label}
										</option>
									))}
								</select>
							)}
						</div>

						<div className="spc--bottom--sml--alt">
							<div className="form__group__header">
								<span className="form__group__label">Description</span>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							{selectedKeyType === keyTypes.ifields ? (
								descriptionInput
							) : (
								<Fragment>
									<select
										className="input input--med input--select spc--bottom--lrg"
										name="selectedDescription"
										onChange={this.handleDescriptionChange}
										value={selectedDescription}
									>
										{map(this.descriptions, description => (
											<option key={description.key} value={description.key}>
												{description.label}
											</option>
										))}
									</select>
									{selectedDescription === 'other' && (
										<div className="form__group">
											<div className="form__group__header">
												<span className="form__group__label">Custom Description</span>
												<span className="form__group__required" data-tooltip="Required">
													*
												</span>
											</div>
											{descriptionInput}
										</div>
									)}
								</Fragment>
							)}
						</div>

						<h6 className="spc--bottom--lrg">Key Permissions</h6>
						{this.renderPermissions()}

						{action === 'edit' && this.isPaymentSiteKey && (
							<Fragment>
								<div className="separator separator--grey1 spc--bottom--med"></div>
								<h5 className="type--base type--wgt--medium spc--bottom--sml--alt">Key Settings</h5>
								{this.renderSettings()}
							</Fragment>
						)}
						{this.renderAllowedIPs()}
					</form>
					<div className="modal__footer">
						<button
							type="button"
							tabIndex="-1"
							className="btn btn--med btn--primary"
							disabled={isLoading || this.isInvalidEmails}
							onClick={action === 'edit' ? this.saveChanges : this.createNewKey}
						>
							{action === 'edit' ? 'Save Changes' : 'Create and View'}
						</button>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}

AddEditKey.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	action: PropTypes.oneOf(['add', 'edit', 'duplicate']).isRequired,
	shouldHideModal: PropTypes.func,
	createNewKey: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
	data: PropTypes.object,
	fetchKey: PropTypes.func,
	saveChanges: PropTypes.func,
	row: PropTypes.object,
	key: PropTypes.string,
};

export default withError(AddEditKey);
