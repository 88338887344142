import React from 'react';
import PropTypes from 'prop-types';
import { SidebarComponent } from 'components/sidebar';
import { get, map, split, startsWith } from 'lodash';
import { withRouter } from 'react-router-dom';
import { withCancelable } from '../cancelable';
import { OutsideClick, hasFeaturePackage, invokeIfFunction } from 'common/utilities';
import { SidebarContext } from 'common/contexts';
import routes from 'routing/routes';
import TerminalOnlyUpgradePlan from 'components/upgrade-plan/TerminalOnlyUpgradePlan';
import { featurePackages } from 'common/utilities/has-feature-package';

const VIRTUAL_PATHS = ['transactions', 'account-settings/new-transaction', 'upgrade-plan'];
const BILLING_PATHS = ['customers', 'recurring-schedules'];

export function withSidebar(WrappedComponent) {
	WrappedComponent = withRouter(withCancelable(WrappedComponent));
	class WithSidebar extends React.Component {
		constructor(props) {
			super(props);
			this.routesWithoutSidebar = map(routes, route => {
				if (route.layout || invokeIfFunction(route.isPublic)) {
					return route.path.slice(1);
				}
			});
			this.sidebarRef = React.createRef();
			this.state = {
				sidebarVisible: false,
				isExpanded: true,
				sidebarExpandedClass: '',
			};
			this.contextValue = {
				sidebarRef: this.sidebarRef,
				toggleSidebar: this.toggleSidebar,
				openSidebar: this.openSidebar,
				closeSidebar: this.closeSidebar,
				toggleExpand: this.toggleExpand,
				toggleSidebarExpandedClass: this.toggleSidebarExpandedClass,
				enableDisableNewTransactionSidebar: this.enableDisableNewTransactionSidebar,
				enableDisableNewCustomerSidebar: this.enableDisableNewCustomerSidebar,
				updateSidebarLogo: this.updateSidebarLogo,
				showDisputesTab: this.showDisputesTab,
			};
		}
		componentDidUpdate(prevProps) {
			if (this.props.location.pathname !== prevProps.location.pathname) {
				this.handleResize();
			}
		}

		componentDidMount() {
			window.addEventListener('resize', this.handleResize);
			this.handleResize();
		}
		get currentPath() {
			const {
				location: { pathname },
			} = this.props;
			return pathname.slice(1);
		}

		get isVirtualOnly() {
			return hasFeaturePackage(featurePackages.terminalOnly);
		}

		get isCustomerBilling() {
			return BILLING_PATHS.some(x => startsWith(this.currentPath, x));
		}
		componentWillUnmount() {
			window.removeEventListener('resize', this.handleResize);
		}

		enableDisableNewCustomerSidebar = enable => {
			this.sidebarRef.current.setState({ hasIfields: enable });
		};
		enableDisableNewTransactionSidebar = enable => {
			this.sidebarRef.current.setState({ hasSelectedRows: enable });
		};

		handleResize = () => {
			const sidebarVisible = window.innerWidth > 1200;
			if (this.state.sidebarVisible === sidebarVisible) return;
			this.setState({ sidebarVisible });
		};

		toggleSidebar = () => {
			this.setState(prevState => ({
				sidebarVisible: !prevState.sidebarVisible,
				isExpanded: window.innerWidth > 1200 ? true : !prevState.isExpanded,
			}));
		};
		openSidebar = () => {
			if (this.state.sidebarVisible) return;
			this.setState({ sidebarVisible: true });
		};

		closeSidebar = () => {
			if (!this.state.sidebarVisible) return;
			this.setState({ sidebarVisible: false });
		};

		showDisputesTab = showDisputesTab => {
			this.sidebarRef.current.setState({ showDisputesTab });
		};

		shouldShowTerminalOnly() {
			const isVirtual = VIRTUAL_PATHS.includes(this.currentPath);
			const isCustomerBilling = this.isCustomerBilling;
			const hasCustomerBillingFeature = hasFeaturePackage(featurePackages.customerBilling);

			return (this.isVirtualOnly && !isVirtual) || (isCustomerBilling && !hasCustomerBillingFeature);
		}
		toggleSidebarExpandedClass = isExpanded => {
			const sidebarExpandedClass = isExpanded ? ' sidebar-is-expanded' : '';
			if (sidebarExpandedClass === this.sidebarExpandedClass) return;
			this.setState({ sidebarExpandedClass });
		};
		toggleExpand = expand => {
			const newExpandedState = expand !== undefined && expand !== null ? expand : !this.state.isExpanded;
			this.setState({ isExpanded: newExpandedState });
		};
		scrollHelpIntoView = () => {
			const elem = document.getElementsByClassName('nav sidebar__nav__wrapper')[0];
			const lastItem = document.querySelector('#lastElementInHelp');
			if (elem && lastItem && !this.state.isExpanded) {
				const itemHeight = lastItem.offsetHeight - 12;
				const rect = lastItem.getBoundingClientRect();
				const previousScroll = parseInt(split(split(elem.style.transform, 'translateY(')[1], 'px')[0]);
				const newScroll = Math.ceil((rect.bottom - window.innerHeight + 72) / itemHeight) * itemHeight;
				if (previousScroll) {
					elem.style.transform = `translateY(${previousScroll - newScroll}px)`;
				}
			}
		};
		updateSidebarLogo = logoData => {
			const { logoUrl, coBrandPortal } = logoData;
			this.sidebarRef.current.logoUrl = logoUrl;
			this.sidebarRef.current.setState({ logoUrl, coBrandPortal });
		};
		render() {
			const { sidebarVisible, isExpanded } = this.state;
			const sidebarCssClass = sidebarVisible ? ' is-revealed' : '';
			const collapsedClass = isExpanded ? '' : ' is-collapsed';
			const isMfaRequired = get(this.props.history, 'location.state.isMfaRequired');

			const isWithoutSidebar = this.routesWithoutSidebar.includes(this.currentPath);
			if (isWithoutSidebar) {
				return <WrappedComponent {...this.props} />;
			}
			return (
				<SidebarContext.Provider value={this.contextValue}>
					{isMfaRequired ? null : (
						<OutsideClick action={this.closeSidebar}>
							<button
								type="button"
								className={`btn btn--link btn--sidebar-toggle ${isExpanded ? '' : 'is-collapsed'}`}
								onClick={() => this.toggleExpand()}
							>
								<i
									className={`icon ${isExpanded ? 'menu-collapse' : 'menu-expand'}`}
									data-tooltip={isExpanded ? 'Collapse Menu' : 'Expand Menu'}
								/>
							</button>
							<div className={`l--aside${collapsedClass}${sidebarCssClass}`}>
								<SidebarComponent
									ref={this.sidebarRef}
									scrollHelpIntoView={this.scrollHelpIntoView}
									isExpanded={isExpanded}
									openSidebar={this.openSidebar}
								/>
							</div>
							<div className={`l--main${collapsedClass}${this.state.sidebarExpandedClass}`}>
								<div className="l--content__wrapper">
									{this.shouldShowTerminalOnly() ? (
										<TerminalOnlyUpgradePlan isCustomerBilling={this.isCustomerBilling} />
									) : (
										<WrappedComponent {...this.props} />
									)}
								</div>
							</div>
						</OutsideClick>
					)}
				</SidebarContext.Provider>
			);
		}
	}
	WithSidebar.propTypes = {
		history: PropTypes.object,
		location: PropTypes.object,
	};

	return WithSidebar;
}
