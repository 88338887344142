import React, { Component } from 'react';
import ImageWithFallback from 'common/components/ImageFallback';
class GiftBalance extends Component {
	constructor(props) {
		super(props);
	}
	render = () => (
		<div className="auth">
			<div className="auth__sidebar">
				<ImageWithFallback imgAttributes={{ alt: 'logo' }}>
					{props => <img src={'static/media/sola-logo.svg'} className="auth__sidebar__logo" {...props} />}
				</ImageWithFallback>
			</div>
			<div className="auth__main">
				<div className="auth__form">
					<h2 className="spc--bottom--lrg">Gift card balance</h2>
					<p className="type--p2 type--color--text--secondary spc--bottom--xxlrg">
						To do this, type in the gift card number and PIN. Check the reCAPTCHA box, and click the Check Balance
						button.
					</p>
					<div className="gift-balance__iframe__wrapper">
						<iframe ref={this.cardNumRef} src="https://secure.cardknox.com/balance" className="gift-balance__iframe" />
					</div>
					<div className="gift-balance__power">
						<p>Power by SOLA | </p>
						<a
							href="https://www.cardknox.com/contact/"
							target="_blank"
							rel="noreferrer noopener"
							className="btn btn--link btn--link--light btn--link--underline spc--left--tny"
						>
							Terms Privacy
						</a>
					</div>
					<p className="type--p4 type--color--light">
						Cardknox offers so many hassle-free payment features for merchants - including gift card management - that
						you'll wonder how you ever did business without it. To enroll with Cardknox or learn more,{' '}
						<a
							href="https://www.cardknox.com/contact/"
							target="_blank"
							rel="noreferrer noopener"
							className="btn btn--link btn--link--light btn--link--underline spc--right--tny"
						>
							contact us
						</a>
						today.
					</p>
				</div>
			</div>
		</div>
	);
}

export default GiftBalance;
