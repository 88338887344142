import {
	clone,
	first,
	cloneDeep,
	map,
	orderBy,
	each,
	filter as filterMethod,
	includes,
	toLower,
	startsWith,
	some,
	concat,
	uniqBy,
	uniq,
	join,
	split,
	get,
	round,
} from 'lodash';
import moment from 'moment';

import httpService from './httpService';
import authenticationService from './authenticationService';
import principalService from './principalService';
import { apiToLocalMoment, maxApiDaysRange } from './../utilities';
import updateApiEndpoint from 'common/utilities/UpdateApiEndpoint';

const { apiDateTimeFormat, apiResponseDateTimeFormat } = ApplicationSettings;

function generateCustomFields(num) {
	let customFields = [];
	for (let i = 1; i <= num; ++i) {
		customFields.push('xCustom' + i.toString().padStart(2, '0'));
	}
	return customFields.join(',');
}

const customFields = generateCustomFields(19);
const transactionFields =
	'xCurrency,xRefNum,xAmount,xName,xExp,xEnteredDate,xResponseResult,xEntryMethod,xCardType,xMaskedCardNumber,xMaskedAccountNumber,xInvoice,xResponseBatch,xDescription,xCommand,xResponseCVVCode,xResponseAVSCode,xStreet,xZip,xOrderID,xResponseAuthCode,xTerminalNum,xPONum,xSourceKey,xSignature,xResponseRefNum,xUserName,xClientIp,xEmail,xSoftwareName,xTip,xIsDebit,xStatus,xProcessingFee,xDigitalWalletType,xSplitAmount,xCardSource,xServiceFee,xTax,xDisputeAmount,xDisputeReason,xInvoice,xAdditionalRefnum,xAdditionalAuthAmount,xIsInternational';
const billingFields =
	'xBillFirstName,xBillMiddleName,xBillLastName,xBillCompany,xBillStreet,xBillCity,xBillZip,xBillState,xBillCountry,xBillPhone';
const shippingFields =
	'xShipFirstName,xShipMiddleName,xShipLastName,xShipCompany,xShipStreet,xShipCity,xShipZip,xShipState,xShipCountry,xShipPhone';

const dashboardFields = 'xRefNum,xResponseResult,xAmount,xStatus,xEnteredDate,xCardType,xCommand';
const fraudFields = 'xStatus,xFraudResubmitted,xStatusReason,xOrderID,xEngine';

const statusFraudMap = {
	0: 'Pending',
	1: 'Guaranteed',
	2: 'Not Guaranteed - Probable Fraud',
	3: 'Not Guaranteed - High Risk',
	4: 'Not Guaranteed - Low Risk',
	5: 'Cancelled',
	6: 'Awaiting Response',
	8: 'Error',
	11: 'Safe',
	12: 'Review',
	13: 'Retrieval',
	14: 'Chargeback',
	15: 'Funded',
	16: 'Settled',
	17: 'Chargeback Reversal',
	18: 'Returned',
	19: 'Hold',
	20: 'Rejected',
	'': 'N/A',
};

class TransactionService {
	constructor(httpService, authenticationService, principalService) {
		this.httpService = httpService;
		this.principalService = principalService;
	}

	getTransactionFields = () => {
		return [transactionFields, billingFields, shippingFields, customFields].join(',');
	};

	getDashboardFields = () => {
		return dashboardFields;
	};

	getFraudFields = () => {
		return [transactionFields, billingFields, shippingFields, customFields, fraudFields].join(',');
	};
	getCanRefund = (commandType, isApproved, isVoid, previouslyRefundedAmount, cardType, data) => {
		return (
			isApproved &&
			includes(['postauth', 'cbsale', 'fssale', 'splitcapture', 'capture', 'sale'], commandType) &&
			!isVoid &&
			this.toCurrency(data.xAmount * 10) > previouslyRefundedAmount &&
			some(['cc', 'check', 'ebtonline'], item => item === cardType)
		);
	};

	getPaymentMethod = (xCommand, xIsDebit) => {
		if (startsWith(toLower(xCommand), 'gift')) {
			return 'Gift';
		} else if (startsWith(toLower(xCommand), 'check')) {
			return 'Check';
		} else if (xIsDebit === '0') {
			return 'Credit';
		} else if (xIsDebit === '1') {
			return 'Debit';
		}
	};

	getDigitalWalletType = xDigitalWalletType => {
		return !xDigitalWalletType || xDigitalWalletType.toLowerCase() === 'unknown' ? '' : xDigitalWalletType;
	};

	toCurrency = value => this.toNumber(value.toFixed(2));

	toNumber = value => {
		const float = parseFloat(value);
		if (isNaN(float)) {
			return 0;
		}
		return float;
	};

	/**
	 * Initiates new transaction towards Cardknox API
	 * @param data object with keys that match the ones from the Cardknox API, for more see: https://kb.cardknox.com/api
	 * @returns {Promise<void>}
	 */
	createNewTransaction = async (xMerchantId, data) => {
		if (data == null) {
			return;
		}
		data = clone(data);
		data.xUseClientIp = true;

		const principal = this.principalService.get();
		await this.principalService.emailPromise;
		if (principal && principal.username) {
			data.xUserName = principal.username;
			const result = await this.httpService.post(
				updateApiEndpoint('gateway'),
				data,
				this.optionsWithMerchantId(xMerchantId)
			);

			return await this.parseResult(result);
		}
	};

	filterTransactions = async (filter, maxRecords = 1000, fields = null) => {
		if (fields !== null && fields.length > 0) {
			filter.xFields = join(uniq(split(fields, ',')), ',');
		} else {
			filter.xFields = this.getTransactionFields();
		}

		if (maxRecords > 1000) {
			return await this.filterTransactionsAll(filter, fields);
		} else {
			return await this.filterTransactionsRequest(filter, maxRecords, fields);
		}
	};

	combineData = (baseData, additionalData, loadAll = false) => {
		baseData.xReportData = uniqBy(concat(baseData.xReportData, additionalData.xReportData), 'xRefNum');
		baseData.xRecordsReturned = parseInt(baseData.xRecordsReturned) + parseInt(additionalData.xRecordsReturned);
		if (loadAll) {
			baseData.xReportingMaxTransactions =
				parseInt(baseData.xReportingMaxTransactions) + parseInt(additionalData.xReportingMaxTransactions);
		}
	};

	isDateRangeGreaterThanMaxApiDays = filter =>
		moment(filter.xEndDate).diff(moment(filter.xBeginDate), 'days') > maxApiDaysRange;

	fetchMultipleDateRanges = async (filter, loadAll, displayOlderTransactionsFirst) => {
		let lastResultRecordsReturned = null;
		let endDate = filter.xEndDate;
		let startDate = filter.xBeginDate;
		if (displayOlderTransactionsFirst) {
			endDate = moment(startDate, apiDateTimeFormat)
				.add(maxApiDaysRange, 'days')
				.endOf('day')
				.format(apiDateTimeFormat);
		} else {
			startDate = moment(endDate, apiDateTimeFormat)
				.subtract(maxApiDaysRange, 'days')
				.startOf('day')
				.format(apiDateTimeFormat);
		}
		let whileFilter = {
			...filter,
			xBeginDate: startDate,
			xEndDate: endDate,
		};
		let lastFilter;
		const finalResult = await this.httpService.post(updateApiEndpoint('report'), whileFilter);
		const result = cloneDeep(finalResult);
		const hasMoreRecords = () => {
			if (loadAll) {
				if (
					(displayOlderTransactionsFirst && whileFilter.xEndDate === filter.xEndDate) ||
					(!displayOlderTransactionsFirst && whileFilter.xBeginDate === filter.xBeginDate)
				) {
					return (
						(result.xRecordsReturned && result.xRecordsReturned === result.xReportingMaxTransactions) ||
						lastResultRecordsReturned === '1000'
					);
				} else {
					return true;
				}
			} else {
				return (
					finalResult.xReportData.length < filter.xMaxRecords &&
					((displayOlderTransactionsFirst && whileFilter.xEndDate !== filter.xEndDate) ||
						(!displayOlderTransactionsFirst && whileFilter.xBeginDate !== filter.xBeginDate))
				);
			}
		};

		let loopDates = this.getNewStartEndDates(
			filter.xBeginDate,
			filter.xEndDate,
			finalResult.xReportData,
			displayOlderTransactionsFirst
		);
		while (hasMoreRecords()) {
			lastFilter = whileFilter;
			whileFilter = cloneDeep(whileFilter);
			let { start, end, refNums } = loopDates;

			whileFilter.xBeginDate = start ? start.format(apiDateTimeFormat) : null;
			whileFilter.xEndDate = end ? end.format(apiDateTimeFormat) : null;

			if (
				(lastFilter.xEndDate === whileFilter.xEndDate &&
					lastFilter.xBeginDate === whileFilter.xBeginDate &&
					lastResultRecordsReturned !== null) ||
				!start ||
				!end
			) {
				refNums = [];
				if (displayOlderTransactionsFirst) {
					whileFilter.xBeginDate = lastFilter.xEndDate;
					whileFilter.xEndDate = filter.xEndDate;
				} else {
					whileFilter.xBeginDate = filter.xBeginDate;
					whileFilter.xEndDate = lastFilter.xBeginDate;
				}
				if (whileFilter.xBeginDate === whileFilter.xEndDate) {
					break;
				}
			}
			whileFilter.xMaxRecords = Math.min(whileFilter.xMaxRecords + refNums.length, 1000);
			if (this.isDateRangeGreaterThanMaxApiDays(whileFilter)) {
				if (displayOlderTransactionsFirst) {
					whileFilter.xEndDate = moment(whileFilter.xEndDate, apiDateTimeFormat)
						.subtract(
							moment(whileFilter.xEndDate, apiDateTimeFormat).diff(moment(whileFilter.xBeginDate), 'days') -
								maxApiDaysRange,
							'days'
						)
						.format(apiDateTimeFormat);
				} else {
					whileFilter.xBeginDate = moment(whileFilter.xBeginDate, apiDateTimeFormat)
						.add(
							moment(whileFilter.xEndDate, apiDateTimeFormat).diff(moment(whileFilter.xBeginDate), 'days') -
								maxApiDaysRange,
							'days'
						)
						.format(apiDateTimeFormat);
				}
			}
			let result = await this.httpService.post(updateApiEndpoint('report'), whileFilter);
			lastResultRecordsReturned = result.xRecordsReturned;
			loopDates = await this.removeDuplicates(whileFilter, result, refNums, displayOlderTransactionsFirst, finalResult);
			if (!loadAll) {
				whileFilter.xMaxRecords -= result.xReportData.length;
			}
		}

		// Add xEnteredDateMoment column
		finalResult.xReportData = map(finalResult.xReportData, val => {
			val.xEnteredDateMoment = moment(val.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat);
			return val;
		});

		// Sort by entered date desc
		finalResult.xReportData = orderBy(finalResult.xReportData, ['xEnteredDateMoment'], ['desc']);
		finalResult.xRecordsReturned = finalResult.xReportData.length;
		finalResult.xReportingMaxTransactions = finalResult.xReportData.length;

		// Remove xEnteredDateMoment column
		finalResult.xReportData = map(finalResult.xReportData, val => {
			delete val.xEnteredDateMoment;
			return val;
		});

		return finalResult;
	};

	filterTransactionsRequest = async (
		filter,
		maxRecords = 1000,
		fields = null,
		displayOlderTransactionsFirst = false,
		parseResult = true
	) => {
		const includeSplitPay = filter.xCommand === 'Report:Net';
		filter = clone(filter);

		if (fields !== null && fields.length > 0) {
			filter.xFields = join(uniq(split(fields, ',')), ',');
		} else {
			filter.xFields = this.getTransactionFields();
		}

		if (includeSplitPay && !includes(filter.xFields, 'xCustom01')) {
			filter.xFields += ',xCustom01';
		}

		if (filter.xCommand !== 'Report:Transactions') {
			filter.xMaxRecords = maxRecords;
			filter.xGetNewest = !displayOlderTransactionsFirst;
		}

		let result = {};

		if (this.isDateRangeGreaterThanMaxApiDays(filter)) {
			result = await this.fetchMultipleDateRanges(filter, false, displayOlderTransactionsFirst);
		} else {
			result = await this.httpService.post(updateApiEndpoint('report'), filter);
		}

		return parseResult ? await this.parseResult(result, includeSplitPay) : result;
	};

	filterTransactionsAll = async (filter, fields = null, displayOlderTransactionsFirst = false, parseResult = true) => {
		const includeSplitPay = filter.xCommand === 'Report:Net';
		filter = clone(filter);

		if (fields !== null && fields.length > 0) {
			filter.xFields = join(uniq(split(fields, ',')), ',');
		} else {
			filter.xFields = this.getTransactionFields();
		}

		if (includeSplitPay && !includes(filter.xFields, 'xCustom01')) {
			filter.xFields += ',xCustom01';
		}

		if (filter.xCommand !== 'Report:Transactions') {
			filter.xMaxRecords = 1000;
			filter.xGetNewest = !displayOlderTransactionsFirst;
		}

		let finalResult = {};

		if (this.isDateRangeGreaterThanMaxApiDays(filter)) {
			finalResult = await this.fetchMultipleDateRanges(filter, true, displayOlderTransactionsFirst);
		} else {
			finalResult = await this.httpService.post(updateApiEndpoint('report'), filter);
			await this.loopMoreRecords(finalResult, filter, displayOlderTransactionsFirst);
		}

		return parseResult ? await this.parseResult(finalResult, includeSplitPay) : finalResult;
	};

	removeDuplicates = async (filter, result, refNums, displayOlderTransactionsFirst, finalResult) => {
		if (result.xRecordsReturned) {
			result.xRecordsReturned -= refNums.length;
			result.xReportingMaxTransactions -= refNums.length;
		}
		const loopDates = this.getNewStartEndDates(
			filter.xBeginDate,
			filter.xEndDate,
			result.xReportData,
			displayOlderTransactionsFirst
		);
		if (finalResult.xReportData) {
			result.xReportData = filterMethod(result.xReportData, ({ xRefNum }) => !includes(refNums, xRefNum));
			finalResult.xReportData = finalResult.xReportData.concat(result.xReportData);
		}
		return loopDates;
	};

	loopMoreRecords = async (finalResult, filter, displayOlderTransactionsFirst) => {
		const result = cloneDeep(finalResult);
		let hasMoreRecords = result.xRecordsReturned && result.xRecordsReturned === result.xReportingMaxTransactions;
		let loopDates = this.getNewStartEndDates(
			filter.xBeginDate,
			filter.xEndDate,
			finalResult.xReportData,
			displayOlderTransactionsFirst
		);

		while (hasMoreRecords) {
			let whileFilter = cloneDeep(filter);
			const { start, end, refNums } = loopDates;
			whileFilter.xBeginDate = start.format(apiDateTimeFormat);
			whileFilter.xEndDate = end.format(apiDateTimeFormat);

			let result = await this.httpService.post(updateApiEndpoint('report'), whileFilter);
			loopDates = await this.removeDuplicates(whileFilter, result, refNums, displayOlderTransactionsFirst, finalResult);
			hasMoreRecords = result.xRecordsReturned && result.xRecordsReturned === result.xReportingMaxTransactions;
		}

		// Add xEnteredDateMoment column
		finalResult.xReportData = map(finalResult.xReportData, val => {
			val.xEnteredDateMoment = moment(val.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat);
			return val;
		});

		// Sort by entered date desc
		finalResult.xReportData = orderBy(finalResult.xReportData, ['xEnteredDateMoment'], ['desc']);
		finalResult.xRecordsReturned = finalResult.xReportData.length;
		finalResult.xReportingMaxTransactions = finalResult.xReportData.length;

		// Remove xEnteredDateMoment column
		finalResult.xReportData = map(finalResult.xReportData, val => {
			delete val.xEnteredDateMoment;
			return val;
		});
	};

	/**
	 * Voids the transaction
	 * @param refNum reference number of transaction
	 * @param type type of the transaction. Either "cc" or "check"
	 * @returns {Promise<*>}
	 */
	transactionVoid = async (refNum, type, xMerchantId) => {
		if (type === 'cc') {
			return await this.transactionVoidRelease(refNum, xMerchantId);
		}
		let data = {};

		if (refNum == null || refNum == '') {
			throw new Error('Reference Number is required');
		}

		data.xRefNum = refNum;
		data.xCommand = type + ':void';

		return await this.transactionAction(xMerchantId, data);
	};

	transactionVoidRelease = async (refNum, xMerchantId) => {
		let data = {};

		if (refNum == null || refNum == '') {
			throw new Error('Reference Number is required');
		}

		data.xRefNum = refNum;
		data.xCommand = 'cc:VoidRelease';

		return await this.transactionAction(xMerchantId, data);
	};

	transactionCapture = async (command, refNum, amount, tip, note, currency, xMerchantId, xInvoice) => {
		let data = {};

		if (refNum == null || refNum == '') {
			throw new Error('Reference Number is required');
		}

		data.xRefNum = refNum;
		data.xCommand = command;
		data.xAmount = amount;
		data.xTip = tip;
		data.xDescription = note;
		data.xCurrency = currency;
		data.xInvoice = xInvoice;

		return await this.transactionAction(xMerchantId, data);
	};

	transactionAdjust = async (
		refNum,
		xRefnumCurrent,
		paymentMethod,
		transactionType,
		amount,
		tip,
		description,
		orderId,
		custom1,
		custom2,
		custom3,
		partialAuth,
		xMerchantId,
		xInvoice
	) => {
		const data = {};

		if (refNum == null || refNum == '') {
			throw new Error('Reference Number is required');
		}

		if (startsWith(toLower(paymentMethod), 'ebt') || startsWith(toLower(paymentMethod), 'crypto')) {
			paymentMethod = 'cc';
		}

		if (partialAuth) {
			data.xIncremental = true;
		}

		data.xRefNum = xRefnumCurrent || refNum;
		data.xCommand = `${paymentMethod}:adjust`;
		if (transactionType === 'authonly') {
			data.xAmount = amount;
			data.xTip = tip;
			data.xInvoice = xInvoice;
			// tax?
		}
		data.xOrderID = orderId;
		data.xCustom01 = custom1;
		data.xCustom02 = custom2;
		data.xCustom03 = custom3;
		data.xDescription = description;

		return await this.transactionAction(xMerchantId, data);
	};

	transactionRelated = async (refNum, xMerchantId) => {
		let data = {};
		if (refNum == null || refNum == '') {
			throw new Error('Reference Number is required');
		}
		data.xRefNum = refNum;
		data.xCommand = 'report:related';

		const result = await this.httpService.post(
			updateApiEndpoint('report'),
			data,
			this.optionsWithMerchantId(xMerchantId)
		);

		return await this.parseResult(result);
	};

	transactionAction = async (xMerchantId, data) => {
		if (data == null) {
			throw new Error('You must supply data in the transaction action');
		}
		data = clone(data);

		data.xUseClientIp = true;

		const principal = this.principalService.get();
		await this.principalService.emailPromise;
		if (principal && principal.username) {
			data.xUserName = principal.username;

			const result = await this.httpService.post(
				updateApiEndpoint('gateway'),
				data,
				this.optionsWithMerchantId(xMerchantId)
			);

			return await this.parseResult(result);
		}
	};

	setItemCardType = (item, isCheck) => {
		if (startsWith(toLower(item.xCommand), 'ebtw')) {
			item.xCardType = 'EBTW';
		}

		if (startsWith(toLower(item.xCommand), 'gift') && toLower(item.xCardType) !== 'ojc') {
			item.xCardType = 'Gift';
		}
		if (isCheck) {
			item.xCardType = 'Check';
		}
	};

	setItemCVV = item => {
		if (item.xResponseCVVCode === 'M') {
			item.CVV = 'Match';
		} else if (item.xResponseCVVCode === 'N') {
			item.CVV = 'No Match';
		}
	};

	parseItem = async (item, defaultCurrency) => {
		const isCheck = includes(toLower(item.xCommand), 'check');
		const isVoid = item.xVoid == 1;
		this.setItemCardType(item, isCheck);
		this.setItemCVV(item);
		item.xCommand = item.xCommand === 'Xfer:Out' ? 'Split Pay' : item.xCommand;
		item.xEnteredDate = await apiToLocalMoment(item.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat);
		item.xAmount = this.toNumber(item.xAmount);
		item.xTip = this.toNumber(item.xTip);
		item.xProcessingFee = this.toNumber(item.xProcessingFee);
		item.xDigitalWalletType = this.getDigitalWalletType(item.xDigitalWalletType);
		item.paymentMethod = this.getPaymentMethod(item.xCommand, item.xIsDebit);
		item.xResponseBatch = parseInt(item.xResponseBatch, 10) || 'N/A';
		item.currency = item.xCurrency || defaultCurrency;
		item.canResubmit =
			!includes([2, 3, 4, 5, 8, 9, 10], parseInt(item.xStatus, 10)) &&
			toLower(item.xCommand) === 'fraud:save' &&
			(!item.xFraudResubmitted || toLower(item.xFraudResubmitted) === 'false' || item.xFraudResubmitted == 0);
		if (item.xStatus === '0' && !isCheck) {
			item.xStatus = 'N/A';
		} else {
			item.xStatus = statusFraudMap[item.xStatus] || `${statusFraudMap['']} ${item.xStatus}`;
		}
		item.achStatus = item.xStatus;
		item.amountToUse = item.xAmount;
		item.xClearedCount = parseInt(item.xClearedCount) || '';

		if (item.xVoid === '1') {
			item.amountToUse = this.toNumber(item.xRequestAmount);
		} else {
			this.setClearedAmount(item);
			item.amountToUse = item.xAmount;
		}

		if (isVoid) {
			item.netSale = 0;
		} else {
			item.netSale = round(item.amountToUse - item.xProcessingFee, 2);
		}
	};

	setClearedAmount = item => {
		if (!item.xClearedCount) return;
		const transactionType = split(toLower(item.xCommand), ':')[1];
		if (transactionType !== 'authonly') return;
		item.xAmount = this.toNumber(item.xAmount - this.toNumber(item.xClearedAmount));

		if (item.xAmount < 0) {
			item.xAmount = 0;
		}
	};

	parseResult = async (result, includeSplitPay) => {
		if (result) {
			result.xReportingMaxTransactions = parseInt(result.xReportingMaxTransactions);
			result.xRecordsReturned = parseInt(result.xRecordsReturned);
			const principal = this.principalService.get();
			const defaultCurrency = (principal && principal.idInfo && principal.idInfo.xMerchantCurrency) || 'USD';
			result.xReportData = await Promise.all(
				map(result.xReportData, async item => {
					if (item) {
						await this.parseItem(item, defaultCurrency);
					}

					return item;
				})
			);
		}

		if (!includeSplitPay) {
			result.xReportData = filterMethod(result.xReportData, ({ xCommand }) => toLower(xCommand) !== 'split pay');
		}
		return result;
	};

	fraudResubmit = (refNum, engine) => {
		if (refNum == null || refNum == '') {
			throw new Error('Reference Number is required');
		}
		if (engine == null || engine == '') {
			throw new Error('Engine is required');
		}

		const data = {
			xRefNum: refNum,
			xEngine: engine,
			xCommand: 'fraud:resubmit',
		};
		return this.transactionAction('', data);
	};

	transactionRefund = async (refNum, type, amount, notes, currency, xMerchantId) => {
		let data = {};

		if (refNum == null || refNum == '') {
			throw new Error('Reference Number is required');
		}
		if (type == null || type == '') {
			throw new Error('Transaction Type is required');
		}
		if (amount == null || amount == '') {
			throw new Error('Amount is required');
		}

		data.xRefNum = refNum;
		data.xCommand = type + ':refund';
		data.xAmount = amount;
		data.xDescription = notes;
		data.xCurrency = currency;

		// TODO: Remove - For debug ?
		data.xAllowDuplicate = true;

		return await this.transactionAction(xMerchantId, data);
	};

	getTransaction = async (refNum, xMerchantId) => {
		const filter = {
			xRefNum: refNum,
			xCommand: 'Report:Transaction',
			xFields: this.getTransactionFields(),
		};

		const result = await this.httpService.post(
			updateApiEndpoint('report'),
			filter,
			this.optionsWithMerchantId(xMerchantId)
		);
		if (!result) return;
		const item = first(result.xReportData);
		if (!item) return;
		const isCheck = includes(toLower(item.xCommand), 'check');
		item.xEnteredDate = await apiToLocalMoment(item.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat);
		item.currency = item.xCurrency;
		item.xServiceFee = this.toNumber(item.xServiceFee);
		item.xTax = this.toNumber(item.xTax);
		item.xAmount = this.toNumber(item.xAmount);
		item.xClearedCount = parseInt(item.xClearedCount) || '';
		item.canResubmit =
			!includes([2, 3, 4, 5, 8, 9, 10], parseInt(item.xStatus, 10)) &&
			toLower(item.xCommand) === 'fraud:save' &&
			(!item.xFraudResubmitted || toLower(item.xFraudResubmitted) === 'false' || item.xFraudResubmitted == 0);
		if (item.xStatus === '0' && !isCheck) {
			item.xStatus = 'N/A';
		} else {
			item.xStatus = statusFraudMap[item.xStatus] || `${statusFraudMap['']} ${item.xStatus}`;
		}

		this.setClearedAmount(item);
		return item;
	};

	getTransactionExtended = async (refNum, xMerchantId) => {
		const data = await this.getTransaction(refNum, xMerchantId);
		const related = await this.transactionRelated(refNum, xMerchantId);
		const isSplitCaptured = data.xClearedCount > 0;
		const splitCapturedAmount = this.toCurrency(parseFloat(get(data, 'xClearedAmount', 0)));
		const canSplitCapture = get(data, 'xIsSplitCapturable', '0') === '1';

		let previouslyRefundedAmount = 0;
		let hasRefunds = false;
		if (
			related &&
			related.xStatus.toLowerCase() === 'success' &&
			related.xReportData &&
			related.xReportData.length > 0
		) {
			for (let item of related.xReportData) {
				const itemCommand = item.xCommand.toLowerCase().split(':');
				if (itemCommand[1] === 'refund' && item.xResponseResult.toLowerCase() === 'approved' && item.xVoid !== '1') {
					previouslyRefundedAmount += Math.abs(item.xAmount) * 10;
					hasRefunds = true;
				}
			}
		}
		previouslyRefundedAmount = this.toCurrency(previouslyRefundedAmount);

		const [cardType, commandType] = data.xCommand.toLowerCase().split(':');
		const isApproved = data.xResponseResult.toLowerCase() === 'approved';

		const isVoid = isApproved && data.xVoid == '1';
		const canVoid =
			isApproved &&
			some(['cc', 'check', 'ebtonline', 'grant'], item => item === cardType) &&
			data.xVoidable == '1' &&
			((commandType !== 'authonly' && !hasRefunds) || commandType === 'authonly') &&
			commandType !== 'save' &&
			commandType !== 'splitcapture' &&
			!isSplitCaptured;

		const isCapture = isApproved && commandType === 'capture' && !isVoid;
		const canCapture = isApproved && commandType === 'authonly' && !isVoid;

		const canAdjust =
			isApproved && !isVoid && cardType !== 'gift' && toLower(data.xCommand) !== 'cash:sale' && !isSplitCaptured;

		const isRefund = commandType === 'refund';
		const isRefunded = hasRefunds;

		const canRefund = this.getCanRefund(commandType, isApproved, isVoid, previouslyRefundedAmount, cardType, data);

		const canRefundAmount = canRefund ? this.toCurrency((data.xAmount * 10 - previouslyRefundedAmount) / 10) : 0;

		const isAuthOnly = !isVoid && !isRefund && !isRefunded && commandType === 'authonly';

		return {
			data,
			related,
			isApproved,
			isVoid,
			canVoid,
			canCapture,
			isCapture,
			isRefund,
			isRefunded,
			canRefund,
			canRefundAmount,
			previouslyRefundedAmount,
			isAuthOnly,
			canAdjust,
			isSplitCaptured,
			splitCapturedAmount,
			canSplitCapture,
		};
	};

	sendReceipt = async (email, ref, xMerchantId) => {
		const filter = {
			xEmail: email,
			xRefNum: ref,
			xCommand: 'Report:Transaction',
			xCustReceipt: 1,
		};

		return await this.httpService.post(updateApiEndpoint('report'), filter, this.optionsWithMerchantId(xMerchantId));
	};

	closeSplitCapturedAuthOnly = async (xRefNum, xMerchantId) => {
		return await this.httpService.post(
			updateApiEndpoint('gateway'),
			{ xRefNum, xCommand: 'CC:AuthClose' },
			this.optionsWithMerchantId(xMerchantId)
		);
	};

	optionsWithMerchantId(xMerchantId) {
		if (xMerchantId) {
			let options = { headers: new Headers() };
			options.headers.set('x-merchant-id', xMerchantId);
			return options;
		}
		return {};
	}

	getMinOrMaxDateFromData = (data, getMin = true) => {
		let refNums = [];
		let foundDate = null;
		each(data, ({ xEnteredDate, xRefNum }) => {
			const date = moment(xEnteredDate, apiResponseDateTimeFormat);
			if (!foundDate || (getMin ? date.isBefore(foundDate) : date.isAfter(foundDate))) {
				foundDate = date;
				refNums = [xRefNum];
			} else if (date.isSame(foundDate)) {
				refNums.push(xRefNum);
			}
		});

		return { foundDate, refNums };
	};

	getNewStartEndDates = (startDate, endDate, data, displayOlderTransactionsFirst = false) => {
		const { foundDate, refNums } = this.getMinOrMaxDateFromData(data, !displayOlderTransactionsFirst);
		let start = displayOlderTransactionsFirst ? foundDate : moment(startDate, apiDateTimeFormat);
		let end = displayOlderTransactionsFirst ? moment(endDate, apiDateTimeFormat) : foundDate;

		if (foundDate && !displayOlderTransactionsFirst) {
			end.add(999, 'ms');
		}

		return {
			start,
			end,
			refNums,
		};
	};
}

const transactionService = new TransactionService(httpService, authenticationService, principalService);

export default transactionService;
