import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import { find, map } from 'lodash';

import { principalService } from 'common/services';
import Route from 'routing/Route';

let principal = principalService.get();
principalService.subscribe(data => {
	principal = data;
});

class EmptyState extends Component {
	render() {
		const { match, location, tabs } = this.props;
		const authorizedRoute = find(tabs, ({ section }) => principal && principal.hasAccess[section]);
		if (authorizedRoute) {
			return <Redirect to={`${match.url}${authorizedRoute.path}`} />;
		}
		return <NoMatch location={location} />;
	}
}
EmptyState.propTypes = {
	match: PropTypes.object,
	location: PropTypes.object,
	tabs: PropTypes.arrayOf(PropTypes.shape({ section: PropTypes.string })).isRequired,
};

class NoMatch extends Component {
	render() {
		const { location } = this.props;
		return (
			<div className="l--content">
				<h3>
					Not found <code>{location.pathname}</code>
				</h3>
			</div>
		);
	}
}
NoMatch.propTypes = { location: PropTypes.object };

class NestedRoutes extends Component {
	render() {
		const {
			match: { path },
			tabs,
		} = this.props;
		const componentProps = { tabs };
		return (
			<Switch>
				<Route exact path={path} component={EmptyState} componentProps={componentProps} />
				{map(tabs, routeData =>
					routeData.hidden || routeData.disabled ? null : (
						<Route
							key={routeData.path}
							routeRef={this.routeRef}
							{...routeData}
							path={`${path}${routeData.path}`}
							componentProps={componentProps}
						/>
					)
				)}
				<Route component={NoMatch} />
			</Switch>
		);
	}
}

NestedRoutes.propTypes = {
	match: PropTypes.object,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({ path: PropTypes.string.isRequired, hidden: PropTypes.bool, disabled: PropTypes.bool })
	).isRequired,
};

export default withRouter(NestedRoutes);
