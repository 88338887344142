import React from 'react';
import PropTypes from 'prop-types';
import { get, map, noop } from 'lodash';

function FraudSettingsActionGrid({
	onAction,
	onInputButton,
	inputPlaceholder,
	inputLabel,
	gridTitle,
	addButtonLabel,
	onGridInputChange,
	newGridItem,
	actionLabel,
	isLoading,
	iconOnly,
	columns = [],
	data = [],
	showColumnGroup = false,
}) {
	return (
		<div>
			<div className="flex--primary flex--gap--med flex--bottom spc--bottom--xxlrg">
				<div className="flex--grow--1">
					<div className="form__group__header">
						<label className="form__group__label">{inputLabel}</label>
					</div>
					<input
						value={newGridItem}
						onChange={e => onGridInputChange(e.target.value)}
						type="text"
						className="input input--med"
						disabled={isLoading}
						placeholder={inputPlaceholder}
					/>
				</div>
				<button className="btn btn--med btn--primary" onClick={onInputButton} disabled={isLoading}>
					{addButtonLabel}
				</button>
			</div>

			<div>
				<h6 className="spc--bottom--med">{gridTitle}</h6>
				<div>
					<table className="table table--primary table--fixed">
						{showColumnGroup && (
							<colgroup>
								<col></col>
								<col></col>
								<col width="150"></col>
								<col width="50"></col>
							</colgroup>
						)}
						<thead>
							<tr>
								{map(columns, column =>
									column.label ? (
										<th key={column.label} colSpan={column.colSpan}>
											{column.label}
										</th>
									) : null
								)}
							</tr>
						</thead>
						<tbody>
							{map(data, (item, index) => {
								if (!item) return null;
								return (
									<tr key={`${item}-${index}`}>
										{map(columns, column => {
											const isObject = typeof item === 'object';
											const fieldValue = isObject ? item[column.field] : item;
											return (
												<td
													onClick={() => get(column, 'onClick', noop)(get(item, column.field))}
													className={column.fieldClassName || ''}
													key={column.field}
												>
													<div className={`type--ellipsis ${column.onClick ? 'flex--primary flex--nowrap' : ''}`}>
														{fieldValue}
														{column.onClick && (
															<i className="icon icon--sml icon--open-new spc--left--xsml cursor--pointer"></i>
														)}
													</div>
												</td>
											);
										})}
										<td className="type--right">
											<button
												type="button"
												className={`btn btn--link ${iconOnly === true ? 'icon-only' : ''}`}
												onClick={() => onAction(item, index)}
												disabled={isLoading}
											>
												{actionLabel}
												<i className="icon icon--lrg icon--clear-value"></i>
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
FraudSettingsActionGrid.propTypes = {
	data: PropTypes.array.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			field: PropTypes.string,
		})
	).isRequired,
	onAction: PropTypes.func.isRequired,
	onInputButton: PropTypes.func.isRequired,
	gridTitle: PropTypes.string,
	inputPlaceholder: PropTypes.string,
	addButtonLabel: PropTypes.string,
	onGridInputChange: PropTypes.func.isRequired,
	newGridItem: PropTypes.string.isRequired,
	actionLabel: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	iconOnly: PropTypes.bool.any,
	inputLabel: PropTypes.string.isRequired,
	showColumnGroup: PropTypes.bool,
};

export default FraudSettingsActionGrid;
