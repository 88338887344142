import React, { Component } from 'react';

class FilterAlert extends Component {
	render() {
		return (
			<div
				className="flex--no-shrink datatooltip--down"
				data-tooltip="Alert: Results may not contain all transactions. Click All to retrieve all transactions."
			>
				<i className="icon icon--tny icon--info--error"></i>
			</div>
		);
	}
}

export default FilterAlert;
