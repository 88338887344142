import React from 'react';
import PropTypes from 'prop-types';

const FakeRefNumComponent = ({ value }) => {
	return (
		<span>
			<i className="icon icon--sml icon--chevron--right--primary spc--right--tny"></i>{' '}
			<span className="btn btn--link">{value}</span>
		</span>
	);
};

FakeRefNumComponent.propTypes = {
	value: PropTypes.string.isRequired,
};

export default FakeRefNumComponent;
