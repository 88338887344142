import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRoute, Redirect } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import { every, get, isArray } from 'lodash';

import { principalService } from '../Common/services';
import { Error as ErrorPage } from '../components/error';
import { invokeIfFunction } from 'common/utilities';

const root = document.body;

function isUserAuthenticated(principal) {
	return !!principal;
}

function checkIfHasAccess(principal, section) {
	if (isArray(section)) {
		return every(section, item => principal.hasAccess[item]);
	} else {
		return principal.hasAccess[section];
	}
}

function isUserAllowed(principal, section) {
	if (!section) {
		return true;
	}
	return !!(
		principal &&
		principal.hasAccess &&
		checkIfHasAccess(principal, section) &&
		principal.acceptedTermsAndConditions
	);
}

function renderWithLayout(Layout, Component, isPublic, props, routeRef, idleTimerRef, customLayoutClass) {
	const { history } = props;

	return (
		<React.Fragment>
			{!invokeIfFunction(isPublic) ? (
				<IdleTimer
					ref={idleTimerRef}
					crossTab={{
						type: 'localStorage',
					}}
					onIdle={() => {
						history.push({
							pathname: '/logout',
							state: { inactivity: true },
						});
					}}
					timeout={1000 * 60 * 30}
					element={root}
				/>
			) : null}
			{Layout ? (
				<Layout {...props} customLayoutClass={customLayoutClass}>
					<Component ref={routeRef} idleTimerRef={idleTimerRef} {...props} />
				</Layout>
			) : (
				<Component ref={routeRef} {...props} idleTimerRef={idleTimerRef} />
			)}
		</React.Fragment>
	);
}

renderWithLayout.propTypes = {
	history: PropTypes.object,
};

const Route = (
	{
		component: Component,
		layout: Layout,
		section,
		findDefaultRoute,
		isPublic = false,
		componentProps = {},
		routeRef,
		customLayoutClass,
		...rest
	},
	context
) => {
	const idleTimerRef = createRef(); // Create a ref for the IdleTimer

	return (
		<ReactRoute
			{...rest}
			render={props => {
				//eslint-disable-next-line
				const { location } = props;

				if (context.errors && !(location.state && location.state.ignoreErrors)) {
					return renderWithLayout(
						Layout,
						ErrorPage,
						isPublic,
						{ ...componentProps, ...props, errors: context.errors },
						routeRef,
						customLayoutClass
					);
				}

				if (invokeIfFunction(isPublic)) {
					return renderWithLayout(
						Layout,
						Component,
						isPublic,
						{ ...componentProps, ...props },
						routeRef,
						idleTimerRef,
						customLayoutClass
					);
				}

				const principal = principalService.get();
				if (!isUserAuthenticated(principal)) {
					let state;

					if (location.pathname !== '/') {
						state = { returnUrl: `${location.pathname}${location.search}` };
					}

					return <Redirect to={{ pathname: '/login', state: state }} />;
				}
				if (
					(get(location, 'state.isMfaRequired', false) || principal.redirectToSecurity) &&
					location.pathname !== '/security'
				) {
					return <Redirect to={{ pathname: '/security', state: { isMfaRequired: true } }} />;
				}

				if (!isUserAllowed(principal, section)) {
					const defaultRoute = findDefaultRoute() || '/';
					return <Redirect to={{ pathname: defaultRoute }} />;
				}

				return renderWithLayout(
					Layout,
					Component,
					isPublic,
					{ ...componentProps, ...props },
					routeRef,
					idleTimerRef,
					customLayoutClass
				);
			}}
		/>
	);
};

Route.contextTypes = {
	errors: PropTypes.array,
};

Route.propTypes = {
	component: PropTypes.any,
	layout: PropTypes.any,
	section: PropTypes.any,
	findDefaultRoute: PropTypes.func,
	isPublic: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	componentProps: PropTypes.object,
	routeRef: PropTypes.any,
	customLayoutClass: PropTypes.string,
};

export default Route;
