import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FrequencySelectionComponent extends Component {
	get countValue() {
		return this.props.filter.values.count;
	}

	get typeValue() {
		return this.props.filter.values.type;
	}

	filterRemoved = () => {
		this.props.onFilterRemoved({
			id: 'frequency',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				Frequency:
				<span className="type--wgt--medium">
					Every {this.countValue} {this.typeValue}
					{this.countValue > 1 ? 's' : null}
				</span>
				<button onClick={this.filterRemoved} data-tooltip="Remove" className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

FrequencySelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default FrequencySelectionComponent;
