import { Authorize, OutsideClick } from 'common/utilities';
import { endsWith, map, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const Tabs = ({ tabs, match: { url }, location, useRoutes, onTabChange, activeTab }) => {
	const [showLinksDropDown, setShowLinksDropDown] = useState(false);
	function toggleShowLinksDropdown() {
		return setShowLinksDropDown(!showLinksDropDown);
	}
	const getActiveTitle = useCallback(() => {
		const { pathname } = location;
		return find(tabs, ({ path }) => path && endsWith(pathname, path));
	}, [tabs, location]);
	const handleTabChange = useCallback(
		({ target }) => {
			onTabChange(target.dataset.path);
			if (showLinksDropDown) {
				toggleShowLinksDropdown();
			}
		},
		[onTabChange, showLinksDropDown]
	);
	if (!tabs) return null;
	const linksActiveClass = showLinksDropDown ? 'is-active' : 'is-closed';
	const baseUrl = url.substr(0, url.lastIndexOf('/'));
	const { title: activeTitle, mobileTitle: activeMobileTitle } = getActiveTitle() || {};
	return (
		<Fragment>
			<div className="tabs spc--bottom--med hide--to--med">
				{map(tabs, ({ path, linkTitle, section }) =>
					path ? (
						<Authorize key={path} section={section}>
							{useRoutes ? (
								<NavLink activeClassName="is-active" className="tabs__link" to={`${baseUrl}${path}`}>
									{linkTitle}
								</NavLink>
							) : (
								<button
									data-path={path}
									onClick={handleTabChange}
									className={`tabs__link${linkTitle === activeTab ? ' is-active' : ''}`}
								>
									{linkTitle}
								</button>
							)}
						</Authorize>
					) : null
				)}
			</div>
			<OutsideClick action={showLinksDropDown ? toggleShowLinksDropdown : noop} className="header__title__holder">
				<div className="pos--rel hide--from--med spc--bottom">
					<button
						onClick={toggleShowLinksDropdown}
						className={`header__title datatooltip--gift datatooltip--h--right type--wgt--medium ${linksActiveClass}`}
					>
						<span className="hide--to--med--inline">{activeTitle}</span>
						<span className="hide--from--med--inline">{activeMobileTitle}</span>
						<i
							className="icon icon--sml icon--arrow--dropdown is-active align--v--neg--2 spc--left--xsml"
							data-tooltip="Additional gift reports"
						></i>
					</button>
					{showLinksDropDown ? (
						<div className={`header__title__menu ${linksActiveClass}`}>
							<ul className="header__title__menu__list">
								{map(tabs, ({ path, title, section, linkTitle }) =>
									path && title !== activeTitle ? (
										<Authorize key={path} section={section}>
											<li className="header__title__menu__item">
												{useRoutes ? (
													<NavLink
														onClick={showLinksDropDown ? toggleShowLinksDropdown : noop}
														activeClassName="is-active"
														className="header__title__menu__link"
														to={`${baseUrl}${path}`}
													>
														{title}
													</NavLink>
												) : (
													<a
														href="#"
														onClick={e => {
															e.preventDefault();
															handleTabChange(e);
														}}
														data-path={path}
														className={`header__title__menu__link${linkTitle === activeTab ? ' is-active' : ''}`}
													>
														{title}
													</a>
												)}
											</li>
										</Authorize>
									) : null
								)}
							</ul>
						</div>
					) : null}
				</div>
			</OutsideClick>
		</Fragment>
	);
};

Tabs.defaultProps = {
	useRoutes: true,
};

Tabs.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string.isRequired,
			linkTitle: PropTypes.string.isRequired,
			section: PropTypes.string,
		})
	),
	match: PropTypes.object,
	location: PropTypes.object,
	onTabChange: PropTypes.func,
	useRoutes: PropTypes.bool,
	activeTab: PropTypes.string,
};

export default withRouter(Tabs);
