import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	cloneDeep,
	find,
	map,
	size,
	each,
	isEmpty,
	join,
	get,
	split,
	reject,
	keys,
	findIndex,
	toPairs,
	concat,
	slice,
	fromPairs,
} from 'lodash';

import { renderIf, OutsideClick } from './../../utilities';
import { exportTypesByKey } from './export-options';
import ExportTypeDropdown from './export-dropdown';
import { generateFileName } from './helpers';
import { withError } from './../error';
import { principalService } from 'common/services';

class ExportComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expand: false,
		};
	}

	get fields() {
		const { columns } = this.props;
		const principal = principalService.get();
		const splitCaptureSupported = get(principal, 'idInfo.xSplitCaptureEnabled', false);
		const fieldKeys = [];
		if (isEmpty(columns)) {
			return null;
		}
		map(columns, ({ key, fieldKey, ignoreOnfetch }) => {
			if (!ignoreOnfetch) fieldKeys.push(fieldKey || key);
		});

		if (splitCaptureSupported) {
			fieldKeys.push('xIsSplitCapturable,xClearedAmount,xClearedCount');
		}

		return join(fieldKeys, ',');
	}

	onOpen = () => {
		const { data } = this.props;
		if (!isEmpty(data)) {
			this.props.togglePrintExportTooltip(true, 'printGridButtonRef');

			this.setState({
				expand: true,
			});
		}
	};

	onClose = () => {
		const { expand } = this.state;
		if (!expand) {
			return;
		}
		this.props.togglePrintExportTooltip(false, 'printGridButtonRef');

		this.setState({
			expand: false,
		});
	};

	export = async ({ key, all }) => {
		this.showLoader(true);

		try {
			const filename = generateFileName(all);
			const data = await this.getData(all, this.fields);
			this.modify3DSecureValues(data);
			this.modifyEnteredDateValues(data);
			const exporter = exportTypesByKey[key];
			const cleanData = await exporter.cleanData(data);
			const columns = await exporter.getColumns(this.props);
			const mappedData = this.replaceColumns(cleanData, columns);
			exporter.export(filename, mappedData);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.onClose();
			}
		}
		this.showLoader(null);
	};
	modify3DSecureValues = data => {
		each(data, row => {
			let r = row.xReviewed;
			if (!r) return;
			if (r === 'N') {
				r = 'Not Approved';
			} else {
				r = 'Approved';
			}
			row.xReviewed = r;
		});
	};
	modifyEnteredDateValues = data => {
		each(data, row => {
			if (!row.xEnteredDate) return;
			const dateTime = split(row.xEnteredDate, ',');
			reject(data, 'xEnteredDate');
			row.date = dateTime[0];
			row.time = dateTime[1];
		});
	};

	getData = async (all, fields) => {
		const { filters, fetchExportData, type, exportProcessingFee, exportNetSale } = this.props;
		if (all) {
			return await fetchExportData.all(this.fixEndDateFilter(filters), fields, type, {
				exportProcessingFee: !!exportProcessingFee,
				exportNetSale: !!exportNetSale,
			});
		} else {
			const data = cloneDeep(this.props.data);
			fetchExportData.current(data, { exportProcessingFee: !!exportProcessingFee, exportNetSale: !!exportNetSale });
			return data;
		}
	};

	fixEndDateFilter = propfilters => {
		const filters = cloneDeep(propfilters);
		const dateFilter = find(filters, { key: 'date' });
		if (dateFilter && dateFilter.values && dateFilter.values.endDate) {
			const endDateFilter = dateFilter.values.endDate;
			endDateFilter.endOf('day');
		}
		return filters;
	};

	replaceColumns = (data, columnsSource) => {
		const columns = this.getColumns(columnsSource);

		if (columns.xEnteredDate) {
			const xEnteredDateIndex = findIndex(keys(columns), key => key === 'xEnteredDate');
			const orderedColumns = toPairs(columns);

			// Filter out 'xEnteredDate' from the ordered columns
			const filteredColumns = orderedColumns.filter(([key]) => key !== 'xEnteredDate');

			// Insert date and time at the xEnteredDate's position
			const dateAndTime = [['date', 'Date'], ['time', 'Time']];
			const updatedColumns = concat(
				slice(filteredColumns, 0, xEnteredDateIndex),
				dateAndTime,
				slice(filteredColumns, xEnteredDateIndex)
			);

			// Convert back to an object with the desired order
			const transformedColumns = fromPairs(updatedColumns);

			return map(data, obj => {
				const newObj = {};
				each(transformedColumns, (column, colKey) => {
					newObj[column] = obj[colKey] !== undefined ? obj[colKey] : '';
				});
				return newObj;
			});
		} else {
			return map(data, obj => {
				const newObj = {};
				each(columns, (column, colKey) => {
					newObj[column] = obj[colKey] !== undefined ? obj[colKey] : '';
				});
				return newObj;
			});
		}
	};

	getColumns = columns => {
		const returnObj = {};
		each(columns, ({ key, name }) => {
			returnObj[key] = name.replace(/\u00AD/g, '');
		});
		return returnObj;
	};

	showLoader = show => {
		this.props.showLoaderMethod(show);
	};

	renderCustomTooltip = (customLabel, enableExport, tooltipText, primaryButton = false) => {
		const buttonClassName = `btn btn--med ${
			primaryButton ? 'btn--primary' : 'btn--link btn--link--tertiary'
		} datatooltip--auto datatooltip--down`;

		return (
			<button
				className={buttonClassName}
				onClick={() => this.export({ key: 'csv', all: false })}
				disabled={!enableExport}
				data-tooltip={tooltipText}
			>
				<i className={`icon icon--sml icon--download${primaryButton ? '--white' : ''}`} />
				{customLabel ? customLabel : 'Export'}
			</button>
		);
	};

	render() {
		const {
			data,
			exportTypes,
			allTitle,
			hasMoreData,
			tooltip,
			settingsOpen,
			customTooltip,
			hideTooltip,
			customLabel,
			primaryButton,
		} = this.props;
		const { expand } = this.state;
		const showDropdown = (size(exportTypes) > 1 || allTitle) && hasMoreData;
		const enableExport = !isEmpty(data);
		const tooltipText = settingsOpen || hideTooltip ? null : customTooltip ? customTooltip : null;
		const tooltipInfo = !tooltipText ? null : showDropdown && (hideTooltip || expand) ? null : tooltip;

		return showDropdown ? (
			<div className="pos--rel">
				<button
					className="btn btn--med btn--link btn--link--tertiary"
					onClick={this.onOpen}
					disabled={!enableExport}
					data-tooltip={tooltipInfo}
				>
					<i className="icon icon--sml icon--download--light" />
					Export
				</button>
				{renderIf(expand)(
					<OutsideClick action={this.onClose} className="buttondropdown">
						<ExportTypeDropdown
							exportTypes={exportTypes}
							allTitle={allTitle}
							title="Current view"
							selectedExport={this.export}
							hasMoreData={hasMoreData}
						/>
					</OutsideClick>
				)}
			</div>
		) : (
			this.renderCustomTooltip(customLabel, enableExport, tooltipText, primaryButton)
		);
	}
}

ExportComponent.defaultProps = {
	showDropdown: true,
	tooltip: 'Export',
};

ExportComponent.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.array,
	filters: PropTypes.array,
	showLoaderMethod: PropTypes.func.isRequired,
	allTitle: PropTypes.string,
	fetchExportData: PropTypes.shape({
		current: PropTypes.func.isRequired,
		all: PropTypes.func,
	}).isRequired,
	exportTypes: PropTypes.array,
	handleError: PropTypes.func,
	hasMoreData: PropTypes.bool,
	type: PropTypes.string,
	exportProcessingFee: PropTypes.bool,
	exportNetSale: PropTypes.bool,
	tooltip: PropTypes.string,
	settingsOpen: PropTypes.bool,
	togglePrintExportTooltip: PropTypes.any,
	customLabel: PropTypes.string,
	customTooltip: PropTypes.string,
	hideTooltip: PropTypes.bool,
	primaryButton: PropTypes.bool,
};

export default withError(ExportComponent);
