import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	upperFirst,
	lowerFirst,
	chain,
	split,
	isEmpty,
	startsWith,
	each,
	head,
	map,
	toLower,
	clone,
	some,
	has,
	trim,
	findKey,
	transform,
	get,
	find,
	camelCase,
	findIndex,
	endsWith,
	times,
	includes,
	flatten,
	first,
	noop,
	replace,
	size,
	isArray,
	filter,
	slice,
	indexOf,
	pullAt,
} from 'lodash';
import UploadInvoice from './components/UploadInvoice';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

import { UserAccountPanel } from '../../Common/components/user-account-panel';
import { withLoader } from '../../Common/components/loader';
import { withCancelable } from '../../Common/components/cancelable';
import { withError } from '../../Common/components/error';
import { Notification } from '../../Common/components/notifications';
import { kvaasService, paymentSiteService, principalService } from '../../Common/services';
import { kvaasResources, checkIfCanadian } from '../../Common/utilities';
import { validators } from '../../Common/fields';
import { stateList, countryList } from 'components/new-transaction/constants';
import EnrollWithPaymentSite from 'components/settings/pages/payment-site/EnrollWithPaymentSite';
import { ActionsModal, modalNames } from 'common/components/transaction-actions';

const { paymentSiteUrl: baseUrl } = ApplicationSettings;
const maxByteFileSize = 20971520;
const requestKeys = {
	data: 'data',
};

const billShipFields = [
	'firstName',
	'lastName',
	'company',
	'street',
	'street2',
	'city',
	'state',
	'zip',
	'country',
	'phone',
	'mobile',
];

class SendPaymentRequest extends Component {
	constructor(props) {
		super(props);

		const principal = principalService.get();
		this.state = { ...this.initialState, isAdminRole: principal.hasAccess['users'] };

		this.top = createRef();
		this.notification = createRef();
		this.amountRef = createRef();
		this.selectRef = createRef();
		this.scheduledUpdate = null;
	}

	get initialState() {
		const initialState = {
			hasRecurring: false,
			selectedPaymentSiteSections: [],
			transactionTypeOptions: {},
			acceptedFiles: [],
			paymentFields: {
				amount: '',
				amountLocked: '0',
				email: '',
				invoice: '',
				poNum: '',
				description: '',
				intervalCount: '',
				intervalType: 'Month',
				totalPayments: '',
				command: '',
			},
			paymentSiteName: '',
			previouslyGeneratedLink: '',
			newPaymentLink: '',
			oldData: {
				paymentSite: null,
			},
			errorMessages: [],
			isSaving: false,
			showPreviouslyGeneratedLink: false,
			isCanadian: false,
			showCustomFields: false,
			paymentSiteOptions: null,
			companyName: '',
			displayTransactionTypeSection: false,
			fileSizeError: false,
		};

		each(billShipFields, item => {
			initialState.paymentFields[camelCase(`bill ${item}`)] = '';
			initialState.paymentFields[camelCase(`ship ${item}`)] = '';
		});

		times(19, i => {
			const index = i + 1;
			return (initialState.paymentFields[`custom${index > 9 ? '' : '0'}${index}`] = '');
		});

		return initialState;
	}

	get zipLabel() {
		return this.state.isCanadian ? 'Postal Code' : 'ZIP';
	}

	get stateLabel() {
		return this.state.isCanadian ? 'Province' : 'State';
	}

	get mappedPaymentSiteName() {
		const { paymentSites, paymentSiteName } = this.state;
		if (isEmpty(paymentSites)) return '';
		return get(find(paymentSites, { path: paymentSiteName }), 'path') || get(paymentSites[0], 'path');
	}

	toggleRecurring = () => {
		this.setState(
			{
				recurringEnabled: !this.state.recurringEnabled,
				hasRecurring: !this.state.recurringEnabled,
			},
			this.updateNewPaymentLink
		);
	};

	loadLabels = () => {
		try {
			const isCanadian = checkIfCanadian();
			this.setState({ isCanadian });
		} catch (e) {
			this.props.handleError(e);
		}
	};

	getPaymentSiteLink = (parameters = '') => {
		return `${baseUrl}${this.mappedPaymentSiteName}${parameters}`;
	};

	mapToOption = ({ name, id }) => ({ label: `${baseUrl}${name}`, name, id });

	async componentDidMount() {
		this.props.showLoader(true);
		await Promise.all([this.loadLabels(), this.loadData()]);
		this.props.showLoader(false);
	}

	loadData = async () => {
		try {
			const [[paymentSiteSettings, portalFlags], paymentSites] = await this.props.makePendingRequest(
				Promise.all([
					kvaasService.get(kvaasResources.paymentSiteSettings, kvaasResources.portalFlags),
					paymentSiteService.find(),
				]),
				requestKeys.data
			);
			const newState = this.mapResponseToState(paymentSiteSettings, true);
			this.mapPaymentSitesToState(newState, paymentSites.xReportData);
			this.mapPortalFlagsToState(newState, portalFlags);
			if (!newState.paymentSiteName && !isEmpty(newState.paymentSiteOptions)) {
				newState.paymentSiteName = replace(get(head(newState.paymentSiteOptions), 'name'), /\s/g, '');
			}
			await this.setStateAsync(newState);
			if (!isEmpty(newState.errorMessages)) {
				this.scrollToTop();
			}
			if (this.amountRef.current) {
				this.amountRef.current.focus();
			}
			await this.loadPaymentSite();
			await this.mapCustomerToState(this.state);
			this.updateNewPaymentLink();
		} catch (e) {
			const message = get(e, 'ex.message', '');
			const notification = this.props.handleError(e, { delayMessage: true });
			if (
				toLower(message) === 'load failed' ||
				toLower(message) === 'networkerror when attempting to fetch resource.' ||
				toLower(message) === 'failed to fetch'
			) {
				notification.message = 'Load failed, please refresh the page.';
			}

			if (notification) {
				notification.show();
				// eslint-disable-next-line
				console.error(e);
			}
		}
	};

	loadPaymentSite = async () => {
		const { paymentSiteName, paymentSites, paymentFields } = this.state;
		const paymentSite = find(paymentSites, { path: paymentSiteName });

		if (paymentSite) {
			const { sections, formSettings } = paymentSite;

			const allowedCommands = find(formSettings, ({ Key }) => toLower(Key) === 'allowedcommands');
			const newState = {
				displayTransactionTypeSection: false,
				selectedPaymentSiteSections: [],
				transactionTypeOptions: {},
				hasRecurring: false,
				recurringEnabled: false,
				displayRecurringToggle: false,
				recurringKey: '',
			};

			if (!allowedCommands) {
				newState.transactionTypeOptions = { 'cc:sale': true };
				newState.paymentFields = {
					...paymentFields,
					command: 'cc:sale',
				};
			} else {
				const commands = split(allowedCommands.Val, ',');
				each(commands, item => {
					newState.transactionTypeOptions[item] = true;
				});
				if (commands.length < 2) {
					newState.paymentFields = {
						...paymentFields,
						command: first(commands),
					};
				} else {
					newState.displayTransactionTypeSection = true;
				}
			}

			each(sections, section => {
				const fields = map(section.fields, ({ key, label, inputType, values }) => {
					if (key === 'xRecurring' || key === 'customers_schedules') {
						newState.recurringKey = key;
						newState.hasRecurring = true;
						newState.displayRecurringToggle = true;
						newState.recurringEnabled = true;
					}
					if (toLower(key) === 'xamount') {
						if (inputType === 'dropdown' && isArray(values)) {
							newState.hideAdjust = true;
						}
					}
					if (toLower(key).indexOf('city, state, zip') > -1) {
						const isBilling = toLower(key).indexOf('bill') > -1;
						return [
							{
								label: 'City',
								inputType: 'text',
								key: `${isBilling ? 'bill' : 'ship'}City`,
							},
							{
								label: this.stateLabel,
								inputType: 'select',
								key: `${isBilling ? 'bill' : 'ship'}State`,
								options: stateList,
							},
							{
								label: this.zipLabel,
								inputType: 'text',
								key: `${isBilling ? 'bill' : 'ship'}Zip`,
							},
						];
					} else {
						const isCountry = includes(['xbillcountry', 'xshipcountry'], toLower(key));
						const isState = includes(['xbillstate', 'xshipstate'], toLower(key));
						const field = {
							label,
							inputType: isCountry || isState ? 'select' : inputType === 'dropdown' ? 'select' : inputType,
							key:
								key !== 'xRecurring' && key !== 'customers_schedules' && startsWith(key, 'x')
									? camelCase(key.substr(1))
									: key,
						};

						if (isCountry) {
							field.options = countryList;
						} else if (isState) {
							field.options = stateList;
						}

						if (!isEmpty(values)) {
							field.options = values;
						}

						return field;
					}
				});
				newState.selectedPaymentSiteSections.push({ ...section, fields: flatten(fields), isExpanded: false });
			});

			await this.setStateAsync(newState);
		}
	};
	mapCustomerToState = async newState => {
		if (this.props.history.location.state) {
			const {
				history,
				history: {
					location: {
						state: { customer },
					},
				},
			} = this.props;
			const paymentFields = { ...(newState.paymentFields || {}) };
			each(customer, (value, key) => {
				if (has(paymentFields, key)) {
					paymentFields[key] = value;
				}
			});
			await this.setStateAsync({ ...newState, paymentFields });
			history.replace({ state: undefined });
		}
	};

	setStateAsync = newState => new Promise(resolve => this.setState(newState, resolve));

	stateToApi = field => {
		if (field === 'amountLocked') {
			return upperFirst(field);
		}
		return field && `x${upperFirst(field)}`;
	};

	apiToState = field => {
		if (field === 'AmountLocked') {
			return lowerFirst(field);
		}
		return field && findKey(this.state.paymentFields, (_, key) => toLower(key) === toLower(field.substr(1)));
	};

	mapPortalFlagsToState = (newState, portalFlags) => {
		if (!portalFlags) {
			return;
		}
		const { data, result, error, refNum } = portalFlags;
		if (!data || (toLower(result) !== 's' && error === 'Item does not exist')) {
			return;
		}
		if (error) {
			newState.errorMessages.push(`${error}${refNum ? ` (Ref# ${refNum})` : ''}`);
		} else {
			newState.paymentFields = {
				...this.state.paymentFields,
				amountLocked: !data.allowAdjustAmount ? '0' : '1',
			};
			newState.enableSmsRequests = data.enableSmsRequests;
		}
	};

	mapResponseToState = (oldPaymentSiteSettings = this.state.oldData.paymentSite, updateNewData) => {
		const newState = {
			oldData: { ...this.state.oldData },
			errorMessages: [],
		};
		if (oldPaymentSiteSettings) {
			const { data, result, error, refNum } = oldPaymentSiteSettings;
			if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
				if (!error) {
					newState.oldData.paymentSite = {
						...oldPaymentSiteSettings,
					};
				}
				if (updateNewData) {
					each(data, (value, key) => {
						if (key === 'name') {
							newState.paymentSiteName = value.replace(/\s/g, '');
						}
						if (key === 'previouslyGeneratedLink') {
							newState.previouslyGeneratedLink = value;
						}
					});

					newState.errorMessages = [];
				}
			} else {
				newState.errorMessages.push(`${error}${refNum ? ` (Ref# ${refNum})` : ''}`);
			}
		}
		return newState;
	};

	mapPaymentSitesToState = (newState, paymentSites) => {
		if (!isEmpty(paymentSites)) {
			newState.paymentSiteOptions = [];
			newState.paymentSites = [];
			if (paymentSites.length == 1) {
				newState.paymentSiteName = paymentSites[0].path;
				newState.paymentSites = [{ ...paymentSites[0] }];
				newState.paymentSiteOptions.push(this.mapToOption({ name: paymentSites[0].path, id: paymentSites[0].id }));
			} else {
				each(paymentSites, ({ path, id, sections, formSettings }) => {
					newState.paymentSiteOptions.push(this.mapToOption({ name: path, id }));
					newState.paymentSites.push({ sections, formSettings, id, path });
				});
			}
		}
	};

	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	validateLink = () => {
		const fields = transform(clone(this.state.paymentFields), (acc, value, key) => (acc[key] = trim(value)));
		const recurring = {};
		each(fields, (value, key) => {
			if ((key === 'email' || key === 'shipEmail') && !!value && !validators.email(value)) {
				fields[key] = '';
			}
			if ((key === 'billPhone' || key === 'shipPhone') && !!value && !validators.mobilePhone(value)) {
				fields[key] = '';
			}
			if (some(['intervalCount', 'intervalType', 'totalPayments'], item => item === key)) {
				recurring[key] = value;
			}
		});
		if (!this.state.recurringEnabled) {
			each(recurring, (_, key) => (fields[key] = ''));
			if (this.state.displayRecurringToggle) {
				fields.enableRecurring = 0;
			}
		} else if (this.state.hasRecurring) {
			fields.enableRecurring = 1;
		}
		return fields;
	};

	validateFields = (name, value) => {
		const errorMessages = [];
		if (name === 'email' && value) {
			if (!validators.email(value)) {
				errorMessages.push('Email is not valid');
			}
		}
		if (name === 'shipEmail' && value) {
			if (!validators.email(value)) {
				errorMessages.push('Shipping email is not valid');
			}
		}
		if (name === 'billPhone' && value) {
			if (!validators.phoneNumber(value)) {
				errorMessages.push('Billing phone number is not valid');
			}
		}
		if (name === 'shipPhone' && value) {
			if (!validators.phoneNumber(value)) {
				errorMessages.push('Shipping phone number is not valid');
			}
		}
		return errorMessages;
	};
	updateNewPaymentLink = () => {
		const fields = this.validateLink();
		const keyValuePairs = chain(fields)
			.map(this.pairToParameter)
			.compact()
			.join('&')
			.value();
		const newPaymentLink = keyValuePairs ? `?${keyValuePairs}` : '';
		return this.setStateAsync({
			newPaymentLink,
		});
	};

	pairToParameter = (value, key) => {
		const isAmountLocked = toLower(key) === 'amountlocked';
		if (isAmountLocked && value === '1') return;
		return value !== '' && `${this.stateToApi(key)}=${encodeURIComponent(value)}`;
	};

	updateKvaas = async savePaymentSite => {
		this.props.showLoader(true);
		try {
			const {
				paymentSiteName,
				newPaymentLink,
				oldData: { paymentSite },
			} = this.state;
			const data = savePaymentSite
				? { name: replace(paymentSiteName, /\s/g, '') }
				: { previouslyGeneratedLink: newPaymentLink };
			const oldData = clone(paymentSite);
			if (oldData && oldData.data) {
				if (savePaymentSite) {
					data.previouslyGeneratedLink = oldData.data.previouslyGeneratedLink;
					oldData.data = { name: oldData.data.name };
				} else {
					data.name = oldData.data.name;
					oldData.data = { previouslyGeneratedLink: oldData.data.previouslyGeneratedLink };
				}
			}
			const [paymentSiteSettings] = await this.props.makePendingRequest(
				kvaasService.save({
					newData: {
						revision: 0,
						data,
					},
					oldData,
					...kvaasResources.paymentSiteSettings,
				})
			);
			this.props.showLoader(false);
			const newState = this.mapResponseToState(paymentSiteSettings, false);
			await this.setStateAsync(newState);
			return paymentSiteSettings;
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
			}
		}
	};
	toBase64 = file =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});

	handleUpload = async uploadedFile => {
		let fileSizeError = false;
		try {
			const file = await this.toBase64(uploadedFile);
			if (file.length > maxByteFileSize) {
				fileSizeError = true;
			}
			return { fileSizeError, file, fileName: uploadedFile.name, type: uploadedFile.type };
		} catch (e) {
			this.props.handleError(e);
		}
	};
	onDrop = async droppedFiles => {
		const acceptedFiles = slice(droppedFiles, 0, 5);
		let cumulativeFileSizeError = false;
		const files = await Promise.all(
			map(acceptedFiles, async uploadedFile => this.handleUpload(uploadedFile, maxByteFileSize))
		);
		cumulativeFileSizeError = this.calculateMaxFileSize(files);
		this.setState({
			files,
			acceptedFiles,
			fileSizeError: cumulativeFileSizeError || some(files, ({ fileSizeError }) => fileSizeError),
		});
	};

	calculateMaxFileSize = (files = this.state.files) => {
		let cumulativeFileSize = 0;
		let fileSizeError = false;
		each(files, ({ file }) => {
			cumulativeFileSize += file.length;
		});
		fileSizeError = cumulativeFileSize > maxByteFileSize;
		if (this.state.fileSizeError) {
			this.setState({
				fileSizeError,
			});
		}
		return fileSizeError;
	};

	removeAttachment = (e, filePath) => {
		const { acceptedFiles, files } = this.state;
		e.preventDefault();
		e.stopPropagation();

		this.setState(
			{
				acceptedFiles: filter(acceptedFiles, ({ path }) => path !== filePath),
				files: filter(files, ({ fileName }) => filePath !== fileName),
			},
			() => this.calculateMaxFileSize()
		);
	};

	getAcceptedFiles = () => {
		const { acceptedFiles } = this.state;
		return map(acceptedFiles, file => (
			<Fragment key={file.path}>
				<p className="upload__list__item__name">
					{file.path} - {file.size}
				</p>
				<button
					className="btn upload__list__item__remove datatooltip--auto"
					data-tooltip="Remove"
					onClick={e => this.removeAttachment(e, file.path)}
				>
					<i className="icon icon--sml icon--close"></i>
				</button>
			</Fragment>
		));
	};

	openCloseActionsModal = modalObj => {
		this.setState({
			modal: modalObj,
		});
	};

	handlePaymentFieldChange = async ({ target: { name, value, checked, type } }) => {
		if (name === 'billPhone' || name === 'shipPhone') {
			if (!/[^\d()+\-\s]/g.test(value) || value === '') {
				await this.setStateAsync({
					paymentFields: {
						...this.state.paymentFields,
						[name]: type === 'checkbox' ? checked : value,
					},
					errorMessages: this.validateFields(name, value),
				});
			}
		} else {
			if (value === '0' && name === 'intervalCount') {
				return;
			}

			// Handle checkboxes separately
			if (type === 'checkbox') {
				const updatedOptions = [...this.state.paymentFields[name]];
				const optionIndex = indexOf(updatedOptions, value);

				if (checked && optionIndex === -1) {
					updatedOptions.push(value);
				} else if (!checked && optionIndex !== -1) {
					pullAt(updatedOptions, optionIndex);
				}

				await this.setStateAsync({
					paymentFields: {
						...this.state.paymentFields,
						[name]: updatedOptions,
					},
					errorMessages: this.validateFields(name, value),
				});
			} else {
				// Handle other input types
				await this.setStateAsync({
					paymentFields: {
						...this.state.paymentFields,
						[name]: type === 'checkbox' ? checked : value,
					},
					errorMessages: this.validateFields(name, value),
				});
			}
		}

		this.updateNewPaymentLink();
	};
	handleAmountLockedChange = async ({ target: { name, checked, value } }) => {
		await this.setStateAsync({
			paymentFields: {
				...this.state.paymentFields,
				[name]: checked ? '0' : '1',
			},
			errorMessages: this.validateFields(name, value),
		});
		this.updateNewPaymentLink();
	};

	handleTotalPaymentsChange = async ({ value }) => {
		if (value === '0') value = this.state.paymentFields.totalPayments;
		await this.setStateAsync({
			paymentFields: {
				...this.state.paymentFields,
				totalPayments: value,
			},
			errorMessages: this.validateFields('totalPayments', value),
		});
		this.updateNewPaymentLink();
	};

	handleChange = async ({ target: { name, value, checked, type } }) => {
		await this.setStateAsync({
			[name]: type === 'checkbox' ? checked : includes(toLower(name), 'email') ? value.replace(/\s/g, '') : value,
		});
	};

	handleBlur = async () => {
		if (!this.scheduledUpdate) {
			const thisUpdate = () => this.updateKvaas(false);
			this.scheduledUpdate = thisUpdate;
			await thisUpdate();
			const nextUpdate = this.scheduledUpdate;
			this.scheduledUpdate = null;
			if (nextUpdate !== thisUpdate) {
				await nextUpdate();
			}
		} else {
			this.scheduledUpdate = () => this.handleBlur();
		}
	};

	handleSave = async e => {
		e.stopPropagation();
		e.preventDefault();
		this.setState({ isSaving: true });
		const paymentSiteSettings = await this.updateKvaas(true);
		const paymentSiteName = get(paymentSiteSettings, 'data.name');
		const newState = { isSaving: false };
		if (paymentSiteName) {
			newState.paymentSiteName = paymentSiteName;
		}
		if (!isEmpty(this.state.errorMessages)) {
			this.scrollToTop();
		} else {
			this.notification.current.addNotification({
				message: 'PaymentSITE has been set as default',
				ref: paymentSiteSettings && paymentSiteSettings.refNum,
				success: true,
			});
		}
		this.setState(newState);
	};

	handlePopulateFields = async () => {
		const { previouslyGeneratedLink, paymentFields, hasRecurring, transactionTypeOptions } = this.state;
		const emptyPaymentFields = clone(paymentFields);
		each(emptyPaymentFields, (_, key) => {
			emptyPaymentFields[key] = '';
		});
		let newPaymentFields = chain(previouslyGeneratedLink)
			.replace(/^.*\?/, '')
			.split('&')
			.map(keyValue => split(keyValue, '=', 2))
			.filter(([key]) => {
				if (!hasRecurring && includes(['xIntervalType', 'xIntervalCount', 'xTotalPayments'], key)) {
					return;
				} else {
					return has(emptyPaymentFields, this.apiToState(key));
				}
			})
			.map(([key, value]) => {
				let newValue = decodeURIComponent(value);

				if (value === undefined || (key === 'xCommand' && !transactionTypeOptions[decodeURIComponent(value)])) {
					newValue = '';
				}

				return [this.apiToState(key), newValue];
			})
			.fromPairs()
			.value();
		const populatedPaymentFields = {
			...emptyPaymentFields,
			...newPaymentFields,
		};
		const newState = {
			paymentFields: populatedPaymentFields,
		};
		if (populatedPaymentFields.command) {
			newState.showCustomFields = true;
		}
		await this.setStateAsync(newState);
		await this.updateNewPaymentLink();
		this.handleBlur();
	};

	handleViewPaymentLink = () => {
		const { newPaymentLink } = this.state;
		window.open(this.getPaymentSiteLink(newPaymentLink), '_blank');
	};

	openSendSmsPopup = async () => {
		const {
			paymentFields: { billMobile, billPhone },
			newPaymentLink,
		} = this.state;

		this.setState({
			modal: {
				name: modalNames.sendSms,
				data: {
					phoneNumber: billMobile || billPhone || '',
					handleError: this.props.handleError,
					addNotification: get(this.notification, 'current.addNotification', noop),
					newPaymentLink,
					paymentSiteName: this.mappedPaymentSiteName,
				},
			},
		});
	};

	openSendEmailPopup = () => {
		const {
			paymentFields: { email, shipEmail },
			newPaymentLink,
			paymentFields,
			files,
		} = this.state;

		this.setState({
			modal: {
				name: modalNames.sendEmail,
				data: {
					toEmail: email || shipEmail || '',
					handleError: this.props.handleError,
					addNotification: get(this.notification, 'current.addNotification', noop),
					newPaymentLink,
					paymentSiteName: this.mappedPaymentSiteName,
					paymentFields,
					files,
				},
			},
		});
	};

	togglePreviouslyGeneratedLink = () => {
		this.setState({
			showPreviouslyGeneratedLink: !this.state.showPreviouslyGeneratedLink,
		});
	};

	toggleCustomFields = () => {
		this.setState({
			showCustomFields: !this.state.showCustomFields,
		});
	};

	toggleExpanded = key => {
		const { selectedPaymentSiteSections } = this.state;

		const newSelectedPaymentSiteSections = [...selectedPaymentSiteSections];
		const sectionIndex = findIndex(selectedPaymentSiteSections, { key });

		newSelectedPaymentSiteSections[sectionIndex] = {
			...selectedPaymentSiteSections[sectionIndex],
			isExpanded: !selectedPaymentSiteSections[sectionIndex].isExpanded,
		};

		this.setState({ selectedPaymentSiteSections: newSelectedPaymentSiteSections });
	};

	disableAutoComplete = e => {
		if (!e.target.autocomplete) {
			//this disables the chrome autofill feature
			e.target.autocomplete = 'autocomplete';
		}
	};

	handlePaymentSiteNameChange = async option => {
		const { paymentFields } = this.state;
		const newPaymentFields = transform(paymentFields, (acc, _, key) => {
			return (acc[key] = this.initialState.paymentFields[key]);
		});
		const newState = { paymentSiteName: option.name, paymentFields: newPaymentFields };
		const isExistingPaymentSite = !!option.name;

		if (isExistingPaymentSite) {
			const selectRef = get(this.selectRef, 'current');

			if (!selectRef) {
				return;
			}

			selectRef.blur();
			selectRef.focus();
			selectRef.blur();
		}

		await this.setStateAsync(newState);
		await this.loadPaymentSite();
		this.updateNewPaymentLink();
	};
	renderCardknoxRecurringFields = (recurringEnabled, isSaving, intervalCount, intervalType, totalPayments) => {
		return (
			recurringEnabled && (
				<Fragment>
					<div className="spc--top--lrg">
						<div className="flex--primary flex--gap--tny spc--bottom--med">
							<label htmlFor="recurringv2_interval_count" className="type--p2 type--p2--medium type--color--text">
								Frequency
							</label>
							<i className="icon icon--tny icon--regular--info" data-tooltip="Interval type"></i>
						</div>
						<div className="flex--primary flex--gap--lrg">
							<div className="flex--grow--1">
								<div className="form__group__header">
									<span className="form__group__label">Every</span>
								</div>
								<div className="flex--primary flex--gap--sml--alt flex--nowrap">
									<input
										disabled={isSaving}
										type="number"
										min="1"
										step="1"
										id="intervalCount"
										name="intervalCount"
										className="input input--med"
										placeholder="1"
										value={intervalCount}
										onChange={this.handlePaymentFieldChange}
										onBlur={this.handleBlur}
									/>
									<select
										disabled={isSaving}
										id="intervalType"
										name="intervalType"
										className="input input--med input--select"
										onChange={this.handlePaymentFieldChange}
										value={intervalType}
									>
										<option value="Day">Day(s)</option>
										<option value="Week">Week(s)</option>
										<option value="Month">Month(s)</option>
										<option value="Year">Year(s)</option>
									</select>
								</div>
							</div>
							<div className="flex--grow--1">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="totalPayments">
										number of
									</label>
								</div>
								<div className="input--sufix">
									<NumberFormat
										className="input--sufix__main"
										name="totalPayments"
										id="totalPayments"
										value={totalPayments}
										inputMode="numeric"
										onValueChange={this.handleTotalPaymentsChange}
										placeholder="Indefinite"
										disabled={isSaving}
										onBlur={this.handleBlur}
										allowNegative={false}
										format="###"
									/>
									<span className="input--sufix__aside">payments</span>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)
		);
	};

	renderRecurringFields = () => {
		const {
			recurringEnabled,
			isSaving,
			recurringKey,
			paymentFields: { intervalCount, intervalType, totalPayments },
		} = this.state;
		const isCardknoxRecurring = recurringKey === 'customers_schedules' || recurringKey === 'xRecurring';
		if (isCardknoxRecurring) {
			return this.renderCardknoxRecurringFields(recurringEnabled, isSaving, intervalCount, intervalType, totalPayments);
		} else {
			return null;
		}
	};

	renderSelectedPaymentSiteSections = () => {
		const { selectedPaymentSiteSections } = this.state;

		return map(selectedPaymentSiteSections, ({ fields, label, key, isExpanded }) => {
			if (
				size(fields) < 2 &&
				some(fields, field => includes(['xrecurring', 'customers_schedules'], toLower(field.key)))
			) {
				return null;
			}
			return (
				<div key={key} className={`card${isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
					<button className="card__header card__header--expandable" onClick={() => this.toggleExpanded(key)}>
						<h5>{label}</h5>
						<i className={`icon icon--sml icon--chevron--${isExpanded ? 'down' : 'top'}--primary`}></i>
					</button>
					{isExpanded && (
						<div key={key} className="card__body card__body--no-bottom-padding">
							<div className="f-row">{this.renderSelectedPaymentSiteFields(fields)}</div>
						</div>
					)}
				</div>
			);
		});
	};

	renderSelectedPaymentSiteFields = fields => {
		const { isSaving, paymentFields, isCanadian, hideAdjust } = this.state;

		return map(fields, ({ key, label, inputType, options }) => {
			const parentKey = key;
			if (toLower(key) === toLower('cvv')) {
				return;
			}
			if (inputType === 'hidden') {
				inputType = 'text';
			}
			const isAmountField = key === 'amount';
			const inputProps = {
				name: key,
				id: key,
				type: inputType,
				className: `input--${
					inputType === 'select'
						? 'med input--select input'
						: inputType === 'radio'
						? 'radio'
						: inputType === 'checkbox'
						? 'check'
						: 'med input'
				}`,
				placeholder: label,
				value: paymentFields[key],
				disabled: isSaving,
				onChange: this.handlePaymentFieldChange,
				onBlur: this.handleBlur,
			};

			if (isAmountField) {
				inputProps.ref = this.amountRef;
			}

			if (key === 'email') {
				inputProps.inputMode = 'email';
				inputProps.type = 'email';
				inputProps.className = `input input--med ${
					!!paymentFields[key] && !validators.email(paymentFields[key]) ? 'is-invalid' : ''
				}`;
			} else if (endsWith(key, 'phone')) {
				inputProps.inputMode = 'tel';
				inputProps.type = 'tel';
				inputProps.className = `input input--med ${
					!!paymentFields[key] && !validators.phoneNumber(paymentFields[key]) ? 'is-invalid' : ''
				}`;
			} else if (some(['zip', 'invoice', 'poNum'], key)) {
				inputProps.inputMode = 'numeric';
			}

			if (key === 'xRecurring' || key === 'customers_schedules') {
				return;
			}

			return (
				<div key={key} className="f-col f-col-sml-12 f-col-med-6 form__group">
					<div className="form__group__header">
						<label className="form__group__label" htmlFor={inputProps.id}>
							{label}
						</label>
					</div>
					{inputProps.type === 'select' ? (
						<select {...inputProps}>
							{map(options, ({ code, disabled = false, name, province, key, value }) =>
								province && !isCanadian ? null : (
									<option key={code || key} value={code || value} disabled={disabled}>
										{name || key}
									</option>
								)
							)}
						</select>
					) : inputProps.type === 'radio' ? (
						map(options, ({ key, value }) => (
							<div key={`${key}.${value}`} className="spc--bottom--tny">
								<input
									{...inputProps}
									id={`${key}.${inputProps.id}`}
									value={value}
									checked={inputProps.value === value}
									className="input--radio"
								/>
								<label htmlFor={`${key}.${inputProps.id}`}>{key}</label>
							</div>
						))
					) : inputProps.type === 'checkbox' ? (
						<div className="flex--primary">
							{map(options, ({ key, value }) => (
								<div key={`${key}.${value}`} className="spc--right--lrg spc--bottom--sml">
									<input
										{...inputProps}
										id={`${key}.${inputProps.id}`}
										value={isAmountField ? key : value}
										checked={paymentFields[parentKey].includes(isAmountField ? key : value)}
									/>
									<label htmlFor={`${key}.${inputProps.id}`}>{key}</label>
								</div>
							))}
						</div>
					) : (
						<input {...inputProps} />
					)}
					{isAmountField && !hideAdjust && (
						<div>
							<input
								type="checkbox"
								id="amountLocked"
								name="amountLocked"
								className="input--check"
								value={paymentFields.amountLocked === '0'}
								checked={paymentFields.amountLocked === '0'}
								onChange={this.handleAmountLockedChange}
								disabled={isSaving}
							/>
							<label htmlFor="amountLocked">Allow user to adjust amount</label>
						</div>
					)}
				</div>
			);
		});
	};
	getTextTooltip = (enableSmsRequests, isAdminRole) => {
		if (!enableSmsRequests) {
			return isAdminRole
				? 'Enable SMS on Send Payment Request Settings'
				: 'Admin users can enable SMS on Send Payment Request Settings';
		}
		return 'A $0.02 SMS fee will apply for every SMS payment request sent.';
	};

	render = () => {
		const {
			paymentFields: { command },
			fileSizeError,
			paymentSiteName,
			previouslyGeneratedLink,
			newPaymentLink,
			errorMessages,
			isSaving,
			showCustomFields,
			paymentSiteOptions,
			transactionTypeOptions,
			displayRecurringToggle,
			paymentSites,
			displayTransactionTypeSection,
			modal,
			recurringEnabled,
			isAdminRole,
			enableSmsRequests,
		} = this.state;
		const hasPaymentSites = !isEmpty(paymentSiteOptions);
		const paymentSiteOption =
			!isEmpty(paymentSiteOptions) && (find(paymentSiteOptions, { name: paymentSiteName }) || paymentSiteOptions[0]);
		const tooltip = paymentSiteName ? null : 'You must first enter a PaymentSITE name.';
		const disabled = isEmpty(paymentSites) || !paymentSiteName;
		const textTooltip = this.getTextTooltip(enableSmsRequests, isAdminRole);
		return (
			<Fragment>
				<Notification ref={this.notification} />
				<ActionsModal modal={modal} onModalClose={this.openCloseActionsModal} isLoading={this.props.isLoading} />
				<header className="header">
					<div className="header__breadcrumbs">Send Payment Request</div>
					<div className="header__menu">
						<UserAccountPanel />
					</div>
				</header>
				<div className="l--content">
					<div className="l--content--centered">
						<h3 className="spc--bottom--med">Send Payment Request</h3>
						<div>
							<div ref={this.top}>
								{isEmpty(errorMessages)
									? null
									: map(errorMessages, (error, index) => (
											<div key={index} className="type--validation spc--bottom--med">
												{error}
											</div>
									  ))}
							</div>
							{!hasPaymentSites && !this.props.isLoading && (
								<div className="spc--bottom--med">
									<EnrollWithPaymentSite disclaimer="Sign up with PaymentSITE to Access This Module" bannerCut={true} />
								</div>
							)}
							<form onSubmit={this.handleSave} className="card card--lrg spc--bottom--lrg">
								<div className="flex--tertiary flex--gap--sml spc--bottom--med">
									<h5>Send payment request using your existing PaymentSITE</h5>
									<i
										className="icon icon--lrg icon--info datatooltip--w--150"
										data-tooltip="Select the preferred PaymentSITE for your customer to make a payment."
									></i>
								</div>
								<div className="flex--primary flex--gap--med">
									<Select
										isDisabled={size(paymentSiteOptions) < 2}
										ref={this.selectRef}
										className="react-select-container react-select-edit flex--grow--1"
										classNamePrefix="react-select"
										placeholder="PaymentSITE"
										name="paymentSiteName"
										id="paymentSiteName"
										options={paymentSiteOptions || []}
										value={paymentSiteOption ? this.mapToOption(paymentSiteOption) : { name: '', label: '' }}
										onChange={this.handlePaymentSiteNameChange}
										onFocus={this.disableAutoComplete}
										getOptionValue={option => option.name}
									/>
									<button
										disabled={!paymentSiteOption || size(paymentSiteOptions) < 2}
										type="submit"
										className="btn btn--med btn--primary"
									>
										Set As Default
									</button>
								</div>
							</form>

							<div className="card card--lrg spc--bottom--lrg">
								<div className="flex--tertiary flex--gap--sml spc--bottom--med">
									<h5>Previously generated link</h5>
									<i
										className="icon icon--lrg icon--info datatooltip--w--150"
										data-tooltip="Make minor changes to saved transaction information without having to reenter all the payment fields."
									></i>
								</div>
								<div className="flex--primary flex--gap--med flex--nowrap">
									<div className="pos--rel flex--grow--1">
										<div className="input--labeled">
											<label className="input--labeled__label" htmlFor="previouslyGeneratedLink">
												{this.getPaymentSiteLink()}
											</label>
											<input
												name="previouslyGeneratedLink"
												id="previouslyGeneratedLink"
												type="text"
												className="input--labeled__input"
												value={previouslyGeneratedLink}
												onChange={this.handleChange}
											/>
										</div>
										<CopyToClipboard text={this.getPaymentSiteLink(previouslyGeneratedLink)}>
											<button
												disabled={!paymentSiteName}
												type="button"
												className="input--labeled__copy-btn"
												data-tooltip={tooltip}
											>
												<i className="icon icon--sml icon--copy"></i>
											</button>
										</CopyToClipboard>
									</div>
									<div data-tooltip={tooltip}>
										<button
											disabled={!hasPaymentSites}
											type="button"
											className="btn btn--med btn--primary"
											onClick={this.handlePopulateFields}
										>
											Populate Fields
										</button>
									</div>
								</div>
							</div>

							{this.renderSelectedPaymentSiteSections()}

							{displayTransactionTypeSection && (
								<div className="card spc--bottom--lrg">
									<button
										className={`card__header card__header--expandable ${showCustomFields ? 'is-expanded' : ''}`}
										onClick={this.toggleCustomFields}
									>
										<h5>Custom PaymentSITE Fields</h5>
										<i className={`icon icon--sml icon--chevron--${showCustomFields ? 'down' : 'top'}--primary`}></i>
									</button>
									{showCustomFields && (
										<div className="card__body">
											<div className="f-row">
												<div className="f-col f-col-sml-12 f-col-med-6">
													<div className="form__group__header">
														<label className="form__group__label" htmlFor="command">
															Transaction Type
														</label>
													</div>
													<select
														name="command"
														id="command"
														className="input input--med input--select"
														value={command}
														disabled={isSaving}
														onChange={this.handlePaymentFieldChange}
														onBlur={this.handleBlur}
													>
														<option value=""></option>
														{transactionTypeOptions['cc:sale'] && <option value="cc:sale">Sale</option>}
														{transactionTypeOptions['cc:authonly'] && <option value="cc:authonly">Auth Only</option>}
														{transactionTypeOptions['cc:save'] && <option value="cc:save">Save</option>}
														{transactionTypeOptions['cash:sale'] && <option value="cash:sale">Bill Me Later</option>}
													</select>
												</div>
											</div>
										</div>
									)}
								</div>
							)}
							{
								<UploadInvoice
									fileSizeError={fileSizeError}
									onDrop={this.onDrop}
									acceptedFiles={this.getAcceptedFiles()}
									files={this.state.files}
									uploadedFile={this.state.acceptedFiles}
								/>
							}
							{displayRecurringToggle && (
								<div className="card spc--bottom--lrg">
									<div className="card__header">
										<h5 className="card__heading__title">Recurring Payment</h5>
									</div>
									<div className="card__body">
										<div className="input--check--enable-form">
											<input
												type="checkbox"
												name="recurringCheckbox"
												className="input--check"
												onChange={this.toggleRecurring}
												id="recurringCheckbox"
												checked={recurringEnabled}
											/>
											<label htmlFor="recurringCheckbox">Make this a recurring payment</label>
										</div>
										{this.renderRecurringFields()}
									</div>
								</div>
							)}
							<div className="card">
								<div className="card__header">
									<h5>Share this payment link</h5>
								</div>
								<div className="card__body">
									<div className="pos--rel">
										<div className="input--labeled">
											<label className="input--labeled__label" htmlFor="newPaymentLink">
												{this.getPaymentSiteLink()}
											</label>
											<input
												name="newPaymentLink"
												id="newPaymentLink"
												type="text"
												className="input--labeled__input"
												value={newPaymentLink}
												readOnly={true}
											/>
										</div>
										<CopyToClipboard text={this.getPaymentSiteLink(newPaymentLink)}>
											<button
												type="button"
												className="input--labeled__copy-btn"
												data-tooltip={tooltip}
												disabled={disabled}
											>
												<i className="icon icon--sml icon--copy" />
											</button>
										</CopyToClipboard>
									</div>
								</div>
								<div className="card__footer">
									<div className="flex--primary flex--gap--sml--alt align--h--right">
										<div data-tooltip={tooltip}>
											<button
												disabled={disabled}
												type="button"
												onClick={this.handleViewPaymentLink}
												className="btn btn--med btn--primary"
											>
												View
											</button>
										</div>
										<div data-tooltip={tooltip}>
											<button
												className="btn btn--med btn--primary"
												type="button"
												onClick={this.openSendEmailPopup}
												disabled={disabled}
											>
												Email
											</button>
										</div>
										<div data-tooltip={tooltip || textTooltip} className="datatooltip--w--150">
											<button
												className="btn btn--med btn--primary"
												type="button"
												onClick={this.openSendSmsPopup}
												disabled={disabled || !enableSmsRequests}
											>
												Text
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};
}

SendPaymentRequest.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	location: PropTypes.object,
	history: PropTypes.object,
};

export default withCancelable(withError(withLoader(SendPaymentRequest)));
