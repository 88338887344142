import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	cloneDeep,
	each,
	map,
	toLower,
	transform,
	camelCase,
	startsWith,
	keys,
	some,
	zip,
	filter,
	isEmpty,
	last,
	noop,
	get,
	findKey,
	replace,
} from 'lodash';
import { withRouter } from 'react-router-dom';
import { Data } from 'react-data-grid-addons';

import ActionsModal from '../../../Common/components/transaction-actions/actions-modal-wrapper';
import { modalNames } from '../../../Common/components/transaction-actions/modal-names';
import { keyManagementService, principalService, emailService } from '../../../Common/services';
import { UserColumns as Columns } from './../../../Common/components/users/column-filter/userColumns';
import AddEditUser from '../../../Common/components/users/popup/add-edit-user';
import { GridComponent } from './../../../Common/components/grid';
import { withLoader } from './../../../Common/components/loader';
import { withError } from './../../../Common/components/error';
import { withCancelable } from './../../../Common/components/cancelable';
import { Notification } from '../../../Common/components/notifications';
import { exportService } from '../../../Common/components/export/exportService';
import { emailTemplates } from 'common/utilities';
import { withStepup } from 'common/components/stepup-mfa';

const requestKeys = {
	fetch: 'FETCH',
	list: 'LIST',
	load: 'LOAD',
	save: 'SAVE',
	create: 'CREATE',
	remove: 'REMOVE',
};

const disclaimerMessage = (
	<div>
		<p className="spc--bottom--sml">
			This field lets you list the IP addresses that have permission to log in to the Cardknox Merchant Portal. Only
			computers that have these IP addresses will have permission to log in your account on Cardknox.
		</p>
		<p className="spc--bottom--sml">
			Any other IP addresses will be denied access to the Merchant Portal. Note that when you whitelist IP addresses
			here, it overrides any IP addresses that are whitelisted at the user level.
		</p>
		<p>
			<span className="type--wgt--bold">Important!</span> If you leave this field empty, all IP addresses are
			whitelisted with no restrictions.
		</p>
	</div>
);

const handleUserTypeRequiredError = error => {
	if (error && toLower(error.message) === 'agent or internal usertype required for this key') {
		return {
			...error,
			isApiError: false,
			displayMessage: 'Please reach out to customer service to add this user to your account',
		};
	}
	return error;
};

class UsersComponent extends Component {
	constructor(props) {
		super(props);
		this.state = this.initialState;

		this.gridRef = createRef();
		this.notification = createRef();

		this.components = {
			gridHeader: this.renderGridHeader,
			modal: ActionsModal,
		};

		this.classes = {
			wrapper: 'settings--main',
			header: 'flex--primary flex--bottom',
			title: 'settings__header flex--grow--1',
			headerMenuAction: 'settings__header__action',
			headerGroup: 'flex flex--secondary',
			content: 'fullheight',
		};
	}

	get initialState() {
		return {
			data: null,
			fetchingData: true,
			fetchingAdditionalData: false,
			columns: cloneDeep(Columns),
			shouldHideModal: false,
			filteredRows: [],
			inlineFilters: {},
		};
	}

	componentDidMount() {
		window.location.hash = 'jump_to_this_location';
	}

	getSnapshotBeforeUpdate = prevProps => prevProps.location.key !== this.props.location.key;

	componentDidUpdate = (prevProps, _, snapshot) => {
		const { errorMessageVisible } = this.props;
		if (snapshot) {
			this.setState(this.initialState, () => {
				this.gridRef.current.reset();
			});
		}
		if (prevProps.errorMessageVisible && !errorMessageVisible) {
			this.showHideModal(false);
		}
	};

	getUserKeys = async () => {
		try {
			const principal = principalService.get();
			const accountId = principal && principal.idInfo.xMerchantID;
			const { data, refnum } = await this.props.makePendingRequest(
				keyManagementService.list(accountId, { filtername: 'KeyType', filtervalue: ['sso'] }),
				requestKeys.list
			);

			return {
				xReportData: this.mapResponseToUsers(data),
				xRecordsReturned: keys(data).length,
				xReportingMaxTransactions: keys(data).length,
				xRefNum: refnum,
			};
		} catch (e) {
			if (toLower(e && e.message) === 'admin account inactive') {
				return;
			} else {
				this.props.handleError(e);
			}
		}
	};

	mapResponseToUsers = response => {
		const result = [];

		each(response, ({ Role, EmailAddress }, key) => {
			result.push({
				key2: key,
				xEmail: EmailAddress || '',
				xRole: Role,
				xStatus: 'Active',
			});
		});
		return result;
	};

	mapResponseToPermissions = async key => {
		try {
			let result = [];
			const permissions = {};
			const keySettings = {};
			this.props.showLoader(true);
			const { data } = await this.props.makePendingRequest(keyManagementService.load(null, key), requestKeys.load);
			this.props.showLoader(false);

			const mappedData = transform(data, (acc, value, key) => (acc[camelCase(key)] = value));
			const { keyType, ddbRevision, index1 } = mappedData;

			each(mappedData, (value, key) => {
				if (startsWith(key, 'allow')) {
					const val = toLower(value) === 'true';
					permissions[key] = val;
				} else if (!some(['ddbRevision', 'keyType', 'index1', 'role'], item => item === key)) {
					if (!!value && value !== 'False') {
						const originalKey = findKey(data, (_, dataKey) => camelCase(dataKey) === key);
						keySettings[originalKey] = value;
					}
				}
			});
			result = {
				keyType,
				ddbRevision,
				index1,
				permissions,
				keySettings,
			};
			return result;
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
			}
		}
	};
	filterOutCurrentUser = async data => {
		const principal = principalService.get();
		await principalService.emailPromise;
		const isAdmin = principal && principal.isAdmin;
		if (data && data.xReportData) {
			if (isAdmin) {
				// If the user is an admin, only map the data
				data.xReportData = map(data.xReportData, this.mapRow);
			} else {
				// If the user is not an admin, filter and map the data
				data.xReportData = map(
					filter(data.xReportData, row => replace(row.xEmail, ':', '_') !== get(principal, 'username', '')),
					this.mapRow
				);
			}
		} else {
			data.xReportData = [];
		}
	};
	fetchData = async () => {
		this.setState({
			fetchingData: true,
			data: null,
			filteredeRows: [],
		});
		try {
			const data = await this.props.makePendingRequest(this.getUserKeys(), requestKeys.fetch);
			await this.filterOutCurrentUser(data);
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data: data,
					fetchingData: false,
					filteredRows: data ? data.xReportData : [],
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	saveChanges = async (data, closeModal) => {
		try {
			this.showHideModal(false);
			this.props.showLoader(true);
			const response = await this.props.makePendingRequest(keyManagementService.save(data), requestKeys.save);
			const success = toLower(response.result) === 'success';
			if (!success) {
				throw response;
			}
			this.refetchData();
			this.props.showLoader(false);
			this.notification.current.addNotification({
				message: 'User saved',
				ref: response.refnum,
				success: true,
			});
			closeModal();
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
				this.showHideModal(true);
			}
		}
	};
	joinMessage = ({ additionalInfo: { email }, message }) => {
		const isErrorCreatingKey = startsWith(toLower(message), 'unable to create key');
		return (
			<div>
				{isErrorCreatingKey
					? `Unable to create User (MK) (${email}). \nPlease reach out to Support for assistance`
					: message}
			</div>
		);
	};

	createNewUser = async (newUsers, closeModal) => {
		try {
			this.showHideModal(false);
			this.props.showLoader(true);
			const results = await this.props.makePendingRequest(
				Promise.all(map(newUsers, user => keyManagementService.create(user))),
				requestKeys.create
			);
			const users = zip(newUsers, results);
			const successes = filter(users, ([, { result } = {}]) => toLower(result) === 'success');
			const errors = filter(users, ([, { result } = {}]) => toLower(result) !== 'success');
			if (!isEmpty(successes)) {
				this.sendEmail(map(filter(successes, ([{ ssoprovider }]) => !ssoprovider), ([{ email }]) => email));
				this.refetchData();
			}
			this.props.showLoader(false);
			const addRegistrationMessage = message => {
				return (
					<Fragment>
						<div className="spc--bottom--sml">{message}</div>
						<div className="type--sml">We've sent an email to the user with login instructions.</div>
					</Fragment>
				);
			};
			if (isEmpty(errors)) {
				this.notification.current.addNotification({
					message: addRegistrationMessage(`User${users.length > 1 ? 's' : ''} created succesfully!`),
					ref: results[0].refnum,
					success: true,
				});
				closeModal();
			} else {
				let [firstNotification, ...otherNotifications] = map(errors, ([{ email }, error]) =>
					this.props.handleError(error, { delayMessage: true, additionalInfo: { email: email || 'Error' } })
				);

				firstNotification.message = this.joinMessage(firstNotification);
				firstNotification.children = [];
				if (!isEmpty(successes)) {
					const oldFirstNotification = firstNotification;
					delete firstNotification.children;
					const [firstSuccess, ...otherSuccesses] = successes;
					firstNotification = {
						...firstNotification,
						ref: '',
						success: true,
						alignLeft: true,
						message: this.joinMessage({ additionalInfo: { email: firstSuccess[0].email }, message: 'created' }),
					};
					firstNotification.children = map(otherSuccesses, ([{ email }]) => ({
						success: true,
						alignLeft: true,
						message: this.joinMessage({ additionalInfo: { email }, message: 'created' }),
					}));
					firstNotification.children.push(oldFirstNotification);
				}
				each(otherNotifications, otherNotification => {
					firstNotification.children.push({
						message: this.joinMessage(otherNotification),
						alignLeft: true,
						success: false,
					});
				});
				if (!isEmpty(successes)) {
					const lastNotification = isEmpty(firstNotification.children)
						? firstNotification
						: last(firstNotification.children);
					lastNotification.message = addRegistrationMessage(lastNotification.message);
				}

				firstNotification.show(firstNotification);
				this.showHideModal(true);
			}
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
				this.showHideModal(true);
			}
		}
	};

	removeUser = async key => {
		try {
			this.showHideModal(false);
			this.props.showLoader(true);
			const { data: user } = await this.props.makePendingRequest(
				keyManagementService.load(null, key),
				requestKeys.remove
			);
			user.Inactive = 'True';
			user.Role = toLower(user.Role);
			const { refnum } = await this.props.makePendingRequest(
				keyManagementService.remove({ data: user }),
				requestKeys.remove
			);
			this.refetchData();
			this.props.showLoader(false);
			this.notification.current.addNotification({
				message: 'User removed',
				ref: refnum,
				success: true,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
				this.showHideModal(true);
			}
		}
	};
	sendEmail = async emails => {
		if (isEmpty(emails)) return;
		this.props.showLoader(true);
		try {
			const principal = principalService.get();
			const fromEmail = principal.email;

			let emailList = '';

			each(emails, (email, idx) => {
				if (idx === 0) {
					emailList = email;
				} else {
					emailList += `,${email}`;
				}
			});

			let templateParams = {
				to: fromEmail,
			};
			if (emailList.length > 0) {
				templateParams.template = {
					emailBcc: emailList,
				};
			}
			const template = {
				TemplateName: emailTemplates.welcomeToCardknox,
				...templateParams,
			};

			await emailService.sendEmail(template);
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader();
	};

	showHideModal = value => {
		this.setState({ shouldHideModal: value }, () => {
			if (this.gridRef.current) {
				this.gridRef.current.calculateColumnWidths(null, cloneDeep(this.state.columns));
			}
		});
	};

	actions = row => {
		return [
			{
				className: 'btn btn--link',
				action: noop,
				component: AddEditUser,
				tooltip: 'Edit',
				key: 'edit',
				icon: 'edit--light',
				componentProps: {
					type: 'edit',
					shouldHideModal: () => this.state.shouldHideModal,
					mapResponseToPermissions: this.mapResponseToPermissions,
					saveChanges: this.saveChanges,
					user: row,
					refreshGridData: this.gridRef.current.refreshGridData,
					isLoading: this.props.isLoading,
				},
			},
			{
				className: 'btn btn--reset datatooltip--h--left',
				action: this.createOnDeleteHandler(row),
				tooltip: 'Delete',
				key: 'delete',
				icon: 'delete--light',
			},
		];
	};

	mapRow = row => {
		return {
			...row,
			actions: [...this.actions(row)],
		};
	};

	createOnDeleteHandler = ({ key2 }) => () => {
		this.gridRef.current.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Deleting user',
				question: `Are you sure you want to delete this user?`,
				onConfirm: () => this.removeUser(key2),
			},
		});
	};

	resolveColumnName = column => {
		switch (column) {
			case 'lastUsage':
				return 'xLastUsageMoment';
			default:
				return column;
		}
	};

	hasMoreData = data => {
		return (
			data && data.xReportData && data.xReportData.length > 0 && data.xRecordsReturned >= data.xReportingMaxTransactions
		);
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	refetchData = () => {
		this.fetchData();
	};

	openWhitelistPopup = () =>
		this.gridRef.current &&
		this.gridRef.current.openCloseModal({
			name: modalNames.whitelistIPs,
			data: {
				handleError: this.props.handleError,
				addNotification: get(this.notification, 'current.addNotification', noop),
				showLoader: this.props.showLoader,
				disclaimerMessage,
				data: this.state.data.xReportData,
				type: 'users',
			},
		});

	renderWhitelistIPsButton = () => (
		<button
			disabled={isEmpty(this.state.data)}
			type="button"
			className="btn btn--med btn--primary spc--right--sml"
			onClick={this.openWhitelistPopup}
		>
			Whitelist IPs
		</button>
	);

	renderHeaderTitle = () => (
		<div className="settings__header">
			<h3 className="settings__title">Account Settings</h3>
			<div className="flex--tertiary">
				<h5>User Management</h5>
				{this.renderHeader()}
			</div>
		</div>
	);

	renderHeader = () => {
		const { isLoading } = this.props;
		const { shouldHideModal } = this.state;
		return (
			<AddEditUser
				type="add"
				className="btn btn--med btn--primary"
				shouldHideModal={() => shouldHideModal}
				mapResponseToPermissions={this.mapResponseToPermissions}
				createNewUser={this.createNewUser}
				isLoading={isLoading}
			>
				<i className="icon icon--sml icon--add--white"></i>
				New User
			</AddEditUser>
		);
	};

	render = () => {
		const { data, fetchingData, fetchingAdditionalData, columns, filteredRows, inlineFilters } = this.state;

		return (
			<Fragment>
				{this.renderHeaderTitle()}
				<GridComponent
					fetchingData={fetchingData}
					fetchingAdditionalData={fetchingAdditionalData}
					filteredRows={filteredRows}
					inlineFilters={inlineFilters}
					useInlineFilters={true}
					columns={columns}
					data={data}
					resolveColumnName={this.resolveColumnName}
					components={this.components}
					classes={this.classes}
					onChange={this.handleChange}
					hasPaging={false}
					title={false}
					enableExport={false}
					enablePrint={false}
					showPrintDropdown={false}
					printTitle="Users report"
					type="users"
					enableFilters={false}
					fetchData={this.refetchData}
					hasMoreData={this.hasMoreData}
					showResults={true}
					showPanel={false}
					showGridHeader={false}
					ref={this.gridRef}
					fetchExportData={{ current: exportService.mapUserData }}
					headerMenuThreshold={Infinity}
					lastApiRefNum={data && data.xRefNum}
					inlineGridHeaderAndTitle={true}
				/>
				<Notification ref={this.notification} />
			</Fragment>
		);
	};
}

UsersComponent.propTypes = {
	handleError: PropTypes.func,
	errorMessageVisible: PropTypes.bool,
	showLoader: PropTypes.func,
	makePendingRequest: PropTypes.func,
	location: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
};

export default withError(
	withLoader(withCancelable(withRouter(withStepup(UsersComponent, 'portal-cardknox'))), handleUserTypeRequiredError)
);
