import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, result } from 'lodash';
import { OutsideClick, ResizeSensor } from 'common/utilities';
class GridFooter extends Component {
	state = { expanded: false };

	componentDidMount = () => {
		const { gridFooterRef } = this.props;
		if (gridFooterRef.current) {
			ResizeSensor(gridFooterRef.current, this.hidePopup);
		}
	};
	handleToggle = (_, close) => {
		if (close && !this.state.expanded) return;
		this.setState({ expanded: !this.state.expanded });
	};
	hidePopup = () => {
		this.setState({
			expanded: false,
		});
	};

	render() {
		const {
			gridFooterRef,
			isLoadMoreEnabled,
			loadMoreOptions,
			fetchingAdditionalData,
			fetchingData,
			filteredRows,
			type,
			openCloseModal,
			CustomComponent,
			onLoadMoreLimitChange,
			loadMoreLimit,
			renderResultsAndReference,
			showResults,
			displayErrorRefNum,
		} = this.props;
		const displayCustomComponent = (!fetchingData && filteredRows.length > 0) || (!fetchingData && displayErrorRefNum);
		return (
			<div ref={gridFooterRef} className="react-grid__footer">
				{isLoadMoreEnabled && (
					<div className="pagination align--h--left">
						{map(loadMoreOptions, item => (
							<button
								key={item}
								onClick={() => onLoadMoreLimitChange(item)}
								className={`${loadMoreLimit === item ? 'is-active' : ''} pagination__item cursor--pointer`}
								disabled={fetchingAdditionalData}
							>
								{item || 'All'}
							</button>
						))}
					</div>
				)}
				<OutsideClick className="flex--primary flex--gap--sml--alt" action={() => this.handleToggle(null, true)}>
					{(showResults || result(CustomComponent)) && (
						<div className="react-grid__footer__icon__wrapper" onClick={this.handleToggle}>
							<i className="react-grid__footer__icon icon--sml icon--more-dots--grey push"></i>
						</div>
					)}
					<span
						className={
							this.state.expanded ? 'flex--primary flex--gap--sml--alt is-open' : 'flex--primary flex--gap--sml--alt'
						}
					>
						{renderResultsAndReference()}
						{fetchingAdditionalData && <div className="loader--progress" />}
						{displayCustomComponent && (
							<CustomComponent openCloseModal={openCloseModal} type={type} data={filteredRows} />
						)}
					</span>
				</OutsideClick>
			</div>
		);
	}
}
GridFooter.propTypes = {
	gridFooterRef: PropTypes.any,
	isLoadMoreEnabled: PropTypes.bool,
	loadMoreOptions: PropTypes.any,
	fetchingAdditionalData: PropTypes.bool,
	fetchingData: PropTypes.bool,
	filteredRows: PropTypes.array,
	type: PropTypes.string,
	openCloseModal: PropTypes.func,
	CustomComponent: PropTypes.any,
	onLoadMoreLimitChange: PropTypes.func,
	loadMoreLimit: PropTypes.any,
	renderResultsAndReference: PropTypes.func,
	showResults: PropTypes.bool,
	displayErrorRefNum: PropTypes.any,
};
export default GridFooter;
